import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { landingUrl } from '../../../../globals';
import { SocialButtons } from '../../../shared/components/SocialButtons';
import Image from '../../../shared/components/Image';
import Image1 from '../../../assets/images/faq/image-1.png';
import Image2 from '../../../assets/images/faq/image-2.png';
import List from '../../../shared/components/List';
import Paragraph from '../../../shared/components/Paragraph';
import VoiceLink from '../../../shared/components/Link';

const useStyles = makeStyles((theme) => ({
  nonbreakable: {
    display: 'inline-block',
  },
  images: {
    display: 'flex',
    flexWrap: 'wrap',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

export default (scrollAction) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return [
    {
      groupKey: 'faq_group1_title',
      items: [{
        titleKey: 'faq_group1_q1_title',
        content: (
          <List numbered>
            <li>
              <Paragraph>
                {t('faq_group1_q1_part1')}
              </Paragraph>
              <Paragraph>
                {t('faq_group1_q1_part2')}
              </Paragraph>
              <Paragraph>
                {t('faq_group1_q1_part3')}
              </Paragraph>
              <SocialButtons />
            </li>
            <li>
              <Paragraph>
                {t('faq_group1_q1_part4')}
              </Paragraph>
              <Paragraph>
                <Trans
                  i18nKey="faq_group1_q1_part5"
                  components={{ strong: <strong className={classes.nonbreakable} /> }}
                />
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                {t('faq_group1_q1_part6')}
              </Paragraph>
              <Paragraph>
                <Trans
                  i18nKey="faq_group1_q1_part7"
                  components={{ strong: <strong className={classes.nonbreakable} /> }}
                />
              </Paragraph>
            </li>
          </List>
        ),
      }, {
        titleKey: 'faq_group1_q2_title',
        content: (
          <>
            <Paragraph>
              {t('faq_group1_q2_part1')}
            </Paragraph>
            <Paragraph>
              {t('faq_group1_q2_part2')}
            </Paragraph>
          </>
        ),
      }, {
        titleKey: 'faq_group1_q3_title',
        content: (
          <Paragraph>
            {t('faq_group1_q3_part1')}
          </Paragraph>
        ),
      }, {
        titleKey: 'faq_group1_q4_title',
        content: (
          <>
            <Paragraph>
              {t('faq_group1_q4_part1')}
            </Paragraph>
            <Paragraph>
              {t('faq_group1_q4_part2')}
            </Paragraph>
            <Paragraph>
              {t('faq_group1_q4_part3')}
            </Paragraph>
          </>
        ),
      }, {
        titleKey: 'faq_group1_q5_title',
        content: (
          <Paragraph>
            {t('faq_group1_q5_part1')}
          </Paragraph>
        ),
      }, {
        titleKey: 'faq_group1_q6_title',
        content: (
          <>
            <Paragraph>
              {t('faq_group1_q6_part1')}
            </Paragraph>
            <Paragraph>
              {t('faq_group1_q6_part1')}
              <VoiceLink
                href="https://t9gram.me/f/registratsiya-telegram/#loc-24"
              >
                Android
              </VoiceLink>
              {t('faq_group1_q6_part2')}
              <VoiceLink
                href="https://t9gram.me/f/registratsiya-telegram/#loc-41"
              >
                iOS
              </VoiceLink>
              .
            </Paragraph>
            <Paragraph>
              {t('faq_group1_q6_part3')}
            </Paragraph>
            <List>
              <li>
                {t('faq_group1_q6_part4')}
              </li>
              <li>
                {t('faq_group1_q6_part5')}
              </li>
              <li>
                {t('faq_group1_q6_part6')}
              </li>
              <li>
                {t('faq_group1_q6_part7')}
              </li>
              <li>
                {t('faq_group1_q6_part8')}
              </li>
            </List>
            <Box className={classes.images}>
              <Image
                src={Image1}
                alt="Example 1"
              />
              <Image
                src={Image2}
                alt="Example 2"
              />
            </Box>
          </>
        ),
      }],
    },
    {
      groupKey: 'faq_group2_title',
      items: [{
        titleKey: 'faq_group2_q1_title',
        content: (
          <Paragraph>
            {t('faq_group2_q1_part1')}
          </Paragraph>
        ),
      }, {
        titleKey: 'faq_group2_q2_title',
        content: (
          <>
            <Paragraph>
              {t('faq_group2_q2_part1')}
            </Paragraph>
            <Paragraph>
              <Trans
                i18nKey="faq_group2_q2_part2"
                components={{ strong: <strong /> }}
              />
            </Paragraph>
          </>
        ),
      }, {
        titleKey: 'faq_group2_q3_title',
        content: (
          <>
            <Paragraph>
              {t('faq_group2_q3_part1')}
            </Paragraph>
            <Paragraph>
              {t('faq_group2_q3_part2')}
              <VoiceLink href="https://bit.ly/2OZGuDQ">
                {t('faq_group2_q3_part3')}
              </VoiceLink>
              {t('faq_group2_q3_part4')}
            </Paragraph>
            <Paragraph>
              {t('faq_group2_q3_part5')}
            </Paragraph>
          </>
        ),
      }],
    },
    {
      groupKey: 'faq_group3_title',
      items: [{
        titleKey: 'faq_group3_q1_title',
        content:
          (
            <>
              <Paragraph>
                {t('faq_group3_q1_part1')}
                <VoiceLink href={landingUrl}>
                  {t('faq_group3_q1_part2')}
                </VoiceLink>
                {t('faq_group3_q1_part3')}
              </Paragraph>
              <Paragraph>
                <VoiceLink
                  color="primary"
                  onClick={scrollAction}
                >
                  {t('faq_group3_q1_part4')}
                </VoiceLink>
                {t('faq_group3_q1_part5')}
              </Paragraph>
            </>
          ),
      }, {
        titleKey: 'faq_group3_q2_title',
        content: (
          <Paragraph>
            {t('faq_group3_q2_part1')}
            <VoiceLink href="https://forms.gle/XdzRNoXVLWthhCQu9">
              {t('faq_group3_q2_part2')}
            </VoiceLink>
          </Paragraph>
        ),
      }],
    }];
};
