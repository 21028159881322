// MOCK DATA
// import Total from './mock/total.json';
// import Station from './mock/01-010-0001.json';

// export function getAll() {
//   return Promise.resolve(Total);
// }

// export function get() {
//   return Promise.resolve(Station);
// }

// export function getDetached() {
//   return Promise.resolve(Station);
// }

// REAL DATA
import { statsBucketUrl } from '../../../globals';
import fetchRetry from '../helpers/fetchRetry';

const DIALOG_VOTE_ID = '8';

export function getTotalVoted() {
  return fetchRetry(statsBucketUrl, {
    retries: 3,
    retryDelay: 1000,
  })
    .then((response) => response.json())
    .then((data) => data);
}

function getTotalVotedFor(voteId) {
  return getTotalVoted().then((stats) => {
    if (stats.Polls) {
      const poll = stats.Polls.find((p) => p.Id === voteId);
      if (poll) {
        return poll.TotalVoted;
      }
    }

    return 0;
  });
}

export function getTotalVotedForDialog() {
  return getTotalVotedFor(DIALOG_VOTE_ID);
}

export function getAll() {
  return fetch('/data/results/total.json')
    .then((response) => response.json())
    .then((data) => data);
}

export function get(code) {
  return fetch(`/data/results/byStation/${code}.json`)
    .then((response) => response.json())
    .then((data) => data);
}

export function getDetached() {
  return fetch('/data/results/byStation/unassigned.json')
    .then((response) => response.json())
    .then((data) => data);
}
