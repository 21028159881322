import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  constitutionPath,
  constitutionVotePath,
  constitutionInfoPath,
  constitutionMailPath,
  faqUrl,
  goPath,
  goEngPath,
  pollsPath,
  thankyouPath,
  termsUrl,
  landingUrl,
  webpollPath,
  webpollResultsPath,
  dialogPath,
} from '../../../globals';
import FullWidth from './FullWidth';
import LanguageSelect from '../components/LanguageSelect/LanguageSelect';
import Logo from '../../assets/images/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  logoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
    '& img': {
      [theme.breakpoints.down('sm')]: {
        width: 90,
        height: 30,
      },
    },
  },
  voice: {
    marginRight: theme.spacing(2),
    '& h1': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.2rem',
        lineHeight: '1.2rem',
      },
    },
  },
  logoText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  hashTag: {
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  appbar: {
    backgroundColor: 'white',
  },
}));

export default function Header() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const pageLocation = document.location;
  const selectLanguage = (value) => { i18n.changeLanguage(value); };
  const selectedLanguage = i18n.language || window.localStorage.i18nextLng;
  const langs = {
    [goEngPath]: ['en', 'ru'],
    [pollsPath]: ['ru'],
    [faqUrl]: ['ru', 'by'],
    [termsUrl]: ['ru'],
    [constitutionPath]: ['ru', 'by'],
    [constitutionVotePath]: ['ru', 'by'],
    [constitutionMailPath]: ['ru', 'by'],
    [constitutionInfoPath]: ['ru'],
    [thankyouPath]: ['ru', 'by'],
    [webpollPath]: ['ru', 'by'],
    [webpollResultsPath]: ['ru', 'by'],
    [dialogPath]: ['ru'],
    '/': ['ru', 'by', 'en'],
  };
  const hashTagTitle = {
    [goPath]: t('go_hashtag'),
    [goEngPath]: t('go_eng_hashtag'),
    '/': t('we_have_voice_hashtag'),
  };
  const hashTagUrl = {
    [goPath]: 'https://www.instagram.com/explore/tags/ягуляю',
    [goEngPath]: 'https://www.instagram.com/explore/tags/supportbelarus',
    '/': 'https://www.instagram.com/explore/tags/унасестьголос',
  };

  let pathname = pageLocation && pageLocation.pathname;
  if (pathname.substring(pathname.length - 1) === '/') {
    pathname = pathname.substring(0, pathname.length - 1);
  }

  return (
    <MuiAppBar position="static" elevation={0} color="default" className={classes.appbar}>
      <FullWidth
        className={classes.root}
      >
        <Grid
          justify="space-between"
          alignItems="center"
          container
        >
          <Grid
            item
            className={classes.logoItem}
          >

            <Link
              className={classes.logo}
              href={landingUrl}
            >
              <img
                width="148"
                height="53"
                src={Logo}
                alt="Голос"
              />
            </Link>

            <div className={classes.logoText}>
              <Link
                href={landingUrl}
                className={classes.voice}
              >
                <Typography
                  variant="h1"
                  component="h1"
                >
                  {t('voice').toUpperCase()}
                </Typography>
              </Link>

              <Link
                className={classes.link}
                href={hashTagUrl[pathname] || hashTagUrl['/']}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Typography
                  className={clsx(classes.hashTag, classes.hashTagUpXs)}
                  component="span"
                  variant="body1"
                >
                  {hashTagTitle[pathname] || hashTagTitle['/']}
                </Typography>
              </Link>
            </div>
          </Grid>

          <Grid item>
            <LanguageSelect
              onSelect={selectLanguage}
              selected={selectedLanguage}
              langs={langs[pathname] || langs['/']}
            />
          </Grid>
        </Grid>
      </FullWidth>
    </MuiAppBar>
  );
}
