import actionTypes from '../actionTypes';

const initialState = {
  tokenStatus: null,
  data: null,
};

export default function pollResults(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_USER_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.CHANGE_TOKEN_STATUS:
      return {
        ...state,
        tokenStatus: action.payload,
      };
    default:
      return state;
  }
}
