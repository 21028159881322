import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button as MuiButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IconViber from '../../assets/images/social/viber.png';
import IconTelegram from '../../assets/images/social/telegram.png';
import { telegramBotUrl, viberBotUrl } from '../../../globals';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: 'inline-flex',
    flexDirection: props.flexDirection,
    flexWrap: 'wrap',
  }),
  button: {
    backgroundColor: theme.palette.white,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    padding: theme.spacing(1, 2),
    textAlign: 'left',
    width: '180px',
    marginBottom: theme.spacing(2),
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    '&:hover': {
      backgroundColor: theme.palette.white,
      boxShadow: `3px 3px 0 0 ${theme.palette.primary.dark}`,
    },
    '& img': {
      marginRight: '12px',
    },
  },
  title: {
    width: '100%',
  },
}));

const buttonDefaultProps = {
  disabled: false,
};

const buttonPropTypes = {
  disabled: PropTypes.bool,
};

export const ViberButton = (props) => {
  const classes = useStyles(props);
  const {
    disabled,
  } = props;

  return (
    <MuiButton
      variant="contained"
      disableElevation
      className={classes.button}
      disabled={disabled}
      href={viberBotUrl}
    >
      <img
        src={IconViber}
        alt="Icon Viber"
        height="35"
        width="35"
      />
      <Typography
        component="span"
        variant="h5"
        className={classes.title}
      >
        Viber
      </Typography>
    </MuiButton>
  );
};

ViberButton.defaultProps = buttonDefaultProps;
ViberButton.propTypes = buttonPropTypes;

export const TelegramButton = (props) => {
  const classes = useStyles(props);
  const {
    disabled,
  } = props;

  return (
    <MuiButton
      variant="contained"
      disableElevation
      className={classes.button}
      disabled={disabled}
      href={telegramBotUrl}
    >
      <img
        src={IconTelegram}
        alt="Icon Telegram"
        height="35"
        width="35"
      />
      <Typography
        component="span"
        variant="h5"
        className={classes.title}
      >
        Telegram
      </Typography>
    </MuiButton>
  );
};

TelegramButton.defaultProps = buttonDefaultProps;
TelegramButton.propTypes = buttonPropTypes;

export const SocialButtons = (props) => {
  const classes = useStyles(props);

  return (
    <Typography
      component="div"
      className={classes.root}
    >
      <ViberButton />
      <TelegramButton />
    </Typography>
  );
};

const SocialButtonsDefaultProps = {
  flexDirection: 'row',
};

const SocialButtonsPropTypes = {
  flexDirection: PropTypes.string,
};

SocialButtons.defaultProps = SocialButtonsDefaultProps;
SocialButtons.propTypes = SocialButtonsPropTypes;
