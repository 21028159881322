import { combineReducers } from 'redux';

import detached from './detached';
import pollBuilderQuestions from './pollBuilderQuestions';
import pollChoices from './pollChoices';
import pollResults from './pollResults';
import pollingStations from './pollingStations';
import results from './results';
import socialData from './socialData';
import whitelist from './whitelist';
import userData from './userData';
import webpoll from './webpoll';

export default combineReducers({
  detached,
  pollBuilderQuestions,
  pollChoices,
  pollResults,
  pollingStations,
  results,
  socialData,
  whitelist,
  userData,
  webpoll,
});
