import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    marginBottom: theme.margin.bottom,
    textAlign: props.textAlign ? props.textAlign : 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  }),
  text: (props) => ({
    fontWeight: props.bold ? '600' : 'normal',
    fontStyle: props.italic ? 'italic' : 'normal',
  }),
  title: (props) => ({
    marginBottom: theme.margin.bottom,
    textAlign: props.textAlign,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  }),
}));

const Paragraph = (props) => {
  const {
    bold = false,
    color = 'inherit',
    children,
    italic = false,
    title = null,
    textAlign = 'left',
    variant = 'body1',
  } = props;
  const classes = useStyles({
    bold,
    italic,
    textAlign,
  });

  return (
    <>
      {title && (
        <Typography
          component="h3"
          variant="h3"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      <p className={classes.root}>
        <Typography
          color={color}
          component="span"
          variant={variant}
          className={classes.text}
        >
          {children}
        </Typography>
      </p>
    </>
  );
};

Paragraph.defaultProps = {
  bold: false,
  color: 'inherit',
  children: null,
  italic: false,
  textAlign: 'left',
  title: null,
  variant: 'body1',
};

Paragraph.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  italic: PropTypes.bool,
  textAlign: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['body1', 'body2']),
};

export default Paragraph;
