import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: '10px',
    width: '100%',
  },
  white: {
    '& .MuiCircularProgress-svg': {
      color: theme.palette.white,
    },
  },
  small: {
    padding: 5,
    '& .MuiCircularProgress-root': {
      height: '20px !important',
      width: '20px !important',
    },
  },
}));

export default function Loading({ small, white }) {
  const classes = useStyles({ white });

  return (
    <Box className={clsx(classes.root, { [classes.white]: white }, { [classes.small]: small })}>
      <CircularProgress />
    </Box>
  );
}

Loading.defaultProps = {
  small: false,
  white: false,
};

Loading.propTypes = {
  small: PropTypes.bool,
  white: PropTypes.bool,
};
