import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  col: () => ({
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  }),
  left: (props) => ({
    textAlign: props.leftAlign ? props.leftAlign : 'left',
  }),
  center: (props) => ({
    textAlign: props.centerAlign ? props.centerAlign : 'left',
  }),
  right: (props) => ({
    textAlign: props.rightAlign ? props.rightAlign : 'left',
  }),
}));

const ThreeCols = ({
  alignItems = 'flex-start',
  center,
  centerAlign = 'left',
  left,
  leftAlign = 'left',
  right,
  rightAlign = 'left',
}) => {
  const classes = useStyles({
    centerAlign,
    leftAlign,
    rightAlign,
  });

  return (
    <Grid
      container
      spacing={4}
      justify="space-between"
      alignItems={alignItems}
    >
      <Grid
        item
        xs={12}
        md={4}
        className={clsx(classes.col, classes.left)}
      >
        {left}
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={clsx(classes.col, classes.center)}
      >
        {center}
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        className={clsx(classes.col, classes.right)}
      >
        {right}
      </Grid>
    </Grid>
  );
};

ThreeCols.defaultProps = {
  alignItems: 'flex-start',
  centerAlign: 'left',
  leftAlign: 'left',
  rightAlign: 'left',
};

ThreeCols.propTypes = {
  alignItems: PropTypes.string,
  center: PropTypes.element.isRequired,
  centerAlign: PropTypes.string,
  left: PropTypes.element.isRequired,
  leftAlign: PropTypes.string,
  right: PropTypes.element.isRequired,
  rightAlign: PropTypes.string,
};

export default ThreeCols;
