import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.margin.bottom,
    fontWeight: (props) => (props.bold ? '600' : 'normal'),
    fontStyle: (props) => (props.italic ? 'italic' : 'normal'),
  },
  ul: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const List = (props) => {
  const {
    bold,
    children,
    numbered,
    italic,
  } = props;
  const classes = useStyles({ bold, italic });

  return (
    <Typography
      component="div"
      variant="body1"
      className={classes.root}
    >
      {numbered ? (
        <ol className={classes.ul}>
          {children}
        </ol>
      ) : (
        <ul className={classes.ul}>
          {children}
        </ul>
      )}
    </Typography>
  );
};

List.defaultProps = {
  bold: false,
  numbered: false,
  italic: false,
};

List.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
  numbered: PropTypes.bool,
  italic: PropTypes.bool,
};

export default List;
