import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '150px',
    marginBottom: theme.margin.bottom,
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
}));

const Icon = (props) => {
  const classes = useStyles(props);
  const {
    alt,
    src,
  } = props;

  return (
    <img
      className={classes.root}
      src={src}
      alt={alt}
    />
  );
};

Icon.defaultProps = {
};

Icon.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default Icon;
