import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button as MuiButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterDomLink } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import Loading from './Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    marginBottom: theme.margin.bottom,
    width: (props) => (props.fullWidth ? '100%' : 'inherit'),
    [theme.breakpoints.up('xs')]: {
      padding: (props) => (props.small ? theme.spacing(1.5, 2) : theme.spacing(2, 3)),
    },
    [theme.breakpoints.up('md')]: {
      padding: (props) => (props.small ? theme.spacing(2, 2) : theme.spacing(3, 4.5)),
    },
    [theme.breakpoints.up('lg')]: {
      padding: (props) => (props.small ? theme.spacing(2, 2) : theme.spacing(3.5, 5)),
    },

    backgroundColor: (props) => (props.background === 'white' ? 'white' : theme.palette.blue),
    // eslint-disable-next-line no-nested-ternary
    border: (props) => (!props.border ? '0' : !props.borderWhite ? `1px solid ${theme.palette.blue}` : `1px solid ${theme.palette.white}`),
    color: (props) => (props.background === 'white' ? theme.palette.blue : 'white'),

    '&:hover': {
      backgroundColor: (props) => (props.background === 'white' ? 'white' : theme.palette.blue),
      color: (props) => (props.background === 'white' ? `${theme.palette.primary.dark}` : 'white'),
      boxShadow: `3px 3px 0 0 ${theme.palette.primary.dark}`,
    },

    '& .MuiTypography-root': {
      [theme.breakpoints.up('xs')]: {
        fontSize: (props) => (props.small ? '0.8rem' : '1.1rem'),
        lineHeight: (props) => (props.small ? '0.8rem' : '1.1rem'),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: (props) => (props.small ? '1rem' : '1.3rem'),
        lineHeight: (props) => (props.small ? '1rem' : '1.3rem'),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: (props) => (props.small ? '1.1rem' : '1.5rem'),
        lineHeight: (props) => (props.small ? '1.1rem' : '1.5rem'),
      },
      fontWeight: '600',
    },
  },
  loading: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      marginBottom: (props) => (props.small ? -theme.spacing(1.5) : -theme.spacing(2)),
      marginTop: (props) => (props.small ? -theme.spacing(1.5) : -theme.spacing(2)),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: (props) => (props.small ? -theme.spacing(2) : -theme.spacing(3)),
      marginTop: (props) => (props.small ? -theme.spacing(2) : -theme.spacing(3)),
    },
    '& .MuiBox-root': {
      position: 'relative',
      top: 5,
    },
  },
}));

const Button = (props) => {
  const classes = useStyles(props);
  const {
    className,
    component,
    disabled = 'false',
    href,
    icon,
    isLoading,
    onClick,
    target,
    text,
    to,
  } = props;

  const buttonProps = {};

  if (href) {
    buttonProps.href = href;
  }

  if (onClick) {
    buttonProps.onClick = onClick;
  }

  if (icon) {
    buttonProps.endIcon = <OpenInNewIcon />;
  }

  if (disabled) {
    buttonProps.disabled = disabled;
  }

  if (component) {
    buttonProps.component = component;
  }

  if (to) {
    buttonProps.to = to;
  }

  if (target === '_blank') {
    buttonProps.rel = 'noreferrer noopener';
    buttonProps.target = target;
  }

  return (
    <MuiButton
      variant="contained"
      disableElevation
      className={clsx(classes.root, className)}
      size="large"
      {...buttonProps}
    >
      <Typography
        component="span"
      >
        {text}
        {isLoading && (
          <Box className={classes.loading}>
            <Loading white small />
          </Box>
        )}
      </Typography>
    </MuiButton>

  );
};

Button.defaultProps = {
  border: false,
  borderWhite: false,
  className: '',
  component: null,
  href: null,
  disabled: false,
  isLoading: false,
  fullWidth: false,
  onClick: null,
  icon: null,
  small: false,
  target: null,
  to: '',
};

Button.propTypes = {
  background: PropTypes.oneOf(['white', 'blue']).isRequired,
  border: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.element,
  isLoading: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  borderWhite: PropTypes.bool,
};

export default Button;

export function RouterButton(props) {
  return (
    <Button
      component={RouterDomLink}
      {...props}
    />

  );
}

RouterButton.defaultProps = {
};

RouterButton.propTypes = {
  to: PropTypes.string.isRequired,
};
