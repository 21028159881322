import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.margin.bottom,
  },
}));

const SectionTitle = ({ title, subtitle, color = 'inherit' }) => {
  const classes = useStyles();

  return (
    <>
      {subtitle && (
        <Typography
          variant="body2"
          color={color}
          className={classes.title}
        >
          {subtitle}
        </Typography>
      )}
      <Typography
        component="h2"
        variant="h2"
        color={color}
        className={classes.title}
      >
        {title}
      </Typography>
    </>

  );
};

SectionTitle.defaultProps = {
  color: 'inherit',
  subtitle: '',
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  color: PropTypes.string,

};

export default SectionTitle;
