const by = {
  translations: {
    polling_stations: 'Выбарчыя ўчасткі',
    результаты: 'Папярэднія вынікі галасавання',
    voice: 'голaс',
    with_photo: 'з фотa',
    according_to_CEC: 'па версіі УВК',
    voters: 'выбаршчыкаў',
    unique_confirmed: 'унікальных пацверджаных галасоў на платформе "Голас"',
    'фото в системе': 'правераных бюлетэняў на платформе "Голас"',
    social_media_follow: 'Далучайцеся да нас у соцсетях і мессенджерах.',
    tell_friends: 'Запрашайце сяброў і знаёмыx!',
    пользовательское: 'Пагадненне карыстальнiка',
    faq: 'Адказы на папулярныя пытанні',
    stations: 'участкаў',
    'официальная явка': 'яўка выбаршчыкаў паводле пратакола УВК',
    according_to_observers: 'паводле звестак назіральнікаў',
    honest_people_hashtag: '#мыСумленныяЛюдзі',
    we_have_voice_hashtag: '#уНасЁсцьГолас',
    all: 'Усе',
    dmitriyev: 'Дзмiтрыеў',
    kanopatskaja: 'Канапацкая',
    lukashenko: 'Лукашэнка',
    tihanovkaja: 'Цiханоўская',
    cherechen: 'Чэрачэнь',
    against: 'Супраць усiх',
    corrupted: 'Сапсаваў(ла) бюлетэнь',
    ignore: 'Не хадзіў(ла) галасаваць',
    dmitriyevAccusative: 'за Дзмiтрыева',
    kanopatskajaAccusative: 'за Канапацкую',
    lukashenkoAccusative: 'за Лукашэнка',
    tihanovkajaAccusative: 'за Цiханоўскую',
    cherechenAccusative: 'за Чэрачня',
    againstAccusative: 'супраць усiх',
    corruptedAccusative: 'Сапсаваў(ла) бюлетэнь',
    ignoreAccusative: '',
    pollingStationDetails: 'Глядзець фота',
    pollingStationDownload: 'Спампаваць ўлётку',
    voices_plural: 'галасы',
    voices_plural_stats: 'галасоў',
    voted_for: 'Гэты голас быў аддадзены',
    back_side: 'Адваротны бок',
    honest_stations: 'Перамога кандыдата супадае ў пратаколе камісіі і дадзеных "Галасы"',
    dishonest_stations: 'Галасоў за кандыдата ў "Голасе" больш, чым у афіцыйным пратаколе (фальсіфікацыя)',
    not_enough_data: 'На участках недастаткова дадзеных для высновы аб сумленнасці працы выбарчай камісіі',
    total_num_of_voices: 'карытсальнікаў на платформе "Голас"',
    участок: 'Участак',
    all_stations: 'Усе ўчасткі',
    область: 'вобласць',
    title: 'Анлайн-платформа "Голас". У гэты раз ваш голас вырашае ўсё!',
    processing_monitored: 'Дадзеныя ад назіральнікаў апрацоўваюцца',
    processing_voters: 'Дадзеныя пра выбаршчыкаў апрацоўваюцца',
    processing_official_numbers: 'Дадзеныя выніковага пратакола не былі вывешаны або сфатаграфаваныя',
    processing_official_numbers_table: 'Няма дадзеных',
    protect_now: 'Абараніце ваш голас зараз!',
    more_ballots_than_official: '"Галасоў" больш, чым галасоў па версіі УВК',
    more_photos_than_official: 'Фота бюлетэняў больш, чым галасоў па версіі УВК',
    destroyed_ballot: 'Бюлетэнь сапсаваны',
    summary_results: 'Вынікі галасавання',
    detailed_report_button: 'Дэталёвая справаздача',
    results_button: 'Пратаколы УВК',
    summary_1: 'Выбары несапраўдныя, бо фальсіфікацыі былі выяўленыя на кожным трэцім выбарчым участку.',
    summary_2: 'Выніковыя дадзеныя, агучаныя ЦВК, значна разыходзяцца з афіцыйнымі дадзенымі з участковых пратаколаў.',
    summary_3: 'Аляксандр Лукашэнка не можа лічыцца легітымным прэзідэнтам Беларусі.',
    summary_4: 'Вялікая колькасць галасоў, аддадзеных за Святлану Ціханоўскую, скрадзеныя ўчастковымі камісіямі.',
    chatbot_send_photo: 'Дашліце чат-боту платформы "Голас" фатаграфію вашага бюлетэня',
    detailed_report: '<bold>Падрабязнасці</bold>',
    detailed_report_from: 'ад платформы "Голас" і ініцыятывы "Сумленныя Людзі" ––',
    here: 'тут',
    no_photos: 'Фота бюлетэняў апрацоўваюцца',
    no_photos_available: 'Фатаграфіі за дадзенага кандыдата адсутнічаюць',
    sensitive_data: 'Фота скрыта, бо на ім можа прысутнічаць пашпарт выбаршчыка або іншыя асабістыя дадзеныя. Арыгінал фота ёсць у распараджэнні платформы "Голас". Усяго на платформе 45,865 такіх фатаграфій з {{photos}} (менш за 10%)',
    photo_quarantined: 'Фота часова скрыта, бо праходзіць дадатковую апрацоўку',
    report_personal_data: 'Мы схавалі асабістыя дадзеныя (пашпарт або твар) з большай частцы фатаграфій, якія змяшчаюць іх. Калі вы ўсё ж заўважылі фота з асабістымі дадзенымі або няправільна распазнанае, паведаміце нам, націснуўшы на кнопку "Схаваць фота" унізе.',
    PEC_results: 'Пратакол',
    send_results_photo: 'Даслаць фота пратакола',
    station_code: 'Код участка',
    photos_disclaimer: 'Фатаграфіі пратаколаў мы атрымалі ад незалежных назіральнікаў ініцыятыў "Сумленныя людзі", "Зубр", а таксама з адкрытых крыніц. Мы ацэньваем дакладнасць дадзеных у 98%. Калі вы выявіце недакладнасць ці памылку, паведаміце нам аб гэтым з дапамогай',
    this_form: 'гэтай формы',
    site_title: 'Вынікі платформы "Голас": знайдзі свой участак!',
    site_description: 'Дапаможам абараніць ваш выбар і пакажам вынікі, падлічаныя не камісіямі, а выбаршчыкамі. Заходзьце ў Viber, Telegram, раскажыце пра свой выбар!',
    error_notify: 'Паведаміць пра памылку',
    notify: 'Схаваць фота',
    notify_personal_data: 'Змяшчае асабістыя дадзеныя',
    notify_incorrect_data: 'Няправільны кандыдат',
    notify_other: 'Іншая праблема',
    problem: 'Праблема',
    state_problem: 'Апішыце праблему',
    report_manually: 'Калі па нейкай прычыне форма вышэй не працуе, адпраўце памылку ўручную з дапамогай',
    missing_photo1: 'Не можаце знайсці сваю фатаграфію? ',
    missing_photo2: 'На платформе "Голас" змяшчаецца ',
    missing_photo3: '{{registered}} галасоў',
    missing_photo4: ', не прывязаных да ўчастку, з іх {{photoVoices}} –– з фота.',
    walking_call_header: 'Гуляйце ў добрай кампаніі',
    // walking_call_header: 'Праект #ягуляю часова прыпыняецца',
    walking_suspended: 'Платформа "Голас" часова прыпыняе праект <strong>#ягуляю</strong>, каб правесці апытанне аб зменах Канстытуцыі.',
    walking_poll: 'Удзельнічайце ў ',
    walking_link_to_poll: 'апытанні аб Канстытуцыі.',
    button_support_belarus: 'ПАДТРЫМАЦЬ БЕЛАРУСАЎ',
    how_does_it_work: 'Як гэта працуе?',
    us_already: 'Зарэгістраваліся ў "Голасе"',
    walking_today: '{{Walk}} па вуліцы ў Беларусі {{Sunday}}',
    walking_home: '{{Walk}} дома ў Беларусі {{Sunday}}',
    image_author: 'аўтар ілюстрацыі:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Крок 1: Зарэгіструйцеся ў\u00A0"Голасе"',
    walking_title_step_2: 'Крок 2: Паведаміце чат-боту, што вы гуляеце',
    walking_title_step_3: 'Крок 3: Падзяліцеся прагулкай з\u00A0сябрамі',
    walking_activate_bot: 'Актывуйце бота перад прагулкай',
    walking_activate_bot_desc_1: 'Перад выхадам на прагулку актывуйце чат-бота: адпраўце яму каманду <strong>/ягуляю</strong>. Такім чынам вы паведаміце "Голасу", што у гэты канкрэтны дзень вы далучыліся да пратэста. Хочаце пайсці гуляць, але не можаце – усё роўна адпраўляйце каманду.',
    walking_activate_bot_desc_2: 'Удзел у законных маштабных маршах, лакальныя мітынгі, ланцужкі салідарнасці, любыя ініцыятывы, да нават прагулка ўва двары і гутарка з суседзямі – форма пратэста не мае значэння. Галоўнае, што вы актыўны і наш чат-бот аб гэтым ведае. Платформе не патрэбны вашыя персанальныя дадзеныя – мы хочам толькі падлічыць удзельнікаў пратэста.',
    walking_activate_bot_desc_3: 'Учыняем падлік кожную нядзелю і ў спецыяльныя дні, пра якія мы будзем паведамляць асобна.',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Выкарыстайце тэг #ягуляю',
    walking_use_hashtag_desc_1: 'Чым нас больш – тым грамчэй наш голас. Уладам будзе больш складана і надалей рабіць выгляд, што нас не чутна. Фатаграфуйцеся падчас сваіх прагулак, паказвайце ў сацсетках, стаўце хэштэг <strong>#ягуляю</strong>.',
    walking_use_hashtag_desc_2: 'Раскажыце аб праекце усім знаёмым. Наш лічыльнік – доказ нашай праўды і нашай перамогі.',
    walking_how_title: 'Як гэта працуе',
    walking_how_desc_1: 'Новы спецпраект "Голаса" – <bold>#ягуляю</bold>. Цяпер мы хочам падлічыць беларусаў, якія выходзяць на пратэсты, адстойваюць сваю свабоду і новыя сумленныя выбары. Мы хочам паказаць усяму свету рэальную карціну барацьбы за справядлівасць.',
    walking_how_desc_2: '<bold>#ягуляю</bold> будзе лічыць усіх: хто законна гуляе ў Мінску і буйных гарадах, хто гуляе ў далёкіх кутках Беларусі ў падтрымку ўдзельнікаў пратэстаў, хто аб\'ектыўна не можа гуляць, але таксама супраць беззаконня.',
    walking_how_desc_3: 'Гэта ананімна і бяспечна. Далучыцца можа кожны. Нават той, хто хоча падтрымаць пратэст, але пакуль не вырашыўся зрабіць гэта адкрыта.',
    walking_how_desc_4: 'У "Голасе" ужо больш за <bold>1 300 000</bold> чалавек. І зараз мы ўзнаўляем рэгістрацыю на платформе.',
    walking_how_desc_5: 'Ўсталявалі бота? Падзяліцеся нашай ідэей з сябрамі!',
    walking_how_desc_6: '<bold>Калі вы ўжо зарэгістраваліся ў чат-боце, пераходзьце да наступнага кроку.</bold>',
    walking_how_desc_7: '<bold>Калі рэгіструецеся ўпершыню – націсніце на кнопку ўлюбёнага мэсэнжэра і кіруйцеся інструкцыямі ў чат-боце. На першым кроку пацвердзіце, што адправілі нумар тэлефона (ён будзе надзейна зашыфраваны).</bold>',
    no_messenger: 'Калі ў вас няма мэсэнжэра',
    faq_answers: 'Адказы на частыя пытанні',
    go_hashtag: '#ягуляю',
    walking_info: 'У нядзелю, 13\u00A0верасня, у\u00A0Беларусі доўга адсутнічаў інтэрнэт, што перашкодзіла шматлікім нашым карыстальнікам адправіць каманду чат-боту. Мы працягваем падлік людзей, якія ўдзельнічалі ў маршы, да 12.00 панядзелка\u00A0– паспейце даслаць /ягуляю чат-боту "Голаса".',
    walking_result_title: 'Мы гуляем',
    walking_result_p1: 'Цяпер беларусы маршыруюць кожны дзень у розны час па ўсёй краіне: каб па-ранейшаму выказваць сваю пазіцыю, але пазбягаць маштабных затрыманняў. Ужо некалькі тыдняў выкарыстоўваецца фармат лакальных маршаў — дваровых ды раённых.',
    walking_result_p2: 'І даведацца, колькі чалавек выказвае нязгоду з рэжымам праз вулічныя дэманстрацыі, — пры такім дэцэнтралізаваным фармаце — асабліва актуальна.',
    walking_result_p3: 'Калі вы ўдзельнічаеце ў вулічных пратэстах у бліжэйшыя выходныя — абавязкова раскажыце нам. Формай пратэсту можа быць дваровае шэсце, удзел у раённым маршы або выхад у вялікай калоне.',
    walking_result_p4: 'Каб паведаміць пра свой шпацыр, неабходна даслаць чат-боту Голаса каманду <strong>/ягуляю</strong>',
    walking_result_p5: 'Распаўсюдзьце інфармацыю сярод знаёмых, якія таксама пратэстуюць!',
    walking_by: 'у Беларусі',
    walking_eu: 'у Еўропе',
    walking_world: 'у свеце',
    support_coordination_council: 'Падтрымайце Каардынацыйны Савет',
    coordination_council: 'Каардынацыйная Рада',
    voted: 'Прагаласавала',
    voted_poll: 'Прайшлi апытанне',
    poll: 'poll',
    polls_explain_1: '"Голас" запускае апытанне ў падтрымку Каардынацыйнай Рады як упаўнаважанага прадстаўніка беларускага народа.',
    polls_explain_2: 'Каардынацыйная Рада створана па ініцыятыве Святланы Ціханоўскай, каб весці дыялог з уладамі ад імя грамадзянскай супольнасці і дамагацца выканання патрабаванняў:',
    polls_explain_3: 'вызваліць палітвязняў і спыніць рэпрэсіі,',
    polls_explain_4: 'прыцягнуць да адказнасці вінаватых у забойствах і гвалце,',
    polls_explain_5: 'правесьці новыя сумленныя выбары.',
    polls_explain_6: 'Разам мы выйдзем з палітычнага крызісу і даможамся згоды ў грамадстве. Абаронім суверэнітэт і незалежнасць нашай краіны.',
    polls_take_part: 'Прыняць удзел у апытанні',
    polls_take_part_p1: 'Апытанне праводзіцца праз адзін з мэсэнджараў –– Viber або Telegram. Пры рэгістрацыі на платформе "Голас" чат-бот запытае ваш нумар тэлефона. Гэта важна для для вызначэння вашай унікальнасці і выключэння накрутак. Нумар захоўваецца ў зашыфраваным выглядзе, гэта бяспечна. Удзел у апытанні ананімны.',
    polls_take_part_p2: 'Пасля рэгістрацыі мы зможам апавяшчаць вас аб новых апытаннях і іх выніках.',
    polls_take_part_p3_1: 'Прайдзіце апытанне аб прызнанні ',
    polls_take_part_p3_2: ' прадстаўніком беларускага грамадства, упаўнаважанага выказваць пазіцыю, весці перамовы з дзяржавай і міжнароднымі структурамі, дамагацца выканання патрабаванняў беларускага народа.',
    polls_take_part_p4: 'Перайдзіце ў чат з ботам, націснуўшы на кнопку Telegram або Viber. Адпраўце слова <bold>/опрос</bold>, каб прайсці апытанне.',
    polls_about_voice: 'Аб платформе "Голас"',
    polls_about_p1: 'На платформе "Голас" зарэгістравана <bold>больш за 1,290,000</bold> беларусаў.',
    polls_about_p2_1: 'Платформа ўжо даказала сваю надзейнасць і дакладнасць. Мы правялі альтэрнатыўны падлік галасоў на прэзідэнцкіх выбарах і выявілі шматлікія фальсіфікацыі. Мы апублікавалі ',
    polls_about_p2_2: 'інтэрактыўную карту выбарчых участкаў',
    polls_about_p2_3: ' з парушэннямі і ',
    polls_about_p2_4: 'фінальную справаздачу,',
    polls_about_p2_5: ' якая зняпраўдзіла афіцыйныя вынікі ЦВК.',
    polls_about_p3: 'Дадзеныя карыстальнікаў застаюцца ў бяспецы, ніхто з іх не пацярпеў за свой голас і волевыяўленне.',
    polls_about_p4: 'Маючы давер жыхароў Беларусі і вопыт у стварэнні надзейных лічбавых рашэнняў, мы працягваем працу ў інтарэсах грамадзянскай супольнасці.',
    polls_icon_pair: 'Иконка - люди, держащиеся за руки',
    registered_with_us: 'Зарэгістравана ў "Голасе"',
    landing_call_to_action_title: 'Платформа, каб пачуць кожнага беларуса',
    landing_counters_p_1: 'Платформа "Голас" была створана беларускімі праграмістамі для кантроля за сумленнасцю выбараў і альтэрнатыўнага падліку галасоў.',
    landing_counters_p_2: 'Пасля доказу фальсіфікацый на выбарах, мы пашырылі функцыі платформы, каб яна дазваляла падлічваць колькасць удзельнікаў мірных пратэстаў.',
    landing_counters_p_3: 'У дадатак, платформа ўзаемадзейнічае з Каардынацыйнай Радай, збірая меркаванні беларусаў аб прымаемых Радай пастановах.',
    landing_counters_p_4: 'Ніжэй можна азнаёміцца з усімі праектамі платформы і далучыцца да нас.',
    landing_counters_p_5: 'Рэгіструйцеся на платформе ў адным з мэсэнжэраў ніжэй:',
    landing_counters_title: 'Зарэгістравана ў\u00A0"Голасе"',
    landing_results_title: 'Доказы фальсіфікацыі выбараў',
    landing_results_button: 'Вынікі выбараў',
    landing_results_p_1: 'Платформа "Голас" ужо сабрала больш за паўмільёна бюлетэняў, якія даказваюць, што выбары былі фальсіфікаваны, і стварыла інтэрактыўную справаздачу, дзе кожны можа ўбачыць вынік нашай працы.',
    landing_results_image_alt: 'Карта вынікаў',
    landing_projects_p_1: 'Галасаванне супраць паправак у Канстытуцыю і збор прапаноў да зменаў у Канстытуцыю Беларусі.',
    landing_projects_button_1: 'Вынікі апытання',
    landing_projects_p_2: 'Праект <bold>#ягуляю</bold> створаны, каб падлічыць і падтрымаць людзей, якія пратэстуюць супраць сфальсіфікаваных выбараў праз мірныя пратэсты.',
    landing_projects_button_2: 'Праект #ягуляю',
    landing_projects_image_alt: 'Праект апытанняў Каардынацыйнай Рады',
    landing_command_title: 'Наша каманда',
    landing_icon_alt: 'Іконка –– група натхнёных людзей',
    landing_command_p: 'Мы\u00A0–– беларусы. Незалежныя распрацоўшчыкі і неабыякавыя людзі. Наша каманда\u00A0–– гэта і кожны з вас, грамадзянін Беларусі, які хоча ўдзельнічаць у стварэнні квітнеючай і паспяховай краіны. Дзе правы і свабоды грамадзян паважаюць, а ўлада працуе на паляпшэнне жыцця людзей.',
    coordination_council_1: 'Каардынацыйная',
    coordination_council_2: 'Рада',
    coordination_council_logo_alt: 'Лагатып Каардынацыйнай Рады',
    landing_card_icon_alt_1: 'Іконка –– вагі',
    landing_card_title_1: 'Гэта законна\u00A0і ананімна',
    landing_card_content_1: 'Інфармацыя карыстальнікаў зашыфравана і надзейна абаронена. Ніхто не\u00A0даведаецца пра ваш удзел і выбар\u00A0–– нават распрацоўшчыкі. "Голас" дзейнічае ў\u00A0прававым полі і не\u00A0парушае закон.',
    landing_card_icon_alt_2: 'Іконка –– галачка',
    landing_card_title_2: 'Чаму гэтай сістэме можна давяраць?',
    landing_card_content_2: 'Мы даказалі нашу празрыстасць і адкрытасць падчас прэзідэнцкіх выбараў. Выканалі абяцанні, выявілі фальсіфікацыі, абаранілі дадзеныя карыстальнікаў. Нам давярае больш за\u00A01\u00A0мільён чалавек.',
    landing_card_icon_alt_3: 'Іконка –– сцяг',
    landing_card_title_3: 'На што гэта ўплывае?',
    landing_card_content_3: 'Апытанні праз сістэму "Голас" могуць ўплываць на\u00A0рашэнні, якія прымаюцца Каардынацыйным Саветам для развіцця нашай краіны і вываду яе з\u00A0глыбокага палітычнага крызісу.',
    landing_dreams_title: 'Беларусь марыць',
    landing_dreams_subtitle: 'Збор ідэй для новай Беларусі',
    landing_dreams_send: 'Сабраныя мары',
    faq_call_to_cation_title: 'Адказы на папулярныя пытанні',
    faq_group1_title: 'Праца спецпраекта "Голаса" #ягуляю',
    faq_group1_q1_title: 'Як мне паўдзельнічаць у праекце?',
    faq_group1_q1_part1: 'ЗАРЭГІСТРУЙЦЕСЯ Ў "ГОЛАСЕ".',
    faq_group1_q1_part2: 'Калі вы ўжо зарэгістраваліся ў чат-боце, пераходзьце да наступнага кроку.',
    faq_group1_q1_part3: 'Калі рэгіструецеся ўпершыню\u00A0— націсніце на кнопку ўлюбёнага мэсэнжэра і кіруйцеся інструкцыямі ў чат-боце. На першым кроку пацвердзіце, што адправілі нумар тэлефона (ён будзе надзейна зашыфраваны).',
    faq_group1_q1_part4: 'ПАВЕДАМІЦЕ ЧАТ-БОТУ, ШТО ВЫ ГУЛЯЕЦЕ.',
    faq_group1_q1_part5: 'Перад выхадам на прагулку актывуйце чат-бота: адпраўце яму каманду<strong>/ягуляю</strong>. Абярыце месца прагулкі: "па хаце", "на вуліцы" альбо "за мяжой". Падзяліцеся сваім месцазнаходжаннем, каб мы маглі пралічыць колькі гарадоў гуляе.',
    faq_group1_q1_part6: 'ПАДЗЯЛІЦЕСЯ ПРАГУЛКАЙ З СЯБРАМІ.',
    faq_group1_q1_part7: 'Выкарыстайце тэг <strong>#ягуляю</strong>.',
    faq_group1_q2_title: 'Калі адпраўляць дадзеныя аб сваёй прагулцы?',
    faq_group1_q2_part1: 'Учыняем падлік кожную нядзелю і ў спецыяльныя дні, пра якія мы будзем паведамляць асобна.',
    faq_group1_q2_part2: 'Паколькі падчас прагулкі Інтэрнэт можа быць недаступны, лепш за ўсё адправіць паведамленне аб прагулцы перад выхадам з хаты.',
    faq_group1_q3_title: 'Навошта боту патрэбна мая геалакацыя?',
    faq_group1_q3_part1: 'Мы хочам падлічыць у якіх гарадах мы будзем гуляць. Чым больш гарадоў шпацыруе, тым лепш!',
    faq_group1_q4_title: 'Не магу падзяліцца сваім месцазнаходжаннем, выдаецца памылка',
    faq_group1_q4_part1: 'Калі пасля націску "Адправіць месцазнаходжанне" нічога не адбываецца або выдаецца памылка, неабходна праверыць налады геалакацыі.',
    faq_group1_q4_part2: 'Для гэтага ў агульных наладах тэлефона неабходна выбраць неабходны мессенджер і дазволіць для яго выкарыстанне геалакацыі.',
    faq_group1_q4_part3: 'У выпадку выкарыстання дэсктопнай версіі\u00A0- дазволіць выкарыстанне геалакацыі у наладах браўзэра.',
    faq_group1_q5_title: 'Калі зарэгістраваўся і ў Viber, і ў Telegram дубліката не будзе?',
    faq_group1_q5_part1: 'У якасці ідэнтыфікатара выбаршчыка платформа выкарыстоўвае нумар тэлефона. Толькі апошні выбар захоўваецца як актуальны. Дубліката не будзе.',
    faq_group1_q6_title: 'Як зарэгістраваць у сістэме сваяка, які мае кнопкавы тэлефон?',
    faq_group1_q6_part1: 'Вам трэба зарэгістраваць акаўнт у Telegram на нумар вашага сваяка. Калі няма Telegram\u00A0— вось інструкцыя па ўстаноўцы на ',
    faq_group1_q6_part2: ' і на ',
    faq_group1_q6_part3: 'Калі ўжо маеце Telegram, вам трэба дадаць яшчэ адзін акаўнт у аплікацыі. Крокі наступныя:',
    faq_group1_q6_part4: 'У Telegram на смартфоне ў бакавым меню (на Android) або ў наладах профілю (iOS) выберыце "Дадаць акаўнт".',
    faq_group1_q6_part5: 'Зарэгіструйце акаўнт на нумар вашага сваяка.',
    faq_group1_q6_part6: 'На нумар вашага сваяка прыйдзе sms-код. Папярэдзьце яго, што трэба дзейнічаць шпарка: мэсэнджар дае дзве хвіліны на пацвярждэнне рэгістрацыі праз праверачны код.',
    faq_group1_q6_part7: 'Увядзіце код з пункта вышэй у акаўнце ў аплікацыі.',
    faq_group1_q6_part8: 'Далей трымайцеся стандартнага працэсу рэгістрацыі свайго выбара наі платформе "Голас", як рабілі гэта для сябе.',
    faq_group2_title: 'Бяспека',
    faq_group2_q1_title: 'Хто распрацоўвае платформу?',
    faq_group2_q1_part1: 'Каманда беларускіх распрацоўшчыкаў. Дасведчаныя інжынеры, якія працуюць у кампаніях з павышанымі патрабаваннямі да бяспекі, як валанцёры стварылі і развіваюць "Голас".',
    faq_group2_q2_title: 'Хто мае доступ да асабовых дадзеных карыстальнікаў?',
    faq_group2_q2_part1: 'Да асабовых дадзеных карыстальніка, гэта значыць да нумара тэлефона, не мае доступу ніхто, бо ён не захоўваецца ў\u00A0адкрытым выглядзе. Мы не запытваем вашыя асабістыя дадзеныя, акрамя тэлефона.',
    faq_group2_q2_part2: '<strong>Увага!</strong> Калі ў вас замежны нумар тэлефона, бот можа папрасіць вас запоўніць форму, паказаць прозвішча, імя, дату нараджэння і прымацаваць фота фрагмента пашпарта, якое пацвярджае гэтыя дадзеныя. Прадаставіць гэтыя дадзеныя бяспечна. Яны будуць выдаленыя пасля праверкі.',
    faq_group2_q3_title: 'Мой нумар тэлефона ў бяспецы?',
    faq_group2_q3_part1: 'Так. Платформа распрацаваная такім чынам, каб усе дадзеныя выбаршчыка заставаліся ў паўнейшай бяспецы. Нават калі база дадзеных трапіць у чужыя рукі, здабыць з яе асабовыя дадзеныя карыстальнікаў будзе немагчыма.',
    faq_group2_q3_part2: 'Тэхнічныя падрабязнасці: Паводле коду краіны платформа спраўджвае, што нумар з Беларусі, і хэшыруе яго (',
    faq_group2_q3_part3: 'алгарытм SHA-3 з соллю',
    faq_group2_q3_part4: '). У выніку нумар тэлефона расшыфраваць нельга.',
    faq_group2_q3_part5: 'Апроч таго, мы выкарыстоўваем строгія палітыкі бяспекі, доступ да базы мае толькі некалькі людзей, але нават яны не могуць убачыць нумар тэлефона.',
    faq_group3_title: 'Іншыя пытанні',
    faq_group3_q1_title: 'Як я магу дапамагчы платформе?',
    faq_group3_q1_part1: 'Распявядзіце пра платформу "Голас" сваім знаёмым, сябрам і блізкім, растлумачце ім, як важна абараніць свой голас, падзяліцеся спасылкай на ',
    faq_group3_q1_part2: 'наш сайт',
    faq_group3_q1_part3: ' у сацсетках.',
    faq_group3_q1_part4: 'Дапамажыце родным і блізкім, якія не маюць смартфонаў',
    faq_group3_q1_part5: ', улічыць іх выбар у платформе "Голас".',
    faq_group3_q2_title: 'У мяне ёсць пытанне. Як з вамі звязацца?',
    faq_group3_q2_part1: 'Паколькі цікавасць да платформы вялікая, а пытанняў да нас паступае вельмі шмат, мы будзем удзячныя, калі вы вывучыце гэтую старонку асабліва ўважліва\u00A0– адказы на многія пытанні мы ўжо пакінулі тут. Калі маеце іншыя пытанні пра працу нашай платформы альбо прапановы, калі ласка, раскажыце пра гэта больш падрабязна ',
    faq_group3_q2_part2: 'вось тут!',
    polls_results_title: 'Вынікі апытання',
    polls_image_alt: 'Мал/нак карты з вынікамі апытання ў падтрымку Каардынацыйнага Савета',
    polls_results_p1: '"Голас" падвёў вынікі апытання ў падтрымку Каардынацыйнага Савета.',
    polls_results_p2: 'Давер беларусаў быў відавочны ад пачатку апытання, але асабліва важна агучыць вынікі: больш за <strong>522,000</strong>\u00A0карыстальнікаў пакінулі свае галасы.',
    polls_results_p3: '<strong>96.75%</strong> з іх прызналі Каардынацыйны Савет паўнамоцным прадстаўніком сваіх інтарэсаў у дыялогу з дзяржавай. <strong>1.25%</strong> выказаліся супраць, <strong>2%</strong> не змаглі адказаць.',
    polls_results_p4: 'Так размеркаваліся галасы <strong>504,289</strong>\u00A0беларусаў, якія, па стане на\u00A025\u00A0верасня, падтрымалі Каардынацыйны Савет:',
    polls_results_line_1: 'Брэсцкая вобл.',
    polls_results_line_2: 'Віцебская вобл.',
    polls_results_line_3: 'Гомельская вобл.',
    polls_results_line_4: 'Гродненская вобл.',
    polls_results_line_5: 'Мінская вобл.',
    polls_results_line_6: 'Магілёўская вобл.',
    polls_results_line_7: 'Мінск',
    image_nina_alt: 'Я гуляю - Ніна Багінская, малюнак',
    selected: 'Выбрана',
    pollbuilder_call_header: 'Збор прапаноў да змяненняў у\u00A0Канстытуцыю Беларусі',
    pollbuilder_disclaimer: '* Па змаўчанні ўсюды вылучаныя першыя варыянты адказу. Гэта зроблена для таго, каб усе людзі, незалежна ад узроўню тэхнічнай пісьменнасці, разумелі, як выбраць варыянт. Каб змяніць выбар, дастаткова націснуць на іншую картку.',
    pollbuilder_title_1: 'Дзеянні да змянення Кастытуцыі',
    pollbuilder_title_2: 'Прапанаваныя змены ў\u00A0Канстытуцыю',
    accordion_info_show_title: 'Даведацца дэталі',
    accordion_info_hide_title: 'Не паказваць дэталі',
    pollbuilder_title_3: 'Вашыя прапановы (не\u00A0абавязкова)',
    pollbuilder_desc_3: 'Магчыма ёсць нешта вельмі важнае, што мы забыліся –– пакіньце, калі ласка, свой каментарый, і мы абавязкова перададзім яго спецыялістам па канстытуцыйным праве ў нашай камандзе.',
    pollbuilder_title_4: 'Раскажыце крыху пра сябе (не\u00A0абавязкова)',
    pollbuilder_desc_4: 'Гэты крок не абавязковы, але дазволіць нам лепш разумець нашу аўдыторыю.',
    pollbuilder_select_sex_title: 'Пол',
    pollbuilder_select_sex_1: 'Жаночы',
    pollbuilder_select_sex_2: 'Мужчынскі',
    pollbuilder_select_age_title: 'Узрост',
    pollbuilder_select_age_0: 'Да 18',
    pollbuilder_select_age_1: '18-24',
    pollbuilder_select_age_2: '25-34',
    pollbuilder_select_age_3: '35-44',
    pollbuilder_select_age_4: '45-54',
    pollbuilder_select_age_5: '55-64',
    pollbuilder_select_age_6: '65-74',
    pollbuilder_select_age_7: '75 і старэй',
    pollbuilder_select_education_title: 'Адукацыя',
    pollbuilder_select_education_1: 'Няпоўная або агульная сярэдняя (школа)',
    pollbuilder_select_education_2: 'Прафесійна-тэхнічная або сярэдняя спецыяльная (ПТВ, каледж, тэхнікум)',
    pollbuilder_select_education_3: 'Няскончанае вышэйшае (больш за 3 курсы ВНУ)',
    pollbuilder_select_education_4: 'Вышэйшая',
    pollbuilder_select_education_5: 'Навуковая ступень',
    pollbuilder_select_work_title: 'Дзе вы працуеце?',
    pollbuilder_select_work_1: 'Працую на дзяржаўным прадпрыемстве, у дзяржаўнай арганізацыі',
    pollbuilder_select_work_2: 'Працую на прыватным прадпрыемстве, у кампаніі, арганізацыі',
    pollbuilder_select_work_3: 'Маю свой бізнэс',
    pollbuilder_select_work_4: 'Працую ў некамерцыйнай (грамадскай) арганізацыі',
    pollbuilder_select_work_5: 'ІП, самазаняты(ая), фрылансер',
    pollbuilder_select_work_6: 'Вучуся у сярэдняй спецыяльнай або вышэйшай навучальнай установе',
    pollbuilder_select_work_7: 'Не працую, вяду хатную гаспадарку або знаходжуся ў дэкрэтным адпачынку',
    pollbuilder_select_work_8: 'На пенсіі па ўзросту або інваліднасці',
    pollbuilder_select_work_9: 'Часова не працую, шукаю працу',
    pollbuilder_select_location_title: 'Дзе Вы ў цяперашні час пражываеце?',
    pollbuilder_select_location_1: 'Мінск',
    pollbuilder_select_location_2: 'Абласны цэнтр',
    pollbuilder_select_location_3: 'Буйны горад з насельніцтвам больш за 100 тыс. чал.',
    pollbuilder_select_location_4: 'Сярэдні горад (30-100 тыс. чал.)',
    pollbuilder_select_location_5: 'Малы горад (да 30 тыс. чал)',
    pollbuilder_select_location_6: 'Іншы населены пункт (сяло, гарадскі пасёлак, аграгарадок, пасёлак, вёска)',
    pollbuilder_select_location_7: 'Не хачу адказваць',
    pollbuilder_select_marital_title: 'Ваша сямейнае становішча',
    pollbuilder_select_marital_1: 'Халасты(ая) / Разведзены(ая) / Удавец(удава)',
    pollbuilder_select_marital_2: 'У шлюбе / У адносінах',
    pollbuilder_submit_desc: 'Вы можаце прагаласаваць толькі адзін раз і не зможаце выправіць свой адказ, таму прымайце рашэнне узважана, азнаёміўшыся з матэрыяламі вышэй. Мы пастараліся максімальна падрабязна апісаць усе перавагі і недахопы ўсіх варыянтаў.',
    pollbuilder_submit: 'Прагаласаваць',
    pollbuilder_submit_wait: 'Дасылаем...',
    pollbuilder_select_region_title: 'У якой вобласці Вы пражываеце?',
    pollbuilder_select_region_1: 'Брэсцкая вобласць',
    pollbuilder_select_region_2: 'Віцебская вобласць',
    pollbuilder_select_region_3: 'Гомельская вобласць',
    pollbuilder_select_region_4: 'Гродзенская вобласць',
    pollbuilder_select_region_5: 'Магілёўская вобласць',
    pollbuilder_select_region_6: 'Мінская вобласць',
    pollbuilder_select_region_7: 'Мінск',
    pollbuilder_select_region_8: 'Не в Беларусі',
    pollbuilder_select_do_not_answer: 'Іншае / не хачу адказваць',
    // constitution translations
    const_main_title: 'Кожны грамадзянін мае права ўплываць на будучыню Беларусі',
    const_main_p_1: '"Голас" выступае супраць ініцыятывы дзяржавы мяняць Канстытуцыю цяпер.',
    const_main_p_2: 'Спачатку павінны быць выкананы патрабаванні беларускай грамадзянскай супольнасці:',
    const_main_list_1: 'правесці новыя сумленныя выбары;',
    const_main_list_2: 'вызваліць палітвязняў;',
    const_main_list_3: 'прыцягнуць да адказнасці вінаватых.',
    const_main_p_3: 'Толькі потым - новая Канстытуцыя.',
    const_main_p_4: 'Цяперашняя ўлада нелегітымная: выбары сфальсіфікаваныя, і «Голас» гэта даказаў. Увагу ад несапраўдных выбараў спрабуюць адцягнуць паспешліва канстытуцыйнай рэформай. Дзяржава стварае ілюзію дэмакратыі, каб навязаць беларусам сваю версію галоўнага закона краіны.',
    const_main_p_5: 'Апытанне складаецца з двух блокаў:',
    const_main_list_2_1: 'Ці можна праводзіць рэформу да новых выбараў?',
    const_main_list_2_2: 'Як беларусы бачаць асноўныя прынцыпы Канстытуцыі?',
    const_main_p_6: 'Ваш голас важны — абярыце ',
    const_main_p_6_1: ' ці ',
    const_main_p_6_2: ' і прайдзіце апытанне.',
    const_us_already: 'Зарэгістравана ў\u00A0"Голасе"',
    const_why_trust: 'Чаму нам давяраюць?',
    honest_people_logo_alt: 'Лагатып ініцыятывы Сумленныя Людзі',
    voice_logo_alt: 'Лагатып онлайн-платформы Голас',
    const_howitworks_p_1: 'Націсніце на кнопку зручнага вам мессенджера, каб зарэгістравацца ў чат-робата "Голаса" у Viber або Telegram.',
    const_howitworks_p_2: 'Пры рэгістрацыі чат-бот спытае нумар вашага тэлефона: гэта трэба, каб пацвердзіць унікальнасць карыстальніка. Як толькі вы ўведзяце нумар у сістэму, ён зашыфруется: яго не зможа ўбачыць ніхто, уключаючы распрацоўшчыкаў.',
    const_howitworks_p_3: 'Рэгістрацыя на платформе "Голас" ананімная і бяспечная.',
    const_whychange_title: 'Навошта ўдзельнічаць у апытанні пра Канстытуцыю',
    const_whychange_p_1: 'Каб гучна і хорам заявіць: беларусы супраць паспешнай рэформы, беларусы патрабуюць новых сумленных выбараў.',
    const_whychange_p_2: 'Каб наглядна паказаць, што нязгодных з захопам улады, гвалтам і рэпрэсіямі - большасць.',
    const_whychange_p_3: 'Каб не дазволіць уладам зноў перапісаць Канстытуцыю пад сябе і замацаваць у ёй сваю волю.',
    const_whychange_p_4: 'Каб даць зразумець, якая Канстытуцыя на самой справе патрэбна беларускаму грамадству.',
    const_whyVoice_trust: 'Чаму "Голасу" давяраюць',
    const_whyVoice_p_1: 'Платформа "Голас" створана камандай незалежных распрацоўшчыкаў для таго, каб у кожнага грамадзяніна нашай краіны быў просты, празрысты і надзейны інструмент для ўдзелу ў развіцці Беларусі.',
    const_whyVoice_p_2: '"Голас" працуе, каб ваша меркаванне пачулі.',
    const_whyVoice_p_3: 'Платформа заслужыла давер беларускіх выбаршчыкаў, калі грунтоўна, падрабязна і неабвержна даказала фальсіфікацыі на прэзідэнцкіх выбарах. У пацверджанне - ',
    const_interactive_map: 'інтэрактыўная карта участкаў',
    and: ' і ',
    const_final_report: 'выніковая справаздача.',
    const_whyVoice_p_4: '"Голас" - яркая праява прамой дэмакратыі, калі грамадзянская супольнасць сама вырашае, у якой краіне і па якіх законах яму жыць. Далучайцеся!',
    voted_for_constitution: 'Галасоў у\u00A0апытаннi аб\u00A0Канстытуцыi',
    we: 'Мы',
    // thankyou translations
    thx_heading: 'ЗБОР ПРАПАНОВАЎ ДА ЗЬМЕНЫ У КАНСТЫТУЦЫЮ БЕЛАРУСI',
    thx_thankyou: 'ДЗЯКУЙ ЗА ВАШ ГОЛАС!',
    voted_thankyou: 'ВЫ УЖО ГАЛАСАВАЛI!',
    thx_p_1: 'Мы збярэм дадзенныя аб тым, што думаюць беларусы пра папраўкі ў Канстытуцыю.  І <strong>у канцы тыдня</strong> дашлем вам інструкцыю, як усім разам адправіць лісты чыноўнікам праз нашу платформу.',
    thx_p_2: 'Наша агульная мэта: спачатку новыя выбары - потым Канстытуцыя.',
    thx_socials_p: 'Падпісвайцеся на нашы каналы, каб першымі даведвацца аб ўсiх навінах "Голасу"',
    thx_sharing_p: 'Расказвайце блізкім і сябрам пра нас',
    alert_reload: 'Нешта пайшло не так. <strong>Паспрабуйце перазагрузіць старонку!</strong>',
    // poll results translations
    poll_results_call_header: 'Вынiкi апытання',
    poll_results_title: 'Падрыхтоўка лiста да адпраўкi',
    poll_results_disclaimer: 'Рэкамендацыя "ГОЛАСА": не ўступаць у дыялог з нелегітымнай уладай',
    poll_results_button: 'Стварыць лiст',
    poll_results_disclaimer_p_1_start: 'У жніўні "Голас" даказаў, што выбары былі скрадзеныя. Усе пацверджання - у падрабязнай',
    poll_results_disclaimer_p_1_link_1: 'справаздачы',
    poll_results_disclaimer_p_1_middle: ' i на ',
    poll_results_disclaimer_p_1_link_2: 'карце фальсіфікацый.',
    poll_results_disclaimer_p_2: 'Цяперашняя ўлада - нелегітымная. У яе няма законных падстаў праводзіць рэформы. Гэтыя падставы ёсць толькі ў грамадзянскай супольнасці Беларусі.',
    poll_results_disclaimer_p_3: 'Той, хто трымае ўладу злачынным шляхам, не стане мяняць Канстытуцыю ў інтарэсах народа. Няма ніякіх гарантый, што прапановы аб папраўках, якія дзяржава заклікае дасылаць да 25 кастрычніка, будуць сабраныя сумленна і сапраўды ўвойдуць у галоўны закон нашай краіны.',
    poll_results_disclaimer_p_4: 'Платформа "Голас" далучаецца да абсалютнай большасці галасоў беларусаў і не рэкамендуе ўступаць у дыялог з тымі, хто спрабуе правесці паспешную рэформу да новых сумленных выбараў. Над новай Канстытуцыяй павінны працаваць тыя, для каго, што думаюць беларусы - вышэйшая каштоўнасць.',
    poll_results_user_choice: 'ВАШ АДКАЗ',
    poll_results_people: 'чал',
    poll_results_offers_title: 'Вашы прапановы',
    poll_results_call_tab_1: 'Меркаванне большасці',
    poll_results_call_tab_2: 'Мае адказы',
    // mail translations
    mail_choose_medium: 'Выбраць спосаб адпраўкі лістоў нязгоды',
    mail_choose_mail_option: 'Выбраць на\u00A0аснове якіх адказаў стварыць ліст',
    mail_nochoose_mail_option: 'Стварыць ліст на\u00A0аснове меркавання большасці',
    mail_disclaimer_title: 'Платформа "Голaс":',
    mail_disclaimer_list_1: 'выкарыстоўвае копіі электронных зваротаў толькі і выключна ў\u00A0мэтах належнага падліку колькасці накіраваных прапаноў па змяненні Канстытуцыі;',
    mail_disclaimer_list_2: 'не ажыццяўляе і не будзе ажыццяўляць збор, апрацоўку, захоўванне і / або перадачу персанальных дадзеных, атрыманых у электронных зваротах;',
    mail_disclaimer_list_3: 'прадпрымае і прыме ўсе меры па абароне персанальных дадзеных, атрыманых у электронных зваротах.',
    mail_post_title: 'ЗВЫЧАЙНАЙ ПОШТАЙ',
    mail_post_instruction_1: 'Раздрукуйце ліст, упішыце свае ФII ды адрас i пакіньце подпіс унізе.',
    mail_post_instruction_2: 'Сфатаграфуйце ліст і адпраўце яго ў чат-бот платформы "Голас".',
    mail_post_instruction_3: 'Далей адпраўце ліст поштай па наступным адрасе:',
    mail_post_instruction_address_1: 'Палата прадстаўнікоў Нацыянальнага сходу Рэспублікі Беларусь',
    mail_post_instruction_address_2: '220010, Рэспубліка Беларусь, г. Мінск, вул. Савецкая, 11 (Дом Урада)',
    mail_post_instruction_4: 'Калі ў вас няма друкаркі ці вы не ведаеце, як раздрукаваць ліст з тэлефона, скапіруйце спасылку на ліст па кнопцы "Скапіяваць спасылку" і перашліце яе ў цэнтр друку або знаёмым, у якіх ёсць прынтэр.',
    mail_email_title: 'EMAIL',
    mail_email_instruction_1: 'Зрабіце фота экрана вашага кампутара ці скрыншот з адкрытым лістом. Адпраўце фота ў чат-бот платформы "Голас". Так мы захаваем і падлічым ўсе лісты.',
    mail_email_instruction_2: '',
    mail_email_instruction_2_start: 'Скапіруйце тэкст ліста ўнізе, адпраўце яго на электронную пошту ',
    mail_email_instruction_2_end: ' і свайму дэпутату, адрас якога можна знайсці ',
    mail_email_instruction_2_link: 'па спасылцы.',
    mail_email_instruction_3: 'ДАДАТАК да пісьма магчыма спампаваць па спасылцы: ',
    mail_email_instruction_4: 'Калі вы не ведаеце, як адправіць ліст па email, скапіруйце спасылку на ліст па кнопцы "Скапіяваць спасылку" і перашліце яе сваякам ці знаёмым, якія могуць вам дапамагчы. Папытайце iх зрабiць фота экрана цi зрабiць скрыншот лiста.',
    mail_mail_body_title: 'ЛIСТ НЯЗГОДЫ',
    mail_open_mailto: 'АДКРЫЦЬ ЛIСТ У ПАШТОВЫМ КЛIЕНЦЕ',
    mail_open: 'Адкрыць',
    mail_print_mail_button: 'Раздрукаваць ліст',
    mail_copy_link_button: 'Скапіяваць спасылку',
    mail_mailto_button: 'Адкрыць у паштовым кліенце',
    mail_copy_text_button: 'Скапіяваць тэкст лiста',
    mail_header_p_1: 'Палата прадстаўнікоў Нацыянальнага сходу Рэспублікі Беларусь',
    mail_header_p_2: '220010, Рэспубліка Беларусь, г. Мінск, вул. Савецкая, 11 (Дом Урада)',
    mail_header_p_3: 'Электронны адрас для адпраўкі карэспандэнцыі: Zakon@house.gov.by',
    mail_body_p_1_1: 'Я, ',
    mail_body_p_1_2: ', накіроўваю на адрас Палаты прадстаўнікоў Нацыянальнага сходу свае прапановы па ўнясенні зменаў у Канстытуцыю Рэспублікі Беларусь. Прашу ўлічыць іх у далейшай працы.',
    mail_body_block_1_title: 'Раздзел 1. Я ПАТРАБУЮ НЕАДКЛАДНА ПРАВЕСЦІ НОВЫЯ СУМЛЕННЫЯ ВЫБАРЫ ПРЭЗІДЭНТА РЭСПУБЛІКІ БЕЛАРУСЬ. БЕЗ ІХ ПРАВЯДЗЕННЕ КАНСТЫТУЦЫЙНАЙ РЭФОРМЫ ЛІЧУ НЕМАГЧЫМЫМ.',
    mail_body_block_1_p_1: 'Я выказваю нязгоду са спробай ініцыяваць змены ў Канстытуцыю ва ўмовах цяперашняга палітычнага крызісу, які выкліканы фальсіфікацыямі на прэзідэнцкіх выбарах 9 жніўня 2020 года.',
    mail_body_block_1_p_2: 'У якасці доказу шматлікіх парушэнняў пры падліку галасоў на выбарчых участках прыкладаю спасылку на справаздачу платформы "Голас": ',
    mail_body_block_1_p_3: 'Я лічу, што любыя змены ў Канстытуцыю могуць быць унесены толькі пасля выканання наступных умоў:',
    mail_body_block_1_p_3_item1: 'спыніць палітычны пераслед грамадзян з боку ўладаў, прыцягнуць да законнай адказнасці і ўзбудзіць крымінальныя справы супраць вінаватых у забойствах, гвалце і катаваннях;',
    mail_body_block_1_p_3_item2: 'вызваліць усіх палітычных зняволеных і вязняў сумлення, скасаваць неправамерныя судовыя пастановы, выплаціць кампенсацыі ўсім пацярпелым;',
    mail_body_block_1_p_3_item3: 'прызнаць выбары 9 жніўня 2020-га несапраўднымі і правесці новыя выбары Прэзідэнта Рэспублікі Беларусь. Яны мусяць быць праведзеныя з няўхільным выкананнем міжнародных стандартаў і нацыянальнага заканадаўства, а таксама пры новым складзе ўсіх камісій па выбарах, улічваючы Цэнтральную камісію Рэспублікі Беларусь па выбарах і правядзенні рэспубліканскіх рэферэндумаў.',
    mail_body_block_1_p_4: 'Мая прапанова зводзіцца да таго, што змена Канстытуцыі магчымая толькі пасля новых выбараў і выканання астатніх пералічаных патрабаванняў.',
    mail_body_block_2_title: 'Раздзел 2. Я ВЫКАЗВАЮ НЕДАВЕР ЦЯПЕРАШНЯЙ УЛАДЗЕ БЕЛАРУСІ, ЯКАЯ ІГНАРУЕ ПАТРАБАВАННІ ГРАМАДЗЯН. Я ЛІЧУ, ШТО НАВЯЗАНАЯ РЭДАКЦЫЯ КАНСТЫТУЦЫІ БУДЗЕ СУПЯРЭЧЫЦЬ ВОЛІ НАРОДА. З ГЭТАЙ ПРЫЧЫНЫ НАКІРОЎВАЮ СПІС ТЫХ ПАПРАВАК, ЯКІЯ ЛІЧУ ПРАВІЛЬНЫМ УНЕСЦІ Ў КАНСТЫТУЦЫЮ ПАСЛЯ ПРАВЯДЗЕННЯ НОВЫХ СУМЛЕННЫХ ВЫБАРАЎ.',
    mail_body_block_2_p_1: 'Прапанаваныя змены ў Канстытуцыю пасля выканання ўмоваў, выказаных у Раздзеле 1:',
    proposed_changes_branches_of_government: 'Размеркаваць паўнамоцтвы паміж Прэзідэнтам, Парламентам, урадам i судамі па прынцыпах, выкладзеных у першапачатковай рэдакцыі Канстытуцыі 1994 года (да ўнясення змяненняў па выніках рэспубліканскіх рэферэндумаў 24.11.1996 г., 17.10.2004 г.).',
    proposed_changes_president_term_limit: 'Замацаваць у Канстытуцыі, што адна і тая ж асоба можа быць Прэзідэнтам не больш за два тэрміны, без залежнасці ад змены Канстытуцыі ў перыяд знаходжання гэтай асобы на пасадзе Прэзідэнта.',
    proposed_changes_integration_with_foreign_countries: 'Замацаваць у Канстытуцыі, што ўваходжанне Рэспублікі Беларусь у міждзяржаўныя утварэнні з удзелам іншых дзяржаў і выхад з іх магчымыя толькі шляхам правядзення рэспубліканскага рэферэндуму.',
    proposed_changes_state_language: 'Замацаваць у Канстытуцыі, што дзяржаўнай мовай Рэспублікі Беларусь з\'яўляецца беларуская мова, але Рэспубліка Беларусь павінная забяспечваць права свабоднага карыстання рускай мовай як мовай міжнацыянальных зносінаў.',
    proposed_changes_republican_referendums: 'Замацаваць у Канстытуцыі, што рэспубліканскія рэферэндумы мусяць прызначацца Парламентам па ўласнай ініцыятыве, прапанове Прэзідэнта альбо пэўнай колькасці грамадзян, якія валодаюць выбарчым правам.',
    proposed_changes_parliament_houses_number: 'Замацаваць у Канстытуцыі, што Парламент Рэспублікі Беларусь з\'яўляецца аднапалатным, усе дэпутаты Парламента выбіраюцца грамадзянамі Рэспублікі Беларусь на падставе ўсеагульнага, свабоднага, роўнага, прамога выбарчага права пры тайным галасаванні.',
    mail_body_block_3_title: 'Раздзел 3. Я НАСТОЙВАЮ НА ЗАКОННЫМ ПАРАДКУ ЎНЯСЕННЯ ЗМЕНАЎ У КАНСТЫТУЦЫЮ.',
    mail_body_block_3_p_1: 'З той прычыны, што пытанні размеркавання паўнамоцтваў паміж галінамі ўлады, абмежавання тэрміну паўнамоцтваў Прэзідэнта, ўваходжання Рэспублікі Беларусь у міждзяржаўныя ўтварэнні і выхаду з іх, а таксама колькасці палат Парламента вызначаныя Раздзеламі I і IV Канстытуцыі, якія могуць быць змененыя толькі шляхам рэферэндуму, лічу неабходным адзначыць, што для змены Канстытуцыі неабходны рэспубліканскі рэферэндум.',
    mail_body_block_3_p_2: 'Паводле Раздзела 1 маіх прапаноў, лічу, што правядзенне рэспубліканскага рэферэндуму можа ажыццяўляцца толькі пасля выканання ўсіх пералічаных у раздзеле 1 умоў, новым складам усіх выбарчых камісій, у тым ліку і Цэнтральнай камісіі Рэспублікі Беларусь па выбарах і правядзенні рэспубліканскіх рэферэндумаў.',
    mail_footer_p_1: 'З павагай,',
    mail_footer_p_2: 'адрас пражывання: ',
    mail_helper_text: 'Абавязковае поле',
    mail_label_name: 'Увядзіце ваша імя і прозвішча для генерацыі ліста',
    mail_placeholder_name: 'Іваноў Іван Іванавіч',
    mail_label_address: 'Увядзіце Ваш адрас рэгістрацыі',
    mail_placeholder_address: 'г. Мінск, вул Леніна 23-34',
    mailto_subject: 'Прапановы па змене Канстытуцыі',
    mail_warning_p_1: 'У некаторых браўзэрах кнопка "РАЗДРУКАВАЦЬ ЛІСТ" можа працаваць некарэктна. У такіх выпадках:',
    mail_warning_list_1_start: 'паспрабуйце адкрыць гэтую спасылку - ',
    mail_warning_list_1_end: ' - у іншым браўзэры або на іншай прыладзе (лепш за ўсё друк працуе ў Google Chrome на кампутары),',
    mail_warning_list_2: 'адпраўце дакумент на друк праз інтэрфейс браўзэра (ctrl + p на кампутары, меню-> друк на мабільным прыладзе),',
    mail_warning_list_3: 'папрасіце дапамагчы раздрукаваць ліст кагосьці са знаёмых / сяброў.',
    mail_warning_p_2: 'Калі кнопка "АДКРЫЦЬ У ПАШТОВЫМ КЛIЕНЦЕ" працуе некарэктна Вы можаце скапіяваць тэкст і ўставіць яго ў ліст.',
    mail_warning_p_3_start: 'Таксама Вы можаце паспрабаваць адкрыць гэтую спасылку - ',
    mail_warning_p_3_end: ' - у іншым браўзэры або выкарыстаць іншы паштовы кліент.',
    mail_copy_attachment: 'Кнопка дазваляе скапіяваць ТОЛЬКІ ТЭКСТ. ДАДАТАК неабходна спампаваць і ўставіць ўручную!',
    mail_copy_attachment_done: 'Тэкст скапіяваны.',
    mail_copy_link_done: 'Спасылка скапiявана',
    mail_link_in_progress: 'Ствараем спасылку...',
    poll_res_first_desc: 'Неабходна ўмовы да ўступлення зменаў у Канстытуцыю Беларусі:',
    go_counter_title: 'Гуляе 19-20 снежня',
    go_last_title: 'Мінулыя шпацыры',
    go_last_p1: 'Раней у нядзелю ў мірных пратэстах узялі ўдзел <strong>беларусы з больш за 400 гарадоў, вёсак ды мястэчак краіны,</strong> а таксама неабыякавыя <strong>жыхары з болей за 1400 гарадоў ды вёсак з 87 краін!</strong>',
    go_last_p2: 'На мапе колерам закадаваная колькасць людзей, якія гулялі ў горадзе (чым “больш чырвоная” кропка, тым больш чалавек гуляла ў гэтым месцы).',
    letter_title: 'Навагодняя паштоўка для А.Г. Лукашэнка',
    letter_subtitle: 'Мары беларусаў, сабраныя праз праект "Беларусь Марыць"',
    letter_counter: 'Мары для Лукашэнкі',

    'poll.intro.header': 'АПЫТАННЕ ПРА КАЛЕКТЫЎНЫ УДЗЕЛ У ПРАТЭСТАВЫХ АКТЫЎНАСЦЯХ',

    'poll.intro.introduction.text': `
      Новае апытанне “Голаса” вельмі важнае. Яно дапаможа вызначыць <b>дзейныя</b> і <b>бяспечныя</b> спосабы далейшай барацьбы з несправядлівасцю, хлуснёй, самавольствам і рэпрэсіямі ў Беларусі. Адкажыце на пытанні і паведаміце нам, якія дзеянні вы гатовыя рабіць дзеля перамогі і свабоды.
      <br/>
      <br/>
      Для стварэння гэтага апытання каманда платформы “Голас” атрымала рэкамендацыі незалежных палітолагаў, сацыёлагаў і эканамістаў. На падставе гэтых прапаноў і ўласнай экспертызы мы сфармулявалі дакладны набор магчымых дзеянняў, якія ва ўмовах прававога бязладдзя не паставяць людзей пад удар, але дапамогуць хутчэй прыбраць дыктатуру.
    `,
    'poll.intro.introduction.points.first.header': 'Навошта ладзіцца гэтае апытанне',
    'poll.intro.introduction.points.first.text': `
      У Беларусі адбылася палітычная і эканамічная катастрофа. Наспявае яшчэ і сацыяльная.
      <br/>
      <br/>
      Выбары сфальсіфікаваныя. Нашая краіна — антырэкардсмен па колькасці палітзняволеных. Людзей збіваюць, катуюць і забіваюць, але супраць сілавікоў не заведзеная ніводная крымінальная справа. Рэпрэсіі адно ўзмацняюцца, і рэжым спрабуе максімальна хутка і жорстка заглушыць галасы актыўных грамадзян.
      <br/>
      <br/>
      Гэтае апытанне — для кожнага, хто гатовы бяспечна дзейнічаць, каб абараніць свае правы і свабоду.
    `,
    'poll.intro.introduction.points.second.header': 'Што патрэбна ад кожнага з вас',
    'poll.intro.introduction.points.second.text': `
      Прайдзіце апытанне і адзначце гатоўнасць да ўдзелу ў агульным плане актыўнасцей, якія ўратуюць Беларусь ад таталітарнай будучыні.
      <br/>
      <br/>
      Электронныя звароты, барацьба з прапагандай і іншыя бяспечныя з асабістага пункту гледжання дзеянні грамадзян Беларусі дапамогуць накіраваць калектыўную энергію на аслабленне рэжыму.
    `,
    'poll.intro.introduction.points.third.header': 'Як гэта працуе: канкрэтныя механікі бяспечнага пратэсту',
    'poll.intro.introduction.points.third.text': `
      У залежнасці ад вашых адказаў мы будзем прапаноўваць вам персанальныя інструкцыі. Выконваючы іх разам з сотнямі тысяч беларусаў-аднадумцаў, вы зможаце паўплываць на будучыню сваёй краіны без рызыкі для сябе. 
    `,

    'poll.intro.next.disclaimer': `
      Вы можаце прагаласаваць толькі адзін раз і не зможаце выправіць свае адказы пазней, таму прымайце рашэнне абдумана.
      <br/>
      Мы пастараліся максімальна ўлічыць усе магчымыя варыянты адказаў
    `,
    'poll.intro.next.label': 'Пачаць апытанне',

    'poll.appeals_and_info.header': 'Звароты грамадзян і распаўсюджванне інфармацыі',
    'poll.appeals_and_info.introduction.text': `
      Новае апытанне “Голаса” вельмі важнае. Яно дапаможа вызначыць дзейныя і бяспечныя спосабы барацьбы з несправядлівасцю, хлуснёй, самавольствам і рэпрэсіямі ў Беларусі. Адкажыце на пытанні і паведаміце нам, якія дзеянні вы гатовыя рабіць дзеля перамогі і свабоды.
      <br/>   
      <br/>
      У залежнасці ад вашых адказаў мы будзем прапаноўваць вам <b>персанальныя інструкцыі</b>. Выконваючы іх разам з сотнямі тысяч беларусаў-аднадумцаў, вы зможаце паўплываць на будучыню сваёй краіны без рызыкі для сябе.
    `,

    'poll.appeals_and_info.questions.international_appeals.text': 'Ці гатовыя вы накіроўваць звароты ў замежныя кампаніі і міжнародныя арганізацыі праз электронную пошту і пісаць каментары ў сацсетках, каб прыцягнуць увагу сусветнай супольнасці да праблем Беларусі?',
    'poll.appeals_and_info.questions.international_appeals.answers.yes_with_comments.text': 'Так, буду дасылаць электронныя паведамленні і пісаць каментары ў сацсетках',
    'poll.appeals_and_info.questions.international_appeals.answers.yes.text': 'Так, буду пісаць толькі электронныя лісты',
    'poll.appeals_and_info.questions.international_appeals.answers.no.text': 'Не, не буду пісаць',

    'poll.appeals_and_info.questions.domestic_appeals.text': 'Ці гатовыя вы пісаць афіцыйныя звароты ў дзяржаўныя органы Рэспублікі Беларусь: у выканкамы, міністэрствы, сілавыя структуры, дэпутацкія прыёмныя і г.д.?',
    'poll.appeals_and_info.questions.domestic_appeals.answers.yes_all.text': 'Так, буду дасылаць звароты ў любыя дзяржорганы',
    'poll.appeals_and_info.questions.domestic_appeals.answers.yes.text': 'Так, буду дасылаць звароты ва ўсе дзяржорганы, акрамя сілавых структур',
    'poll.appeals_and_info.questions.domestic_appeals.answers.no.text': 'Не, не буду рабіць нічога з пералічанага',

    'poll.appeals_and_info.questions.domestic_info_distribution.text': 'Ці гатовыя вы распаўсюджваць улёткі пра сітуацыю ў Беларусі, каб больш людзей ведала пра тое, што адбываецца ў краіне?',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_all_country.text': 'Так, буду ездзіць па ўсёй краіне і распаўсюджваць улёткі',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_my_town.text': 'Так, буду распаўсюджваць улёткі толькі ў сваім населеным пункце',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_my_building.text': 'Так, буду распаўсюджваць улёткі толькі па паштовых скрынях у сваім доме/пад’ездзе',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.no.text': 'Не, не буду распаўсюджваць',

    'poll.appeals_and_info.questions.open_letter.text': 'Ці гатовыя вы падпісаць адкрыты ліст за адстаўку Лукашэнкі, каб паказаць недавер да дзейнай улады?',
    'poll.appeals_and_info.questions.open_letter.answers.yes_open.text': 'Так, зраблю гэта адкрыта',
    'poll.appeals_and_info.questions.open_letter.answers.yes_with_1000.text': 'Так, але хачу зрабіць гэта ананімна, пакуль не набярэцца больш за 1000 іншых подпісаў',
    'poll.appeals_and_info.questions.open_letter.answers.yes_anonymously.text': 'Так, але хачу зрабіць гэта ананімна, незалежна ад колькасці сабраных подпісаў',
    'poll.appeals_and_info.questions.open_letter.answers.no.text': 'Не, я не хачу падпісваць адкрытыя лісты',

    'poll.appeals_and_info.questions.telegram_for_family.text': 'Ці гатовыя вы дапамагчы сваім сваякам усталяваць “Тэлеграм” альбо “Вайбер”, каб даць ім доступ да альтэрнатыўных крыніц інфармацыі?',
    'poll.appeals_and_info.questions.telegram_for_family.answers.done.text': 'Так, ужо зрабіў гэта',
    'poll.appeals_and_info.questions.telegram_for_family.answers.yes_instruction.text': 'Так, дапамагу, калі вы дасце простую і зразумелую інструкцыю ',
    'poll.appeals_and_info.questions.telegram_for_family.answers.yes_neutral.text': 'Так, дапамагу, але з доступам да нейтральных крыніц інфармацыі (радыкальная рыторыка некаторых каналаў адпудзіць маіх сваякоў)',
    'poll.appeals_and_info.questions.telegram_for_family.answers.no.text': 'Не, не буду гэтым займацца',
    'poll.appeals_and_info.next.disclaimer': 'Для переходу да наступнага блока адкажыце на ўсе пытанні на гэтай старонцы',

    'poll.appeals_and_info.back.label': 'Вярнуцца назад',
    'poll.appeals_and_info.next.label': 'Наступны блок',

    'poll.economy.header': 'ЭКАНОМІКА',
    'poll.economy.introduction.text': `
      Цяпер усе даступныя рэсурсы краіны ідуць на захаванне дзейнай улады. Гэта павялічвае і без таго сур’ёзныя праблемы беларускай эканомікі і вядзе да непазбежнай катастрофы.
      <br/>
      <br/>
      Буйныя міжнародныя банкі і фонды больш не будуць дапамагаць Беларусі крэдытамі. Толькі за 2020 год насельніцтва нашай краіны вывела з банкаў больш за 2 млрд долараў.
      <br/>
      <br/>  
      Праблемы ўжо пачаліся: павялічыліся падаткі, узляцелі кошты на прадукты, камунальныя паслугі і лекі – і ўсё гэта працягне пагаршацца, каб дзяржава магла аплачваць працу сілавога апарату. 
      <br/>
      <br/>
      <b>Як эканамічны ціск аслабляе рэжым</b>. Улады пускаюць усе сродкі на тое, каб утрымацца, таму аслабіць іх можна праз матэрыяльны і фінансавы ціск. Эканамічны прэсінг можа выражацца, напрыклад, у байкатаванні тавараў і паслуг, у правядзенні забастовак на дзяржпрадпрыемствах, якія спансіруюць рэжым, ва ўздзеянні на кіраўнічыя структуры эканамічнымі санкцыямі.
    `,

    'poll.economy.questions.boycott_sponsors.text': 'Ці гатовыя вы самі байкатаваць кампаніі і паслугі ў Беларусі, якія спансіруюць рэжым? <a href="https://golos2020.org/results/listovka.jpg" target="_blank">Вось тут</a> ёсць поўная інструкцыя, як гэта рабіць',
    'poll.economy.questions.boycott_sponsors.answers.already_doing.text': 'Ужо раблю гэта',
    'poll.economy.questions.boycott_sponsors.answers.can_do_more.text': 'Раблю гэта часткова, але магу рабіць больш актыўна',
    'poll.economy.questions.boycott_sponsors.answers.can_not_do_more.text': 'Раблю гэта часткова, не буду рабіць больш актыўна',
    'poll.economy.questions.boycott_sponsors.answers.will_not.text': 'Не буду адмаўляцца ад звыклых тавараў і паслуг',

    'poll.economy.questions.withdraw_savings.text': 'Ці гатовыя вы кансалідавана, адначасова з іншымі беларусамі, забраць свае ашчаджанні з беларускіх банкаў?\n',
    'poll.economy.questions.withdraw_savings.answers.done.text': 'Так, ужо гэта зрабіў',
    'poll.economy.questions.withdraw_savings.answers.yes_if.text': 'Так, забяру, калі пераканаюся, што гэта дапаможа змене ўлады',
    'poll.economy.questions.withdraw_savings.answers.no.text': 'Не, не стану забіраць, хаця захоўваю грошы ў беларускіх банках',
    'poll.economy.questions.withdraw_savings.answers.no_savings.text': 'Не захоўваю грошы ў беларускіх банках',

    'poll.economy.questions.strike.text': 'Калі вы працуеце на дзяржаўным прадпрыемстве, абярыце найбольш актыўны варыянт дзеянняў, на які вы гатовыя:',
    'poll.economy.questions.strike.answers.yes.text': 'Адкрыта далучыцца да стачкі',
    'poll.economy.questions.strike.answers.express_opinion.text': 'Выражаць свае меркаванне, не далучаючыся да стачкі (напрыклад, адмаўляцца падпісваць лісты, ініцыяваныя дзейнымі ўладамі; выйсці з дзяржаўнага прафсаюза; наладжваць сувязі і самаарганізоўвацца з аднадумцамі на прадпрыемстве)',
    'poll.economy.questions.strike.answers.act_anonymously.text': 'Дзейнічаць на працоўным месцы ананімна (напрыклад, распавядаць пра парушэнне правоў працаўнікоў праз чат-боты, ананімна распаўсюджваць улёткі з інфармацыяй пра сітуацыю на прадпрыемстве)',
    'poll.economy.questions.strike.answers.no.text': 'Асуджаю сітуацыю ў краіне/на прадпрыемстве, але не буду рабіць ніякія дзеянні',
    'poll.economy.questions.strike.answers.support_government.text': 'Падтрымліваю кіраўніцтва краіны і прадпрыемства',
    'poll.economy.questions.strike.answers.dont_work_for_state.text': 'Не працую на дзяржаўным прадпрыемстве',

    'poll.economy.questions.change_unions.text': 'Ці гатовыя вы выйсці з дзяржаўных прафсаюзаў / дзяржаўных грамадскіх арганізацый і ўступіць у незалежныя прафсаюзы?',
    'poll.economy.questions.change_unions.answers.yes.text': 'Так',
    'poll.economy.questions.change_unions.answers.just_leave.text': 'Магу выйсці з дзяржаўных прафсаюзаў і грамадскіх арганізацый, але нікуды ўступаць не буду',
    'poll.economy.questions.change_unions.answers.state_unions.text': 'Я знаходжуся ў дзяржаўным прафсаюзе і не збіраюся выходзіць',
    'poll.economy.questions.change_unions.answers.no_unions.text': 'Я не знаходжуся ў прафсаюзах',

    'poll.economy.questions.boycott_oil.text': 'Ці гатовыя вы ў фармаце бяспечнай забастоўкі супраць нафтаперапрацоўчых кампаній не запраўляць машыну цягам месяца?',
    'poll.economy.questions.boycott_oil.answers.yes.text': 'Так',
    'poll.economy.questions.boycott_oil.answers.no_with_car.text': 'Не, не буду гэтага рабіць, хаця ў мяне ёсць машына',
    'poll.economy.questions.boycott_oil.answers.no_car.text': 'У мяне няма машыны',

    'poll.economy.questions.donate.text': 'Ці гатовыя вы падтрымліваць пацярпелых ад рэжыму фінансава праз ахвяраванні ў фонды і на платформах узаемадапамогі',
    'poll.economy.questions.donate.answers.yes.text': 'Так, буду падтрымліваць',
    'poll.economy.questions.donate.answers.already.text': 'Ужо падтрымліваю',
    'poll.economy.questions.donate.answers.no.text': 'Не, не буду падтрымліваць',

    'poll.economy.back.label': 'Вярнуцца назад',
    'poll.economy.next.label': 'Наступны блок',

    'poll.protests.header': 'ВУЛІЧНЫЯ ПРАТЭСТЫ',
    'poll.protests.text': `
      Мірныя пратэсты ў фармаце маршаў, дваравых актыўнасцей і ланцугоў салідарнасці паказалі рэжыму і ўсяму свету, што ў гэты раз беларусы не гатовыя мірыцца з фальсіфікацыямі, забойствамі і катаваннямі.
      <br/>
      <br/>
      <b>Як пратэставая актыўнасць аслабляе рэжым</b>. Падтрымка пратэставай актыўнасці важная, бо трымае ў напружанні сілавыя структуры і паказвае сусветнай супольнасці, што беларусы не здаюцца. Так узмацняецца ціск на ўлады ўнутры краіны і за яе межамі. У заціснутага рэжыму няма іншага выйсця, акрамя як абваліцца.
    `,

    'poll.protests.questions.protest_activities.text': 'Ці гатовыя вы працягваць вулічную/дваравую пратэставую актыўнасць?',
    'poll.protests.questions.protest_activities.answers.all.text': 'Буду пратэставаць у любым фармаце, у тым ліку праз маршы, дваравыя выхады, развешванне сцягоў і стужак і г.д.',
    'poll.protests.questions.protest_activities.answers.local_only.text': 'Буду пратэставаць толькі ў фармаце дваравых выхадаў',
    'poll.protests.questions.protest_activities.answers.symbols_only.text': 'Буду пратэставаць толькі ў фармаце развешвання сцягоў і стужак',
    'poll.protests.questions.protest_activities.answers.no.text': 'Не, не буду пратэставаць',

    'poll.protests.back.label': 'Вярнуцца назад',
    'poll.protests.next.label': 'Даслаць адказы',
    webpoll_results_helper_text: 'Калі вы яшчэ не галасавалі — перайдзіце ў бота і ўвядзіце каманду /анкета.',
    webpoll_results_title: 'Апытанне аб калектыўным удзеле ў пратэстнай дзейнасці',
    webpoll_blocks: 'Блок {{number}} з {{count}}',
    results_stations_error: 'Па гэтым участку няма дадзеных.',
  },
};
export default by;
