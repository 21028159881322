import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

const VoiceAccordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    marginBottom: theme.margin.bottom,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginBottom: theme.margin.bottom,
    },
  },
  expanded: {},
}))(MuiAccordion);

const VoiceAccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.padding.all,
  },
  content: {
    margin: 0,
    color: theme.palette.white,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  expandIcon: {
    color: 'white',
  },
}))(MuiAccordionSummary);

const VoiceAccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: theme.padding.all,
    paddingBottom: 0,
  },
}))(MuiAccordionDetails);

const Accordion = ({
  isExpanded,
  title,
  content,
  onChange,
  myRef,
}) => {
  const [expanded, setExpanded] = useState(isExpanded || false);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const handleChange = () => {
    if (onChange) {
      onChange();
    } else {
      setExpanded(!expanded);
    }
  };

  let attrs = {
    expanded,
    onChange: handleChange,
  };

  if (myRef) {
    attrs = {
      ...attrs,
      ref: myRef,
    };
  }

  return (
    <VoiceAccordion square {...attrs}>
      <VoiceAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          component="h4"
          variant="h4"
        >
          {title}
        </Typography>
      </VoiceAccordionSummary>
      <VoiceAccordionDetails>
        {content}
      </VoiceAccordionDetails>
    </VoiceAccordion>
  );
};

Accordion.defaultProps = {
  isExpanded: null,
  onChange: null,
  myRef: null,
};

Accordion.propTypes = {
  isExpanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  myRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default Accordion;
