import actionTypes from '../actionTypes';

const initialState = null;

export default function pollBuilderQuestions(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_POLL_BUILDER:
      return action.payload;
    default:
      return state;
  }
}
