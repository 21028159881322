import actionTypes from '../actionTypes';

const initialState = {
  gender: null,
  age_range: null,
  education: null,
  place_of_work: null,
  locality_type: null,
  marital_status: null,
  region: null,
};

export default function pollChoices(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_SOCIAL_DATA:
      return state;
    case actionTypes.CHANGE_SOCIAL_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
