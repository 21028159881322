const config = require('./config');

const environment = process.env.REACT_APP_ENVIRONMENT || config.ENVIRONMENT;
const gtagId = config.GTAG_ID;
const sentryUrl = config.SENTRY_URL;
const resultsUrl = process.env.RESULTS_URL || config.RESULTS_URL;
const mapApiKeys = process.env.MAP_API_KEYS || config.MAP_API_KEYS;
const apiBaseUrl = process.env.API_BASE_URL || config.API_BASE_URL;
const constitutionApiUrl = process.env.CONSTITUTION_API_URL || config.CONSTITUTION_API_URL;
const goPath = '/go';
const goEngPath = config.GO_ENG_PATH;
const constitutionPath = config.CONSTITUTION_PATH;
const thankyouPath = config.THANKYOU_PATH;
const pollsPath = config.POLLS_PATH;
const constitutionMailPath = config.CONSTITUTION_MAIL_PATH;
const cookieAbroadSupport = 'check_in';
// use REACT_APP_ prefix for variables needed in build
// const faqUrl = process.env.REACT_APP_FAQ_URL || config.FAQ_URL;
const faqUrl = '/home/faq';
// const landingUrl = process.env.REACT_APP_LANDING_URL || config.LANDING_URL;
const landingUrl = '/home';
const electionUrl = '/election';
const statsBucketUrl = process.env.REACT_APP_STATS_BUCKET_URL || config.STATS_BUCKET_URL;
const viberBotUrl = process.env.REACT_APP_VIBER_BOT_URL || config.VIBER_BOT_URL;
const telegramBotUrl = process.env.REACT_APP_TELEGRAM_BOT_URL || config.TELEGRAM_BOT_URL;
const walkingUrl = process.env.REACT_APP_WALKING_URL || config.WALKING_URL;
// const termsUrl = process.env.REACT_APP_TERMS_URL || config.TERMS_URL;
const termsUrl = '/home/terms';
const goEngUrl = process.env.REACT_APP_GO_ENG_URL || config.GO_ENG_URL;
const constitutionUrl = '/constitution';
const abroadCheckinApi = process.env.REACT_APP_ABROAD_CHECKIN_API || config.ABROAD_CHECKIN_API;
// eslint-disable-next-line max-len
const pollingStationsApi = process.env.REACT_APP_POLLING_STATIONS_API || config.POLLING_STARTIONS_API;
// eslint-disable-next-line max-len
const pollingStationsJson = process.env.REACT_APP_POLLING_STATIONS_JSON || config.POLLING_STATIONS_JSON;
const pollsUrl = process.env.REACT_APP_POLLS_URL || config.POLLS_URL;
const pollBuilderApi = process.env.REACT_APP_POLL_BUILDER_API || config.POLL_BUILDER_API;
const constitutionInfoPath = '/constitution/info';
const constitutionVotePath = '/constitution/vote';
const pollResultsJson = process.env.REACT_APP_POLL_RESULTS_JSON || config.POLL_RESULTS_JSON;
const postcardPath = `${process.env.REACT_APP_DREAMING_API_URL}/postcard?limit=10000` || config.POSTCARD;
const webpollApi = process.env.REACT_APP_WEBPOLL_API || config.WEBPOLL_API;
const webpollPath = process.env.REACT_APP_WEBPOLL_PATH || config.WEBPOLL_PATH;
const webpollResultsPath = process.env.REACT_APP_WEBPOLL_RESULTS_PATH
  || config.WEBPOLL_RESULTS_PATH;
const dialogPath = process.env.REACT_APP_DIALOG_PATH || config.DIALOG_PATH;

module.exports = {
  gtagId,
  sentryUrl,
  environment,
  landingUrl,
  resultsUrl,
  mapApiKeys,
  viberBotUrl,
  telegramBotUrl,
  walkingUrl,
  faqUrl,
  termsUrl,
  apiBaseUrl,
  statsBucketUrl,
  goPath,
  goEngPath,
  constitutionPath,
  thankyouPath,
  pollsPath,
  goEngUrl,
  constitutionUrl,
  abroadCheckinApi,
  cookieAbroadSupport,
  pollingStationsApi,
  pollingStationsJson,
  pollsUrl,
  pollBuilderApi,
  constitutionApiUrl,
  electionUrl,
  constitutionInfoPath,
  constitutionMailPath,
  pollResultsJson,
  constitutionVotePath,
  postcardPath,
  webpollApi,
  webpollPath,
  webpollResultsPath,
  dialogPath,
};
