import actionTypes from '../actionTypes';

const initialState = null;

export default function pollChoices(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_POLL_CHOICES:
      return action.payload;
    case actionTypes.CHANGE_POLL_CHOICES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
