import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Typography } from '@material-ui/core';
import theme from '../../theme/theme';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: theme.margin.bottom,
    color: (props) => props.color,
  },
  number: {
    marginBottom: theme.margin.bottom,
  },
  digit: {
    display: 'inline-block',
    padding: theme.spacing(1.5),
    color: (props) => props.color,
    '&:not(:first-child)': {
      borderLeft: '0px',
    },
    '&:nth-last-child(3n):not(:first-child)': {
      borderLeft: (props) => `4px solid ${props.color}`,
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.up('xs')]: {
      border: (props) => `2px solid ${props.color}`,
      '&:nth-last-child(3n):not(:first-child)': {
        borderLeft: (props) => `2px solid ${props.color}`,
        marginLeft: theme.spacing(1),
      },
    },
    [theme.breakpoints.up('sm')]: {
      border: (props) => `2px solid ${props.color}`,
      '&:nth-last-child(3n):not(:first-child)': {
        borderLeft: (props) => `2px solid ${props.color}`,
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
      padding: theme.spacing(1),
    },
  },
}));

const Counter = ({
  title,
  total,
  color,
}) => {
  const classes = useStyles({
    // eslint-disable-next-line no-nested-ternary
    color: color === 'blue' ? theme.palette.primary.main : (color === 'white' ? 'white' : theme.palette.text.primary),
  });

  return (
    <>
      <Typography
        component="h3"
        variant="h3"
        className={classes.title}
      >
        {title}
      </Typography>

      <Typography
        component="div"
        className={classes.number}
      >
        {total.toString().split('').map((num, i) => (
          <Typography
            component="div"
            variant="h1"
            className={classes.digit}
            // eslint-disable-next-line react/no-array-index-key
            key={`counter_${i}`}
          >
            {num}
          </Typography>
        ))}
      </Typography>
    </>
  );
};

Counter.defaultProps = {
  title: '',
  color: 'black',
};

Counter.propTypes = {
  title: PropTypes.string,
  color: PropTypes.oneOf(['white', 'black', 'blue']),
  total: PropTypes.number.isRequired,
};

export default Counter;
