import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import getData from '../data/getFaqData';
import Section from '../../../shared/components/Section';
import VoiceAccordion from '../../../shared/components/Accordion';

export default function FAQ() {
  const { t } = useTranslation();
  const [active, setActive] = useState(null);

  const faqEl = useRef(null);
  const faqElOpen = useRef(null);
  const targetKey = 'faq_group1_q6_title';
  const targetKeyOpen = qs.parse(window.location.search, { ignoreQueryPrefix: true }).open;

  const handleActiveFaq = (key) => setActive(key !== active ? key : null);

  const handleSmoothScroll = (key, ref) => {
    handleActiveFaq(key);

    if (!ref.current) return;

    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const smoothScrollToTargetKey = () => handleSmoothScroll(targetKey, faqEl);
  const smoothScrollToOpenKey = () => handleSmoothScroll(targetKeyOpen, faqElOpen);

  const handleSmoothScrollToOpen = () => {
    if (targetKeyOpen) {
      if (targetKey === targetKeyOpen) {
        smoothScrollToTargetKey();
      } else {
        smoothScrollToOpenKey();
      }

      window.history.replaceState(
        null, '', window.location.pathname + window.location.search.replace(/[?&]open=[^&]+/, '').replace(/^&/, '?'),
      );
    }
  };

  const faqData = getData(smoothScrollToTargetKey);

  useEffect(() => {
    handleSmoothScrollToOpen();
  }, []);

  return (
    <>
      <Section
        background="blue"
        title={t('faq_call_to_cation_title')}
      />
      {faqData.map(({ groupKey, items }) => (
        <Section
          key={groupKey}
          title={t(groupKey)}
          content={(
            <>
              {items.map(({ titleKey, content }) => {
                let ref = null;

                if (targetKeyOpen && targetKey !== targetKeyOpen && titleKey === targetKeyOpen) {
                  ref = faqElOpen;
                } else if (titleKey === targetKey) {
                  ref = faqEl;
                }

                return (
                  <VoiceAccordion
                    isExpanded={titleKey === active}
                    onChange={() => handleActiveFaq(titleKey)}
                    key={titleKey}
                    title={t(titleKey)}
                    content={content}
                    myRef={ref}
                  />
                );
              })}
            </>
          )}
        />
      ))}
    </>
  );
}
