const ru = {
  translations: {
    polling_stations: 'Избирательные участки',
    результаты: 'Предварительные результаты голосования',
    voice: 'голос',
    with_photo: 'с фото',
    according_to_CEC: 'по версии УИК',
    voters: 'избирателей',
    unique_confirmed: 'уникальных подтвержденных голосов на платформе "Голос"',
    'фото в системе': 'проверенных бюллетеней на платформе "Голос"',
    social_media_follow: 'Присоединяйтесь к нам в соцсетях и мессенджерах.',
    tell_friends: 'Приглашайте друзей и знакомыx!',
    пользовательское: 'Пользовательское соглашение',
    faq: 'Ответы на популярные вопросы',
    stations: 'участков',
    'официальная явка': 'явка избирателей согласно протоколу УИК',
    according_to_observers: 'по данным наблюдателей',
    honest_people_hashtag: '#мыЧестныеЛюди',
    we_have_voice_hashtag: '#уНасЕстьГолос',
    all: 'Все',
    dmitriyev: 'Дмитриев',
    kanopatskaja: 'Канопацкая',
    lukashenko: 'Лукашенко',
    tihanovkaja: 'Тихановская',
    cherechen: 'Черечень',
    against: 'Против всех',
    corrupted: 'Испортил(а) бюллетень',
    ignore: 'Не ходил(а) на выборы',
    dmitriyevAccusative: 'за Дмитриева',
    kanopatskajaAccusative: 'за Канопацкую',
    lukashenkoAccusative: 'за Лукашенко',
    tihanovkajaAccusative: 'за Тихановскую',
    cherechenAccusative: 'за Черечня',
    againstAccusative: 'против всех',
    corruptedAccusative: 'Испортил(а) бюллетень',
    ignoreAccusative: '',
    pollingStationDetails: 'Смотреть фото',
    pollingStationDownload: 'Cкачать листовку',
    voices_plural: 'голоса',
    voices_plural_stats: 'голосов',
    voted_for: 'Этот голос был отдан',
    back_side: 'Обратная сторона',
    honest_stations: 'Победа кандидата совпадает в протоколе комиссии и данных "Голоса"',
    dishonest_stations: 'Голосов за кандидата в "Голосе" больше, чем в официальном протоколе (фальсификация)',
    not_enough_data: 'На участках недостаточно данных для вывода о честности работы избирательной комиссии',
    total_num_of_voices: 'пользователей на платформе "Голос"',
    участок: 'Участок',
    all_stations: 'Все участки',
    область: 'область',
    title: 'Онлайн-платформа "Голос". В этот раз ваш голос решает всё!',
    processing_monitored: 'Данные от наблюдателей обрабатываются',
    processing_voters: 'Данные об избирателях обрабатываются',
    processing_official_numbers: 'Данные итогового протокола не были вывешены или сфотографированы',
    processing_official_numbers_table: 'Нет данных',
    protect_now: 'Защитите ваш голос сейчас!',
    more_ballots_than_official: '"Голосов" больше, чем голосов по версии УИК',
    more_photos_than_official: 'Фото бюллетеней больше, чем голосов по версии УИК',
    destroyed_ballot: 'Бюллетень испорчен',
    summary_results: 'Результаты голосования',
    detailed_report_button: 'Детальный отчёт',
    results_button: 'Протоколы УИК',
    summary_1: 'Выборы недействительны, поскольку фальсификации были обнаружены на каждом третьем избирательном участке.',
    summary_2: 'Итоговые данные, озвученные ЦИК, значительно расходятся с официальными данными из участковых протоколов.',
    summary_3: 'Александр Лукашенко не может считаться легитимным президентом Беларуси.',
    summary_4: 'Большое количество голосов, отданных за Светлану Тихановскую, украдены участковыми комиссиями.',
    chatbot_send_photo: 'Пришлите чат-боту платформы "Голос" фотографию вашего бюллетеня',
    detailed_report: '<bold>Подробный отчёт</bold>',
    detailed_report_from: 'от платформы "Голос" и инициативы "Честные Люди" ––',
    here: 'здесь',
    no_photos: 'Фото бюллетеней обрабатываются',
    no_photos_available: 'Фотографии за данного кандидата отсутствуют',
    sensitive_data: 'Фото скрыто, так как на нём может присутствовать паспорт избирателя или другие личные данные. Оригинал фото есть в распоряжении платформы "Голос". Всего на платформе 45,865 таких фотографий из {{photos}} (менее 10%)',
    photo_quarantined: 'Фото временно скрыто, так как проходит дополнительную обработку',
    report_personal_data: 'Мы скрыли личные данные (паспорт или лицо) с большей части фотографий, содержащих их. Если вы всё же заметили фото с личными данными или неверно распознанное, сообщите нам, нажав на кнопку "Скрыть фото" внизу.',
    PEC_results: 'Протокол',
    send_results_photo: 'Прислать фото протокола',
    station_code: 'Код участка',
    photos_disclaimer: 'Фотографии протоколов мы получили от независимых наблюдателей инициатив "Честные люди", "Зубр", а также из открытых источников. Мы оцениваем точность данных в 98%. Если вы обнаружите неточность или ошибку, сообщите нам об этом через',
    this_form: 'эту форму',
    site_title: 'Результаты платформы Голос: найди свой участок!',
    site_description: 'Поможем защитить ваш выбор и покажем результаты, подсчитанные не комиссиями, а избирателями. Заходите в Viber, Telegram, расскажите о своём выборе!',
    error_notify: 'Сообщить об ошибке',
    notify: 'Скрыть фото',
    notify_personal_data: 'Содержит личные данные',
    notify_incorrect_data: 'Неправильный кандидат',
    notify_other: 'Другая проблема',
    problem: 'Проблема',
    state_problem: 'Опишите проблему',
    report_manually: 'Если по какой-то причине форма выше не работает, отправьте ошибку вручную через',
    missing_photo1: 'Не можете найти свою фотографию? ',
    missing_photo2: 'На платформе "Голос" содержится ',
    missing_photo3: '{{registered}} голосов',
    missing_photo4: ', не привязанных к участку, из них {{photoVoices}} –– с фото.',
    walking_call_header: 'Гуляйте в хорошей компании',
    // walking_call_header: 'Проект #ягуляю приостановлен',
    walking_suspended: 'В связи с проведением опроса об изменениях Конституции  платформа "Голос" вынуждена приостановить проект <strong>#ягуляю</strong>.',
    walking_poll: 'Участвуйте в ',
    walking_link_to_poll: 'опросе о\u00A0Конституции.',
    button_support_belarus: 'ПОДДЕРЖАТЬ БЕЛОРУСОВ',
    how_does_it_work: 'Как это работает?',
    us_already: 'Зарегистрировано в\u00A0"Голосе"',
    walking_today: '{{Walk}} на улице в Беларуси {{Sunday}}',
    walking_home: '{{Walk}} дома в Беларуси {{Sunday}}',
    image_author: 'автор иллюстрации:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Шаг 1: Зарегистрируйтесь в\u00A0"Голосе"',
    walking_title_step_2: 'Шаг 2: Сообщите чат-боту, что вы гуляете',
    walking_title_step_3: 'Шаг 3: Поделитесь прогулкой с\u00A0друзьями',
    walking_activate_bot: 'Активируйте бота перед прогулкой',
    walking_activate_bot_desc_1: 'Перед выходом на прогулку активируйте чат-бота: отправьте ему команду <strong>/ягуляю</strong>. Так вы сообщите "Голосу", что в конкретный день присоединились к протесту. Хотите пойти гулять, но не можете — все равно отправляйте команду.',
    walking_activate_bot_desc_2: 'Участие в законных масштабных маршах, локальные митинги, цепочки солидарности, любые инициативы, да даже прогулка во дворе и разговор с соседями — форма протеста не имеет значения. Главное, что вы активны и наш чат-бот об этом знает. Платформе не нужны ваши персональные данные — мы хотим только посчитать участников протеста.',
    walking_activate_bot_desc_3: 'Устраиваем подсчёт каждое воскресенье и в специальные дни, о которых будем сообщать отдельно.',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Используйте хэштег #ягуляю',
    walking_use_hashtag_desc_1: 'Чем нас больше — тем громче наш голос. Властям будет сложнее и дальше делать вид, что нас не слышно. Фотографируйтесь во время своих прогулок, показывайте в соцсетях, ставьте хэштег <strong>#ягуляю</strong>.',
    walking_use_hashtag_desc_2: 'Расскажите о проекте всем знакомым. Наш счётчик — доказательство нашей правды и нашей победы.',
    walking_how_title: 'Как это работает',
    walking_how_desc_1: 'Новый спецпроект "Голоса" — <bold>#ягуляю</bold>. Теперь мы хотим посчитать белорусов, которые выходят на протесты, отстаивают свободу и новые честные выборы. Мы хотим показать всему миру реальную картину борьбы за справедливость.',
    walking_how_desc_2: '<bold>#ягуляю</bold> будет считать всех: кто законно гуляет в Минске и крупных городах, кто гуляет в отдалённых уголках Беларуси в поддержку протестующих, кто объективно не может гулять, но тоже против беззакония.',
    walking_how_desc_3: 'Это анонимно и безопасно. Присоединиться может каждый. Даже тот, кто хочет поддержать протест, но пока не решается делать это открыто.',
    walking_how_desc_4: 'В "Голосе" уже более <bold>1 300 000</bold> человек. И сейчас мы возобновляем регистрацию на платформе.',
    walking_how_desc_5: 'Поставили бота? Поделитесь нашей идеей с друзьями!',
    walking_how_desc_6: '<bold>Если вы уже зарегистрированы в чат-боте, переходите к следующему шагу.</bold>',
    walking_how_desc_7: '<bold>Если регистрируетесь впервые — нажимайте на кнопку любимого мессенджера и следуйте инструкциям в чат-боте. На первом шаге подтвердите отправку номера телефона (он будет надежно зашифрован).</bold>',
    no_messenger: 'Если у вас нет мессенджера',
    faq_answers: 'Ответы на часто задаваемые вопросы',
    go_hashtag: '#ягуляю',
    support_belarus_call_header: 'Поддержите белорусов, протестующих {{Sunday}}',
    support_belarus_background_image: 'Фото многотысячного протеста у Стеллы в Минске',
    support_belarus_counters_title_total: 'Всемирная поддержка Беларуси',
    support_belarus_counters_title_total_walking: 'Протесты в Беларуси {{Sunday}}',
    support_button_header: 'ПОДДЕРЖИТЕ БЕЛАРУСЬ ПРЯМО СЕЙЧАС',
    support_button_desc: 'Просто нажмите кнопку "ПОДДЕРЖАТЬ ПРОТЕСТУЮЩИХ" в\u00A0воскресенье, и мы покажем народу Беларуси, что они не одиноки и что весь мир поддерживает вышедших сегодня на улицы против режима и за демократию.',
    support_button: 'ПОДДЕРЖАТЬ ПРОТЕСТУЮЩИХ',
    support_button_temp: 'ВЕРНИТЕСЬ СЮДА В ВОСКРЕСЕНЬЕ, ЧТОБЫ ПОДДЕРЖАТЬ БЕЛАРУСЬ',
    support_header: 'Почему это важно?',
    support_desc_0: 'Каждое воскресенье тысячи беларусов выходят на улицы, мирно протестуя против официальных результатов президентских выборов, фальсифицированных "последним диктатором Европы" Александром Лукашенко.',
    support_desc_1: 'Мы создали уникальную платформу "Голос", чтобы подсчитать количество людей, выходящих на протесты. Сегодня мы расширяем платформу и даём возможность всему миру высказать свою поддержку беларусам в их желании жить в правовом демократическом государстве.',
    support_desc_2: 'Мы приглашаем вас показать свою солидарность с народом Беларуси, длительное время подвергающемуся угрозам и репрессиям за мнения, расходящиеся со взглядами авторитарного правительства.',
    support_icons_header: 'ПОЧЕМУ ЛЮДИ ВЫХОДЯТ НА ПРОТЕСТЫ?',
    support_icons_title_0: 'Беларусь находится под властью "последнего диктатора Европы" А. Лукашенко уже 26 лет.',
    support_icons_title_1: 'После 1994 года, ОБСЕ признало нелегитимными каждый референдум и каждые выборы в Беларуси.',
    support_icons_title_2: 'Во время предвыборной кампании и в дни после выборов более 7 000 человек были грубо и жестоко задержаны на мирных протестах против фальсификации выборов.',
    support_icons_title_3: 'Противозаконные массовые задержания, сфабрикованные дела о преступлениях, огромные непропорциональные штрафы, превышение законных полномочий силовыми структурами (похищения, вымогательства, угрозы и жестокость, доходящая до убийства) — это сегодняшняя реальность Беларуси.',
    support_icons_desc: 'Это в корне противоречит духу свободных, прозрачных и демократических выборов и не оставляет сомнения в их противозаконности и фальсификациях. Граждане Беларуси лишены основных прав человека: права на свободу слова, права голоса, права на свободу и личную неприкосновенность. В Беларуси не существует презумпции невиновности, как не существует и независимых честных судов. Более того, милиция может препятствовать праву граждан на своевременную медицинскую помощь.',
    support_flags: 'Изображение национального бело-красно-белого флага',
    support_share_desc: 'Поделитесь этим текстом, чтобы другие поддержали Беларусь',
    support_icon_0: 'Иконка — пистолет',
    support_icon_1: 'Иконка — перечеркнутый глаз',
    support_icon_2: 'Иконка — заключенный',
    support_icon_3: 'Иконка — весы',
    support_belarus: 'ПОДДЕРЖИТЕ БЕЛАРУСЬ',
    photo_by: 'Автор фотографии: ',
    go_eng_hashtag: '#supportbelarus',
    support_button_disabled: 'СПАСИБО ЗА ВАШУ ПОДДЕРЖКУ!',
    enable_geolocation: 'Пожалуйста, включите геолокацию в своем браузере или на своем устройстве, чтобы сообщить нам свой регион.',
    walking_info: 'В воскресенье, 13\u00A0сентября, в\u00A0Беларуси долго отсутствовал интернет, что помешало многим нашим пользователям отправить команду чат-боту. Мы продлеваем подсчёт людей, участвовавших в марше, до 12.00 понедельника\u00A0— успейте прислать /ягуляю чат-боту "Голоса"!',
    walking_result_title: 'Мы гуляем',
    walking_result_supporters_title: 'Наша поддержка',
    walking_result_p1: 'Сейчас белорусы маршируют каждый день в разное время по всей стране: чтобы по-прежнему выражать свою позицию, но избегать масштабных задержаний. Уже несколько недель используется формат локальных маршей — дворовых и районных.',
    walking_result_p2: 'И узнать, сколько человек выражает несогласие с режимом через уличные демонстрации, — при таком децентрализованном формате — особенно актуально.',
    walking_result_p3: 'Если вы участвуете в уличных протестах в ближайшие выходные — обязательно расскажите нам. Формой протеста может быть дворовое шествие, участие в районном марше или выход в большой колонне.',
    walking_result_p4: 'Чтобы сообщить о своей прогулке, необходимо прислать чат-боту Голоса команду <strong>/ягуляю</strong>.',
    walking_result_p5: 'Распространите информацию среди знакомых, которые тоже протестуют!',
    walking_by: 'в Беларуси',
    walking_eu: 'в Европе',
    walking_world: 'в мире',
    support_coordination_council: 'Поддержите Координационный Совет',
    coordination_council: 'Координационный Совет',
    voted: 'Проголосовало',
    voted_poll: 'Прошло опрос',
    poll: 'poll',
    polls_explain_1: '"Голос" запускает опрос в поддержку Координационного Совета –– как уполномоченного представителя белорусского народа.',
    polls_explain_2: 'Координационный Совет создан по инициативе Светланы Тихановской, чтобы вести диалог с властями от имени гражданского общества и добиваться выполнения требований:',
    polls_explain_3: 'освободить политзаключенных и прекратить репрессии,',
    polls_explain_4: 'привлечь к ответственности виновных в убийствах и насилии,',
    polls_explain_5: 'провести новые честные выборы.',
    polls_explain_6: 'Вместе мы выйдем из политического кризиса и добьемся согласия в обществе. Защитим суверенитет и независимость нашей страны.',
    polls_take_part: 'Принять участие в опросе',
    polls_take_part_p1: 'Опрос проводится через один из мессенджеров –– Viber или Telegram. При регистрации на платформе "Голос" чат-бот запросит ваш номер телефона. Это важно для определения вашей уникальности и исключения накруток. Номер хранится в зашифрованном виде, это безопасно. Участие в опросе анонимное.',
    polls_take_part_p2: 'После регистрации мы сможем уведомлять вас о новых опросах и их результатах.',
    polls_take_part_p3_1: 'Пройдите опрос о признании ',
    polls_take_part_p3_2: ' представителем белорусского общества, уполномоченного выражать позицию, вести переговоры с государством и международными структурами, добиваться выполнения требований белорусского народа.',
    polls_take_part_p4: 'Перейдите в чат с ботом, нажав на кнопку Telegram или Viber. Отправьте слово <bold>/опрос</bold>, чтобы пройти опрос.',
    polls_about_voice: 'О платформе "Голос"',
    polls_about_p1: 'На платформе "Голос" зарегистрировано <bold>более 1,290,000</bold> белорусов.',
    polls_about_p2_1: 'Платформа уже доказала свою надёжность и достоверность. Мы провели альтернативный подсчет голосов на президентских выборах и выявили многочисленные фальсификации. Мы опубликовали ',
    polls_about_p2_2: 'интерактивную карту избирательных участков',
    polls_about_p2_3: ' с нарушениями и ',
    polls_about_p2_4: 'финальный отчёт,',
    polls_about_p2_5: ' который опроверг официальные результаты ЦИК.',
    polls_about_p3: 'Данные пользователей остаются в безопасности, никто из них не пострадал за свой голос и волеизъявление.',
    polls_about_p4: 'Имея доверие жителей Беларуси и опыт в создании надежных цифровых решений, мы продолжаем работу в интересах гражданского общества.',
    polls_icon_pair: 'Иконка - люди, держащиеся за руки',
    registered_with_us: 'Зарегистрировано в "Голосе"',
    landing_call_to_action_title: 'Платформа, чтобы услышать каждого белоруса',
    landing_counters_p_1: 'Платформа "Голос" была создана белорусскими программистами для контроля за честностью выборов и альтернативного подсчета голосов.',
    landing_counters_p_2: 'После доказательства фальсификаций на выборах, мы расширили функции платформы, чтобы она позволяла подсчитывать количество людей, выходящих на мирный протест.',
    landing_counters_p_3: 'В дополнение к этому платформа взаимодействует с Координационным Советом для получения мнения белорусов о принимаемых советом решениях.',
    landing_counters_p_4: 'Ниже можно ознакомиться со всеми проектами платформы и присоединиться к нам.',
    landing_counters_p_5: 'Регистрируйтесь на платформе в одном из мессенджеров ниже:',
    landing_counters_title: 'Зарегистрировано в\u00A0"Голосе"',
    landing_results_title: 'Доказательства фальсификации выборов',
    landing_results_button: 'Результаты выборов',
    landing_results_p_1: 'Платформа "Голос" уже собрала более полумиллиона бюллетеней, доказывающих, что выборы были сфальсифицированы, и создала интерактивный отчёт, где каждый может увидеть результат нашей работы.',
    landing_results_image_alt: 'Карта результатов',
    landing_projects_p_1: 'Голосование против поправок в Конституцию и сбор предложений к изменениям в Конституцию Беларуси.',
    landing_projects_button_1: 'Результаты опроса',
    landing_projects_p_2: 'Проект <bold>#ягуляю</bold> создан, чтобы посчитать и поддержать людей, которые протестуют против фальсифицированных выборов через мирные протесты.',
    landing_projects_button_2: 'Проект #ягуляю',
    landing_projects_image_alt: 'Проект опросов Координационного Совета',
    landing_command_title: 'Наша команда',
    landing_icon_alt: 'Иконка –– группа воодушевлённых людей',
    landing_command_p: 'Мы\u00A0–– белорусы. Независимые разработчики и неравнодушные люди. Наша команда\u00A0–– это и каждый из вас, гражданин Беларуси, который хочет участвовать в создании процветающей и успешной страны. Где права и свободы граждан уважают, а власть работает на улучшение жизни людей.',
    coordination_council_1: 'Координационный',
    coordination_council_2: 'Совет',
    coordination_council_logo_alt: 'Логотип Координационного Совета',
    landing_card_icon_alt_1: 'Иконка — весы',
    landing_card_title_1: 'Это законно\u00A0и анонимно',
    landing_card_content_1: 'Информация пользователей зашифрована и надёжно защищена. Никто не\u00A0узнает о\u00A0вашем участии и выборе\u00A0–– даже разработчики. "Голос" действует в\u00A0правовом поле и не\u00A0нарушает закон.',
    landing_card_icon_alt_2: 'Иконка — галочка',
    landing_card_title_2: 'Почему этой системе можно доверять?',
    landing_card_content_2: 'Мы доказали нашу прозрачность и открытость во\u00A0время президентских выборов. Выполнили обещания, обнаружили фальсификации, защитили данные пользователей. Нам доверяет более 1\u00A0миллиона человек.',
    landing_card_icon_alt_3: 'Иконка — флаг',
    landing_card_title_3: 'На что это влияет?',
    landing_card_content_3: 'Опросы через систему "Голос" могут влиять на\u00A0решения, принимаемые Координационным Советом для\u00A0развития нашей страны и вывода её из глубокого политического кризиса.',
    landing_dreams_title: 'Беларусь мечтает',
    landing_dreams_subtitle: 'Сбор идей для новой Беларуси',
    landing_dreams_send: 'Собранные мечты',
    faq_call_to_cation_title: 'Ответы на популярные вопросы',
    faq_group1_title: 'Работа спецпроекта "Голоса" #ягуляю',
    faq_group1_q1_title: 'Как мне поучаствовать в проекте?',
    faq_group1_q1_part1: 'ЗАРЕГИСТРИРУЙТЕСЬ В "ГОЛОСЕ".',
    faq_group1_q1_part2: 'Если вы уже зарегистрированы в чат-боте, переходите к следующему шагу.',
    faq_group1_q1_part3: 'Если регистрируетесь впервые\u00A0— нажимайте на кнопку любимого мессенджера и следуйте инструкциям в чат-боте. На первом шаге подтвердите отправку номера телефона (он будет надежно зашифрован).',
    faq_group1_q1_part4: 'СООБЩИТЕ ЧАТ-БОТУ, ЧТО ВЫ ГУЛЯЕТЕ.',
    faq_group1_q1_part5: 'Активируйте бота перед прогулкой. В день акции протеста присылайте в бот команду <strong>/ягуляю</strong>. Выберите место прогулки: "дома", "на улице" или "за границей". Поделитесь своим местоположением, чтобы мы могли просчитать сколько городов гуляет.',
    faq_group1_q1_part6: 'ПОДЕЛИТЕСЬ ПРОГУЛКОЙ С ДРУЗЬЯМИ.',
    faq_group1_q1_part7: 'Используйте хэштег <strong>#ягуляю</strong>.',
    faq_group1_q2_title: 'Когда отправлять данные о своей прогулке?',
    faq_group1_q2_part1: 'Устраиваем подсчёт каждое воскресенье и в специальные дни, о которых будем сообщать отдельно.',
    faq_group1_q2_part2: 'Так как Интернет во время прогулки может отсутствовать, лучше всего всего отправить сообщение о прогулке перед выходом из дома.',
    faq_group1_q3_title: 'Зачем боту нужна моя гелокация?',
    faq_group1_q3_part1: 'Мы хотим подсчитать в каких городах мы будем гулять. Чем больше городов гуляет, тем лучше!',
    faq_group1_q4_title: 'Не могу поделиться своим местоположением, выдается ошибка',
    faq_group1_q4_part1: 'Если после нажатия "Отправить местоположение" ничего не происходит или выдается ошибка, необходимо проверить настройки геолокации.',
    faq_group1_q4_part2: 'Для этого в общих настройках телефона необходимо выбрать необходимый мессенджер и разрешить для него использование геолокации.',
    faq_group1_q4_part3: 'В случае использования десктопной версии\u00A0– разрешить использование геолокации в настройках браузера.',
    faq_group1_q5_title: 'Если зарегистрировался и в Viber, и в Telegram дубликата не будет?',
    faq_group1_q5_part1: 'В качестве идентификатора избирателя платформа использует номер телефона. Только последний выбор сохраняется в качестве актуального. Дубликата не будет.',
    faq_group1_q6_title: 'Как зарегистрировать в системе родственника, у которого кнопочный телефон?',
    faq_group1_q6_part1: 'Вам нужно зарегистрировать аккаунт в Telegram на номер вашего родственника. Если у вас нет Telegram\u00A0— вот инструкция по установке на ',
    faq_group1_q6_part2: ' и на ',
    faq_group1_q6_part3: 'Если у вас уже есть Telegram, вам нужно добавить ещё один аккаунт в приложении. Шаги следующие: ',
    faq_group1_q6_part4: 'В Telegram на смартфоне в боковом меню (на Android) или в настройках профиля (iOS) выберите "Добавить аккаунт".',
    faq_group1_q6_part5: 'Зарегистрируйте новый аккаунт на номер вашего родственника.',
    faq_group1_q6_part6: 'На номер вашего родственника придёт код в sms. Заранее предупредите его действовать быстро\u00A0- мессенджер дает две минуты на подтверждение регистрации через проверочный код.',
    faq_group1_q6_part7: 'Введите код из пункта выше в аккаунте в приложении.',
    faq_group1_q6_part8: 'Далее следуйте стандартному процессу регистрации своего выбора в платформе "Голос", как вы делали это для себя.',
    faq_group2_title: 'Безопасность',
    faq_group2_q1_title: 'Кто разрабатывает платформу?',
    faq_group2_q1_part1: 'Команда белорусских разработчиков. Опытные инженеры, работающие в компаниях с повышенными требованиями к безопасности, в качестве волонтеров создали и развивают "Голос".',
    faq_group2_q2_title: 'Кто имеет доступ к личным данным пользователей?',
    faq_group2_q2_part1: 'К личным данным пользователя, то есть к номеру телефона, не имеет доступа никто, так как он не хранится в\u00A0открытом виде. Мы не запрашиваем ваши личные данные, кроме телефона.',
    faq_group2_q2_part2: '<strong>Внимание!</strong> Если у вас иностранный номер телефона, бот может попросить вас заполнить форму, указать фамилию, имя, дату рождения и прикрепить фото фрагмента паспорта, подтверждающее эти данные. Предоставить эти данные безопасно. Они будут удалены после проверки.',
    faq_group2_q3_title: 'Мой номер телефона в безопасности?',
    faq_group2_q3_part1: 'Да. Платформа разработана так, чтобы все данные избирателя оставались в полной безопасности. Даже если база данных попадет в чужие руки, из нее невозможно будет извлечь личные данные пользователей.',
    faq_group2_q3_part2: 'Технические подробности: По коду страны платформа проверяет, что номер из Беларуси, и хэширует его (',
    faq_group2_q3_part3: 'алгоритм SHA-3 с солью',
    faq_group2_q3_part4: '). В результате номер телефона расшифровать нельзя.',
    faq_group2_q3_part5: 'Кроме того, мы используем строгие политики безопасности, доступ к базе имеют только несколько людей, но даже они не могут увидеть номер телефона.',
    faq_group3_title: 'Прочие вопросы',
    faq_group3_q1_title: 'Чем я могу помочь платформе?',
    faq_group3_q1_part1: 'Расскажите о платформе "Голос" своим знакомым, друзьям и близким, объясните им, как важно защитить их голос, поделитесь ссылкой на ',
    faq_group3_q1_part2: 'наш сайт',
    faq_group3_q1_part3: ' в соцсетях.',
    faq_group3_q1_part4: 'Помогите родным и близким, у которых нет смартфонов',
    faq_group3_q1_part5: ', учесть их выбор в платформе "Голос".',
    faq_group3_q2_title: 'У меня есть вопрос, как с вами связаться?',
    faq_group3_q2_part1: 'Так как интерес к платформе очень большой, и вопросов к нам поступает очень много, будем признательны, если вы изучите данный документ очень внимательно\u00A0– ответы на многие вопросы мы уже составили. Если у вас есть иные вопросы по работе нашей платформы или предложения, расскажите, пожалуйста, поподробнее ',
    faq_group3_q2_part2: 'вот тут!',
    polls_results_title: 'Результаты опроса',
    polls_image_alt: 'Изображение карты с результатами опроса в поддержку Координационного Совета',
    polls_results_p1: '"Голос" подвел итоги опроса в поддержку Координационного Совета.',
    polls_results_p2: 'Доверие белорусов было очевидно с начала опроса, но особенно важно озвучить результаты: более <strong>522,000</strong>\u00A0пользователей оставили свои голоса.',
    polls_results_p3: '<strong>96.75%</strong> из них признали Координационный Совет полномочным представителем своих интересов в диалоге с государством. <strong>1.25%</strong>\u00A0высказались против, <strong>2%</strong> затруднились ответить.',
    polls_results_p4: 'Так распределилсь голоса <strong>504,289</strong>\u00A0белорусов, которые, по состоянию на\u00A025\u00A0сентября, поддержали Координационный Совет:',
    polls_results_line_1: 'Бресткая обл.',
    polls_results_line_2: 'Витебская обл.',
    polls_results_line_3: 'Гомельская обл.',
    polls_results_line_4: 'Гродненская обл.',
    polls_results_line_5: 'Минская обл.',
    polls_results_line_6: 'Могилевская обл.',
    polls_results_line_7: 'Минск',
    image_nina_alt: 'Я гуляю - Нина Багинская, рисунок',
    // constitution translations
    const_main_title: 'Каждый гражданин вправе влиять на будущее Беларуси',
    const_main_p_1: '"Голос" выступает против инициативы государства менять Конституцию сейчас.',
    const_main_p_2: 'Сначала должны быть выполнены требования белорусского гражданского общества:',
    const_main_list_1: 'провести новые честные выборы;',
    const_main_list_2: 'освободить политзаключенных;',
    const_main_list_3: 'привлечь к ответственности виновных.',
    const_main_p_3: 'И только потом — новая Конституция.',
    const_main_p_4: 'Нынешняя власть нелегитимна: выборы сфальсифицированы, и "Голос" это доказал. Внимание от недействительных выборов пытаются отвлечь скоропалительной конституционной реформой. Государство создает иллюзию демократии, чтобы навязать белорусам свою версию главного закона страны.',
    const_main_p_5: 'Опрос состоит из двух блоков:',
    const_main_list_2_1: 'Можно ли проводить реформу до новых выборов?',
    const_main_list_2_2: 'Как белорусы видят основные принципы Конституции?',
    const_main_p_6: 'Ваш голос важен — выберите ',
    const_main_p_6_1: ' или ',
    const_main_p_6_2: ' и пройдите опрос.',
    const_us_already: 'Зарегистрировано в\u00A0"Голосе"',
    const_why_trust: 'Почему нам доверяют?',
    honest_people_logo_alt: 'Логотип инициативы Честные Люди',
    voice_logo_alt: 'Логотип онлайн-платформы Голос',
    const_howitworks_p_1: 'Нажмите на кнопку удобного вам мессенджера, чтобы зарегистрироваться в чат-боте "Голоса" в Viber или Telegram.',
    const_howitworks_p_2: 'При регистрации чат-бот спросит номер вашего телефона: это нужно, чтобы подтвердить уникальность пользователя. Как только вы введете номер в систему, он зашифруется: его не сможет увидеть никто, включая разработчиков.',
    const_howitworks_p_3: 'Регистрация на платформе "Голос" анонимна и безопасна.',
    const_whychange_title: 'Зачем участвовать в опросе о Конституции',
    const_whychange_p_1: 'Чтобы громко и хором заявить: белорусы против поспешной реформы, белорусы требуют новых честных выборов.',
    const_whychange_p_2: 'Чтобы наглядно показать, что несогласных с захватом власти, насилием и репрессиями — большинство.',
    const_whychange_p_3: 'Чтобы не позволить властям снова переписать Конституцию под себя и закрепить в ней свой произвол.',
    const_whychange_p_4: 'Чтобы дать понять, какая Конституция на самом деле нужна белорусскому обществу.',
    const_whyVoice_trust: 'Почему "Голосу" доверяют?',
    const_whyVoice_p_1: 'Платформа "Голос" создана командой независимых разработчиков для того, чтобы у каждого гражданина нашей страны был простой, прозрачный и надежный инструмент для участия в развитии Беларуси.',
    const_whyVoice_p_2: '"Голос" работает, чтобы ваше мнение услышали.',
    const_whyVoice_p_3: 'Платформа заслужила доверие белорусских избирателей, когда обстоятельно, подробно и неопровержимо доказала фальсификации на президентских выборах. В подтверждение — ',
    const_interactive_map: 'интерактивная карта',
    and: ' и ',
    const_final_report: 'итоговый отчёт.',
    const_whyVoice_p_4: '"Голос" — яркое проявление прямой демократии, когда гражданское общество само решает, в какой стране и по каким законам ему жить. Присоединяйтесь!',
    voted_for_constitution: 'Голосов в\u00A0опросе о\u00A0Конституции',
    voted_for_CC: 'Голосов в опросе о Координационном Совете',
    we: 'Мы',
    selected: 'Выбрано',
    pollbuilder_call_header: 'Сбор предложений к изменениям в\u00A0конституцию Беларуси',
    pollbuilder_disclaimer: '* По умолчанию везде выделены первые варианты ответа. Это сделано для того, чтобы все люди, независимо от уровня технической грамотности, понимали, как выбрать вариант. Чтобы изменить выбор, достаточно нажать на другую карточку.',
    pollbuilder_title_1: 'Действия до изменения конституции',
    pollbuilder_title_2: 'Предлагаемые изменения в\u00A0конституцию',
    accordion_info_show_title: 'Узнать детали',
    accordion_info_hide_title: 'Скрыть детали',
    pollbuilder_title_3: 'Ваши предложения (не\u00A0обязательно)',
    pollbuilder_desc_3: 'Возможно есть что-то очень важное, что мы забыли –– оставьте, пожалуйста, свой комментарий, и мы обязательно передадим его специалистам по конституционному праву в нашей команде.',
    pollbuilder_title_4: 'Расскажите немного о себе (не\u00A0обязательно)',
    pollbuilder_desc_4: 'Этот шаг не обязателен, но позволит нам лучше понимать нашу аудиторию.',
    pollbuilder_select_sex_title: 'Пол',
    pollbuilder_select_sex_1: 'Женский',
    pollbuilder_select_sex_2: 'Мужской',
    pollbuilder_select_age_title: 'Возраст',
    pollbuilder_select_age_0: 'До 18',
    pollbuilder_select_age_1: '18-24',
    pollbuilder_select_age_2: '25-34',
    pollbuilder_select_age_3: '35-44',
    pollbuilder_select_age_4: '45-54',
    pollbuilder_select_age_5: '55-64',
    pollbuilder_select_age_6: '65-74',
    pollbuilder_select_age_7: '75 и старше',
    pollbuilder_select_education_title: 'Образование',
    pollbuilder_select_education_1: 'Неполное или общее среднее (школа)',
    pollbuilder_select_education_2: 'Профессионально-техническое или среднее специальное (ПТУ, колледж, техникум)',
    pollbuilder_select_education_3: 'Незаконченное высшее (более 3 курсов ВУЗа)',
    pollbuilder_select_education_4: 'Высшее',
    pollbuilder_select_education_5: 'Научная степень',
    pollbuilder_select_work_title: 'Где вы работаете?',
    pollbuilder_select_work_1: 'Работаю на государственном предприятии, в государственной организации',
    pollbuilder_select_work_2: 'Работаю на частном предприятии, в компании, организации',
    pollbuilder_select_work_3: 'Имею свой бизнес',
    pollbuilder_select_work_4: 'Работаю в некоммерческой (общественной) организации',
    pollbuilder_select_work_5: 'ИП, самозанятый(ая), фрилансер',
    pollbuilder_select_work_6: 'Учусь в среднем специальном или высшем учебном заведении',
    pollbuilder_select_work_7: 'Не работаю, веду домашнее хозяйство или нахожусь в декретном отпуске',
    pollbuilder_select_work_8: 'На пенсии по возрасту или инвалидности',
    pollbuilder_select_work_9: 'Временно не работаю, ищу работу',
    pollbuilder_select_location_title: 'Где Вы в настоящее время проживаете?',
    pollbuilder_select_location_1: 'Минск',
    pollbuilder_select_location_2: 'Областной центр',
    pollbuilder_select_location_3: 'Крупный город с населением более 100 тыс. чел.',
    pollbuilder_select_location_4: 'Средний город (30-100 тыс. чел.)',
    pollbuilder_select_location_5: 'Малый город (до 30 тыс. чел)',
    pollbuilder_select_location_6: 'Иной населенный пункт (село, городской поселок, агрогородок, поселок, деревня)',
    pollbuilder_select_location_7: 'Не хочу отвечать',
    pollbuilder_select_marital_title: 'Ваше семейное положение',
    pollbuilder_select_marital_1: 'Холост(а) / Разведен(а) / Вдовец(вдова)',
    pollbuilder_select_marital_2: 'В браке / В отношениях',
    pollbuilder_submit_desc: 'Вы можете проголосовать только один раз и не сможете исправить свой ответ, поэтому принимайте решение взвешенно, ознакомившись с материалами выше. Мы постарались максимально подробно описать все преимущества и недостатки всех вариантов.',
    pollbuilder_submit: 'Проголосовать',
    pollbuilder_submit_wait: 'Отправляем...',
    pollbuilder_select_region_title: 'В какой области Вы проживаете?',
    pollbuilder_select_region_1: 'Брестская область',
    pollbuilder_select_region_2: 'Витебская область',
    pollbuilder_select_region_3: 'Гомельская область',
    pollbuilder_select_region_4: 'Гродненская область',
    pollbuilder_select_region_5: 'Могилёвская область',
    pollbuilder_select_region_6: 'Минская область',
    pollbuilder_select_region_7: 'Минск',
    pollbuilder_select_region_8: 'Не в Беларуси',
    pollbuilder_select_do_not_answer: 'Другое / не хочу отвечать',
    // thankyou translations
    thx_heading: 'СБОР ПРЕДЛОЖЕНИЙ К ИЗМЕНЕНИЯМ В КОНСТИТУЦИЮ БЕЛАРУСИ',
    thx_thankyou: 'СПАСИБО ЗА ВАШ ГОЛОС!',
    voted_thankyou: 'ВЫ УЖЕ ГОЛОСОВАЛИ!',
    thx_p_1: 'Мы соберем мнение белорусов о поправках в Конституцию. И <strong>в конце недели</strong> пришлем вам инструкцию, как всем вместе отправить письма чиновникам через нашу платформу.',
    thx_p_2: 'Наша общая цель: сначала новые выборы – потом Конституция.',
    thx_socials_p: 'Подписывайтесь на наши каналы, чтобы первыми узнавать все новости "Голоса"',
    thx_sharing_p: 'Рассказывайте близким и друзьям про нас',
    alert_reload: 'Что-то пошло не так. <strong>Попробуйте перезагрузить страницу!</strong>',
    // info translations
    info_heading: '"Голос" запускает честное голосование о новой Конституции Беларуси',
    info_sub_heading: 'Платформа "Голос" разработала инструмент, который позволит белорусскому гражданскому обществу выразить свое отношение к конституционной реформе, инициированной властями.',
    info_p_1: 'Ранее "Голос" обстоятельно и неопровержимо доказал, что президентские выборы в Республике Беларусь сфальсифицированы. Теперь власть спешит перевести фокус с недействительных выборов на псевдодемократическую конституционную реформу и торопит присылать предложения до 25 октября.',
    info_p_2: 'Однако у нелегитимной власти нет и не может быть законных оснований проводить реформы и менять Конституцию. Зато они есть у гражданского общества Беларуси.',
    info_h_1: 'Зачем нужен проект голосования о конституционной реформе',
    info_h1_p_1: 'Новый проект "Голоса" создан, чтобы обеспечить неукоснительное следование принципам демократии и защиту прав белорусского народа даже в условиях политического хаоса.',
    info_h1_p_2: 'Этот инструмент нужен, чтобы высветить первоочередные требования общества, четко сформулировать реальные предложения граждан для новой Конституции РБ и собрать их на единой прозрачной платформе.',
    info_h1_p_3: '<i>"В действиях властей нет прозрачности. Существуют серьезные риски, что переписанная Конституция нанесет вред белорусскому народу и поставит под угрозу суверенитет страны. Власть сейчас готова на всё, чтобы удержаться. Чтобы ей этого не удалось и реальные голоса людей были услышаны сквозь все помехи, мы выпустили новый проект"</i>,\u00A0— разработчики платформы "Голос".',
    info_h_2: 'Что собой представляет новый проект "Голоса"',
    info_h2_link: 'Проект "Голоса", посвященный конституционной реформе',
    info_h2_p_1: ', — место, где каждый гражданин Беларуси может высказать свое мнение о настоящем и будущем страны.',
    info_h2_p_2: 'Проект состоит из двух блоков:',
    info_h2_list_item_1: '<strong>Голосование против поспешного внесения поправок в Конституцию.</strong> Сначала новые выборы — потом новая Конституция. Реформа нужна, но только при новой власти и с учетом требований гражданского общества.',
    info_h2_list_item_2: '<strong>Опрос об основных принципах народной Конституции Беларуси.</strong> Возможность открыто поделиться своими взглядами на разделение властей, ограничение президентских сроков, порядок проведения референдумов и другие важные вопросы развития страны.',
    info_h2_p_3: 'Голосование против поправок помешает властям провести скоропалительную конституционную реформу и выдать ее за волю народа. А опрос о главных принципах новой Конституции покажет, какой белорусы хотят видеть свою страну на самом деле.',
    info_h_3: 'Как это работает и какие сроки у каждого этапа',
    info_h3_p_1: 'Конституционная кампания "Голоса" пройдет в два этапа: сначала голосование, потом общереспубликанская рассылка писем депутатам.',
    info_h3_p_2_start: '<strong>До 18 октября любой белорусский гражданин может выразить свое отношение к инициативе властей через тайное анонимное голосование на сайте </strong>',
    info_h3_p_2_link: 'golos2020.org/constitution',
    info_h3_p_2_end: '. Из ответов на вопросы о важнейших принципах государственного устройства Республики Беларусь сложится народная версия будущей Конституции. Когда власть сменится, а законы снова станут действовать, она будет готова к доработке и принятию.',
    info_h3_p_3_start: '<strong>После 18 октября начнется второй этап конституционного проекта «Голоса» — масштабная рассылка писем депутатам.</strong>  «Голос» призывает всех белорусов написать чиновникам о своем несогласии с реформой, привести доказательства сфальсифицированных выборов с выдержками из ',
    info_h3_p_3_link: 'итогового отчета',
    info_h3_p_3_end: ' и твердо потребовать: сначала новые выборы — потом новая Конституция.',
    info_h3_p_4: 'Для удобства письмо депутату можно будет сгенерировать на платформе "Голос": образец будет готов к распечатке, останется только внести свои данные, сфотографировать его и отправить в «Голос» через чат-бота, чтобы письмо точно не потерялось. Потом его нужно отослать почтой на адрес депутатской приемной.',
    info_h_4: 'Почему важно участвовать',
    info_h4_p_1: 'Чем больше голосов против и чем они громче, тем сложнее властям отрицать, замалчивать и искажать настоящие требования гражданского общества Беларуси.',
    info_h4_p_2: '<strong>Голосование докажет, что большинство белорусов против поспешной реформы.</strong> Они требуют новых честных выборов, освобождения политзаключенных и наказания виновных в репрессиях и насилии.',
    info_h4_p_3: '<strong>Опрос покажет, какой граждане Беларуси на самом деле видят новую Конституцию.</strong> Созданный народом прообраз ляжет в основу главного закона страны после честных выборов.',
    info_h4_p_4: '<strong>Письма депутатам дадут физические доказательства</strong> тому, насколько воля народа расходится с произволом властей.',
    info_h4_p_5: 'Чиновники пытаются совершить очередное преступление против Беларуси, но участие каждого гражданина страны в новом проекте "Голоса" расстроит их планы и поможет сохранить независимость от нелегитимной власти. Это и есть прямая демократия: гражданское общество само решает, в какой стране и по каким законам ему жить.',
    info_h_5: 'Как "Голос" защищает голоса белорусов',
    info_h5_p_1: 'Регистрация в "Голосе" анонимна и безопасна. Номера телефонов всех пользователей зашифрованы и не видны даже разработчикам платформы.',
    info_h5_p_2: 'Перед публикацией писем депутатам в открытом доступе персональные данные автоматически скрываются.',
    info_h5_p_3: 'В результате кампании платформа "Голос" соберет следующую информацию:',
    info_h5_list_item_1: 'общее количество проголосовавших;',
    info_h5_list_item_2: 'доля тех, кто против проведения реформы при нелегитимной власти;',
    info_h5_list_item_3: 'реальные предложения белорусов для новой Конституции;',
    info_h5_list_item_4: 'количество письменных требований, отправленных депутатам.',
    info_h5_p_4: 'Огромный массив голосов и писем, где четко зафиксировано, каким белорусы видят основной закон своей страны, не оставит властям пространства для маневра.',
    info_h_6: 'Где посмотреть результаты',
    info_h6_p_1: 'Результаты голосования о новой Конституции и данные о количестве отправленных депутатам писем доступны всем в режиме реального времени на сайте ',
    info_h6_link: 'golos2020.org/constitution.',
    info_h_7: 'Как рассказать знакомым о проекте',
    info_h7_p_1: 'Сейчас важен каждый голос. Участвуйте в конституционном проекте "Голоса" сами и зовите близких, друзей и знакомых, которым не все равно. Вместе мы добьемся справедливости.',
    info_sub_heading_bottom: 'Сайт проекта: ',
    // poll results translations
    poll_results_call_header: 'Результаты опроса',
    poll_results_title: 'Подготовка письма к отправке',
    poll_results_disclaimer: 'РЕКОМЕНДАЦИЯ "ГОЛОСА": НЕ ВСТУПАТЬ В ДИАЛОГ С НЕЛЕГИТИМНОЙ ВЛАСТЬЮ',
    poll_results_button: 'Создать письмо',
    poll_results_disclaimer_p_1_start: 'В августе "Голос" доказал, что выборы были украдены. Все подтверждения — в подробном ',
    poll_results_disclaimer_p_1_link_1: 'отчете',
    poll_results_disclaimer_p_1_middle: ' и на ',
    poll_results_disclaimer_p_1_link_2: 'карте фальсификаций.',
    poll_results_disclaimer_p_2: 'Текущая власть — нелегитимна. У нее нет законных оснований проводить реформы. Эти основания есть только у гражданского общества Беларуси.',
    poll_results_disclaimer_p_3: 'Тот, кто удерживает власть преступным путем, не станет менять Конституцию в интересах народа. Нет никаких гарантий, что предложения о поправках, которые государство призывает присылать до 25 октября, будут собраны честно и действительно войдут в главный закон нашей страны.',
    poll_results_disclaimer_p_4: 'Платформа "Голос" присоединяется к абсолютному большинству голосов белорусов и не рекомендует вступать в диалог с теми, кто пытается провести поспешную реформу до новых честных выборов. Над новой Конституцией должны работать те, для кого мнение белорусов — высшая ценность.',
    poll_results_user_choice: 'ВАШ ОТВЕТ',
    poll_results_people: 'чел',
    poll_results_offers_title: 'Ваши предложения',
    poll_results_call_tab_1: 'Мнение большинства',
    poll_results_call_tab_2: 'Мои ответы',
    // mail translations
    mail_choose_medium: 'Выбрать способ отправки ПИСЕМ НЕСОГЛАСИЯ',
    mail_choose_mail_option: 'Выбрать на\u00A0основе каких ответов создать письмо',
    mail_nochoose_mail_option: 'Создать письмо на\u00A0основе мнения большинства',
    mail_disclaimer_title: 'Платформа "Голос":',
    mail_disclaimer_list_1: 'использует копии электронных обращений только и исключительно в\u00A0целях надлежащего подсчета количества направленных предложений по изменению Конституции;',
    mail_disclaimer_list_2: 'не осуществляет и не будет осуществлять сбор, обработку, хранение и/или передачу персональных данных, полученных в электронных обращениях;',
    mail_disclaimer_list_3: 'предпринимает и предпримет все меры по защите персональных данных, полученных в\u00A0электронных обращениях.',
    mail_post_title: 'ОБЫЧНОЙ ПОЧТОЙ',
    mail_post_instruction_1: 'Распечатайте письмо, впишите свои ФИО и адрес, поставьте подпись внизу.',
    mail_post_instruction_2: 'Сфотографируйте письмо и отправьте фото в чат-бот платформы "Голос".',
    mail_post_instruction_3: 'Далее отправьте письмо почтой по следующему адресу:',
    mail_post_instruction_address_1: 'Палата представителей Национального собрания Республики Беларусь',
    mail_post_instruction_address_2: '220010, Республика Беларусь, г. Минск, ул. Советская, 11 (Дом Правительства)',
    mail_post_instruction_4: 'Если у вас нет принтера или вы не знаете, как распечатать письмо с телефона, скопируйте ссылку на письмо по кнопке "Скопировать ссылку" и перешлите ее в центр печати или знакомым, у которых есть принтер.',
    mail_email_title: 'EMAIL',
    mail_email_instruction_1: 'Сделайте скриншот или фото экрана с открытым письмом. Отправьте фото в чат-бот платформы «Голос». Так мы сохраним и посчитаем все письма.',
    mail_email_instruction_2: '',
    mail_email_instruction_2_start: 'Скопируйте текст письма внизу, вставьте его в почтовый клиент, введите свои данные и отправьте его на электронную почту ',
    mail_email_instruction_2_end: ' и своему депутату, адрес которого можно найти ',
    mail_email_instruction_2_link: 'по ссылке.',
    mail_email_instruction_3: 'ПРИЛОЖЕНИЕ к письму можно скачать по ссылке: ',
    mail_email_instruction_4: 'Если вы не знаете, как отправить письмо по email, скопируйте ссылку на письмо по кнопке “Скопировать ссылку” и перешлите ее родственникам или знакомым, которые могут вам помочь. Попросите их сфотографировать экран или сделать скриншот открытого письма.',
    mail_mail_body_title: 'ПИСЬМО НЕСОГЛАСИЯ',
    mail_open_mailto: 'ОТКРЫТЬ ПИСЬМО В ПОЧТОВОМ КЛИЕНТЕ',
    mail_open: 'Открыть',
    mail_print_mail_button: 'Распечатать письмо',
    mail_copy_link_button: 'Скопировать ссылку',
    mail_mailto_button: 'Открыть в почтовом клиенте',
    mail_copy_text_button: 'Скопировать текст письма',
    mail_header_p_1: 'Палата представителей Национального собрания Республики Беларусь',
    mail_header_p_2: '220010, Республика Беларусь, г. Минск, ул. Советская, 11 (Дом Правительства)',
    mail_header_p_3: 'Электронный адрес для отправки корреспонденции: Zakon@house.gov.by',
    mail_body_p_1_1: 'Я, ',
    mail_body_p_1_2: ', направляю в адрес Палаты представителей Национального собрания свои предложения по внесению изменений в Конституцию Республики Беларусь. Прошу учесть их в дальнейшей работе.',
    mail_body_block_1_title: 'Раздел 1. Я ТРЕБУЮ БЕЗОТЛАГАТЕЛЬНО ПРОВЕСТИ НОВЫЕ ЧЕСТНЫЕ ВЫБОРЫ ПРЕЗИДЕНТА РЕСПУБЛИКИ БЕЛАРУСЬ. БЕЗ НИХ ПРОВЕДЕНИЕ КОНСТИТУЦИОННОЙ РЕФОРМЫ СЧИТАЮ НЕВОЗМОЖНЫМ.',
    mail_body_block_1_p_1: 'Я выражаю несогласие с попыткой инициировать изменения в Конституцию в условиях текущего политического кризиса, вызванного фальсификациями на президентских выборах 9 августа 2020 года.',
    mail_body_block_1_p_2: 'В качестве доказательства многочисленных нарушений при подсчете голосов на избирательных участках прилагаю ссылку на отчет платформы "Голос": ',
    mail_body_block_1_p_3: 'Я считаю, что любые изменения в Конституцию могут быть внесены только после выполнения следующих условий:',
    mail_body_block_1_p_3_item1: 'прекратить политическое преследование граждан со стороны властей, привлечь к законной ответственности и возбудить уголовные дела против виновных в убийствах, насилии и пытках;',
    mail_body_block_1_p_3_item2: 'освободить всех политических заключенных и узников совести, отменить неправомерные судебные постановления, выплатить компенсации всем пострадавшим;',
    mail_body_block_1_p_3_item3: 'признать выборы 9 августа 2020 года недействительными и провести новые выборы Президента Республики Беларусь. Они должны быть проведены с неукоснительным соблюдением международных стандартов и национального законодательства, а также при новом составе всех комиссий по выборам, включая Центральную комиссию Республики Беларусь по выборам и проведению республиканских референдумов.',
    mail_body_block_1_p_4: 'Мое предложение сводится к тому, что изменение Конституции возможно только после новых выборов и выполнения остальных перечисленных требований.',
    mail_body_block_2_title: 'Раздел 2. Я ВЫРАЖАЮ НЕДОВЕРИЕ НЫНЕШНИМ ВЛАСТЯМ БЕЛАРУСИ, КОТОРЫЕ ИГНОРИРУЮТ ТРЕБОВАНИЯ ГРАЖДАН. Я ПОЛАГАЮ, ЧТО НАВЯЗАННАЯ РЕДАКЦИЯ КОНСТИТУЦИИ БУДЕТ ПРОТИВОРЕЧИТЬ ВОЛЕ НАРОДА. ПОЭТОМУ НАПРАВЛЯЮ СПИСОК ТЕХ ПОПРАВОК, КОТОРЫЕ СЧИТАЮ ПРАВИЛЬНЫМ ВНЕСТИ В КОНСТИТУЦИЮ ПОСЛЕ ПРОВЕДЕНИЯ НОВЫХ ЧЕСТНЫХ ВЫБОРОВ.',
    mail_body_block_2_p_1: 'Предлагаемые изменения в Конституцию после выполнения условий, изложенных в Разделе 1:',
    proposed_changes_branches_of_government: 'Распределить полномочия между Президентом, Парламентом, Правительством и судами на принципах, изложенных в первоначальной редакции Конституции 1994 года (до внесения изменений по итогам республиканских референдумов 24.11.1996 г., 17.10.2004 г).',
    proposed_changes_president_term_limit: 'Определить в Конституции, что одно и то же лицо может быть Президентом не более двух сроков, вне зависимости от изменения Конституции в период нахождения данного лица на посту Президента.',
    proposed_changes_integration_with_foreign_countries: 'Определить в Конституции, что вхождение Республики Беларусь в межгосударственные образования с участием других государств и выход из них возможны только путем проведения республиканского референдума.',
    proposed_changes_state_language: 'Определить в Конституции, что государственным языком Республики Беларусь является белорусский язык, но Республика Беларусь должна обеспечивать право свободного пользования русским языком как языком межнационального общения.',
    proposed_changes_republican_referendums: 'Определить в Конституции, что республиканские референдумы должны назначаться Парламентом по собственной инициативе, предложению Президента или определенного количества граждан, обладающих избирательным правом.',
    proposed_changes_parliament_houses_number: 'Определить в Конституции, что Парламент Республики Беларусь является однопалатным, все депутаты Парламента избираются гражданами Республики Беларусь на основе всеобщего, свободного, равного, прямого избирательного права при тайном голосовании.',
    mail_body_block_3_title: 'Раздел 3. Я НАСТАИВАЮ НА ЗАКОННОМ ПОРЯДКЕ ВНЕСЕНИЯ ИЗМЕНЕНИЙ В КОНСТИТУЦИЮ.',
    mail_body_block_3_p_1: 'Поскольку вопросы распределения полномочий между ветвями власти, ограничения срока полномочий Президента, вхождения Республики Беларусь в межгосударственные образования и выхода из них, а также количества палат Парламента определены Разделами I и IV Конституции, которые могут быть изменены только путем референдума, считаю необходимым отметить, что для изменения Конституции необходим республиканский референдум.',
    mail_body_block_3_p_2: 'Согласно Разделу 1 моих предложений, считаю, что проведение республиканского референдума может осуществляться только после выполнения всех перечисленных в Разделе 1 условий, новым составом всех комиссий по выборам, включая Центральную комиссию Республики Беларусь по выборам и проведению республиканских референдумов.',
    mail_footer_p_1: 'С уважением,',
    mail_footer_p_2: 'адрес проживания: ',
    mail_helper_text: 'Обязательное поле',
    mail_label_name: 'Введите ваше ФИО для генерации письма',
    mail_placeholder_name: 'Иванов Иван Иванович',
    mail_label_address: 'Введите ваш адрес регистрации',
    mail_placeholder_address: 'г. Минск, ул Ленина 23-34',
    mailto_subject: 'Предложение по изменениям в Конституции',
    mail_warning_p_1: 'В некоторых браузерах кнопка "РАСПЕЧАТАТЬ ПИСЬМО" может работать некорректно. В таких случаях:',
    mail_warning_list_1_start: 'попробуйте открыть эту ссылку - ',
    mail_warning_list_1_end: ' - в другом браузере или на другом устройстве (лучше всего печать работает в Google Chrome на компьютере),',
    mail_warning_list_2: 'отправьте документ на печать через интерфейс браузера (ctrl+p на компьютере, меню->печать на мобильном устройстве),',
    mail_warning_list_3: 'попросите помочь распечатать письмо кого-то из знакомых/друзей.',
    mail_warning_p_2: 'Если кнопка "ОТКРЫТЬ В ПОЧТОВОМ КЛИЕНТЕ" работает некорректно Вы можете скопировать текст и вставить его в письмо.',
    mail_warning_p_3_start: 'Также Вы можете попробовать открыть эту ссылку - ',
    mail_warning_p_3_end: ' - в другом браузере или использовать другой почтовый клиент.',
    mail_copy_attachment: 'Кнопка позволяет скопировать ТОЛЬКО ТЕКСТ. ПРИЛОЖЕНИЕ необходимо скачать и вставить ВРУЧНУЮ!',
    mail_copy_attachment_done: 'Текст скопирован.',
    mail_copy_link_done: 'Ссылка скопирована',
    mail_link_in_progress: 'Создаём ссылку...',
    poll_res_first_desc: 'Необходимое условие до внесения изменений в Конституцию Беларуси:',
    go_counter_title: 'Гуляет 19-20 декабря',
    go_last_title: 'Предыдущие прогулки',
    go_last_p1: 'В прошлые разы в мимрных протестах в воскресенье участвовали <strong>белорусы из более чем 400 городов, деревень и посёлков страны,</strong> а также неравнодушные <strong>жители более 1400 городов и деревень из 87 стран!</strong>',
    go_last_p2: 'На карте цветом закодировано число гулявших городе (чем "краснее" точка, тем больше человек гуляло в этом месте).',
    letter_title: 'Новогодняя открытка для А.Г. Лукашенко',
    letter_subtitle: 'Мечты белорусов, собранные в проекте "Беларусь Мечтает"',
    letter_counter: 'Мечты для Лукашенко',

    'poll.intro.header': 'ОПРОС О КОЛЛЕКТИВНОМ УЧАСТИИ В ПРОТЕСТНЫХ АКТИВНОСТЯХ',

    'poll.intro.introduction.text': `
      Новый опрос «Голоса» очень важен. Он поможет определить <b>действенные</b> и <b>безопасные</b> способы дальнейшей борьбы с несправедливостью, ложью, произволом и репрессиями в Беларуси. Ответьте на вопросы и сообщите нам, какие действия вы готовы совершать для победы и свободы.
      <br/>
      <br/>
      Для создания этого опроса команда платформы «Голос» получила рекомендации независимых политологов, социологов и экономистов. На основе этих предложений и собственной экспертизы мы сформулировали четкий набор возможных действий, которые в условиях правового беспредела не поставят людей под удар, но помогут скорее устранить диктатуру.
    `,
    'poll.intro.introduction.points.first.header': 'Зачем проводится этот опрос',
    'poll.intro.introduction.points.first.text': `
      В Беларуси произошла политическая и экономическая катастрофа. Назревает еще и социальная.
      <br/>
      <br/>
      Выборы сфальсифицированы. Наша страна — антирекордсмен по количеству политзаключенных. Людей избивают, пытают и убивают, но против силовиков не заведено ни одного уголовного дела. Репрессии только усиливаются, и режим пытается максимально быстро и жестко заглушить голоса активных граждан.
      <br/>
      <br/>
      Этот опрос — для каждого, кто готов безопасно действовать, чтобы отстоять свои права и свободу.
    `,
    'poll.intro.introduction.points.second.header': 'Что нужно от каждого из вас',
    'poll.intro.introduction.points.second.text': `
      Пройдите опрос и обозначьте готовность к участию в общем плане активностей, которые спасут Беларусь от тоталитарного будущего.
      <br/>
      <br/>
      Электронные обращения, борьба с пропагандой и другие безопасные с личной точки зрения действия граждан Беларуси помогут направить коллективную энергию на ослабление режима.
    `,
    'poll.intro.introduction.points.third.header': 'Как это работает: конкретные механики безопасного протеста',
    'poll.intro.introduction.points.third.text': `
      В зависимости от ваших ответов мы будет предлагать вам персональные инструкции. Выполняя их вместе с сотнями тысяч белорусов-единомышленников, вы сможете повлиять на будущее своей страны, не подвергая себя рискам. 
    `,

    'poll.intro.next.disclaimer': `
      Вы можете проголосовать только один раз и не сможете исправить свои ответы позже, поэтому принимайте решение взвешенно.
      <br/>
      Мы постарались максимально учесть все возможные варианты ответов
    `,
    'poll.intro.next.label': 'Начать опрос',

    'poll.appeals_and_info.header': 'Обращения граждан и распространение информации',
    'poll.appeals_and_info.introduction.text': `
      Новый опрос «Голоса» очень важен. Он поможет определить действенные и безопасные способы борьбы с несправедливостью, ложью, произволом и репрессиями в Беларуси. Ответьте на вопросы и сообщите нам, какие действия вы готовы совершать для победы и свободы.
      <br/>   
      <br/>
      В зависимости от ваших ответов мы будем предлагать вам <b>персональные инструкции</b>. Выполняя их вместе с сотнями тысяч белорусов-единомышленников, вы сможете повлиять на будущее своей страны, не подвергая себя рискам.
    `,

    'poll.appeals_and_info.questions.international_appeals.text': 'Готовы ли вы направлять обращения в зарубежные компании и международные организации через электронную почту и писать комментарии в соцсетях, чтобы привлечь внимание мирового сообщества к проблемам Беларуси?',
    'poll.appeals_and_info.questions.international_appeals.answers.yes_with_comments.text': 'Да, буду отправлять электронные обращения и писать комментарии в соцсетях',
    'poll.appeals_and_info.questions.international_appeals.answers.yes.text': 'Да, буду писать только электронные письма',
    'poll.appeals_and_info.questions.international_appeals.answers.no.text': 'Нет, не буду писать',

    'poll.appeals_and_info.questions.domestic_appeals.text': 'Готовы ли вы писать официальные обращения в государственные органы Республики Беларусь: в исполкомы, министерства, силовые структуры, депутатские приемные и т.д.?',
    'poll.appeals_and_info.questions.domestic_appeals.answers.yes_all.text': 'Да, буду отправлять обращения в любые госорганы',
    'poll.appeals_and_info.questions.domestic_appeals.answers.yes.text': 'Да, буду отправлять обращения во все госорганы, кроме силовых структур',
    'poll.appeals_and_info.questions.domestic_appeals.answers.no.text': 'Нет, не буду делать ничего из перечисленного',

    'poll.appeals_and_info.questions.domestic_info_distribution.text': 'Готовы ли вы распространять листовки о ситуации в Беларуси, чтобы больше людей знало о том, что происходит в стране?',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_all_country.text': 'Да, буду ездить по всей Беларуси и распространять листовки',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_my_town.text': 'Да, буду распространять листовки только в своем населенном пункте',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.yes_my_building.text': 'Да, буду распространять листовки только по почтовым ящикам в своем доме/подъезде',
    'poll.appeals_and_info.questions.domestic_info_distribution.answers.no.text': 'Нет, не буду распространять',

    'poll.appeals_and_info.questions.open_letter.text': 'Готовы ли вы подписать открытое письмо за отставку Лукашенко, чтобы показать недоверие к существующей власти?',
    'poll.appeals_and_info.questions.open_letter.answers.yes_open.text': 'Да, сделаю это открыто',
    'poll.appeals_and_info.questions.open_letter.answers.yes_with_1000.text': 'Да, но хочу сделать это анонимно, пока не наберется более 1000 других подписей',
    'poll.appeals_and_info.questions.open_letter.answers.yes_anonymously.text': 'Да, но хочу сделать это анонимно, независимо от количества собранных подписей',
    'poll.appeals_and_info.questions.open_letter.answers.no.text': 'Нет, я не хочу подписывать открытые письма',

    'poll.appeals_and_info.questions.telegram_for_family.text': 'Готовы ли вы помочь своим родственниками установить «Телеграм» или «Вайбер», чтобы дать им доступ к альтернативным источникам информации?',
    'poll.appeals_and_info.questions.telegram_for_family.answers.done.text': 'Да, уже сделал это',
    'poll.appeals_and_info.questions.telegram_for_family.answers.yes_instruction.text': 'Да, помогу, если вы дадите простую и понятную инструкцию ',
    'poll.appeals_and_info.questions.telegram_for_family.answers.yes_neutral.text': 'Да, помогу, но только с доступом к нейтральным источникам информации (радикальная риторика некоторых каналов отпугнёт моих родственников)',
    'poll.appeals_and_info.questions.telegram_for_family.answers.no.text': 'Нет, не буду этим заниматься',
    'poll.appeals_and_info.next.disclaimer': 'Для перехода к следующему блоку, ответьте на все вопросы на этой странице',

    'poll.appeals_and_info.back.label': 'Вернуться назад',
    'poll.appeals_and_info.next.label': 'Следующий блок',

    'poll.economy.header': 'ЭКОНОМИКА',
    'poll.economy.introduction.text': `
      Сейчас все доступные ресурсы страны идут на сохранение существующей власти. Это усугубляет и без того серьезные проблемы белорусской экономики и ведет к неминуемому бедствию.
      <br/>
      <br/>
      Крупные международные банки и фонды больше не будут помогать Беларуси кредитами. Только за 2020 год население нашей страны вывело из банков более 2 млрд долларов.
      <br/>
      <br/>  
      Проблемы уже начались: увеличились налоги, взлетели цены на продукты, коммунальные услуги и лекарства — и все это продолжит усугубляться, чтобы государство могла оплачивать работу силового аппарата. 
      <br/>
      <br/>
      <b>Как экономическое давление ослабляет режим</b>. Власть пускает все средства на то, чтобы удержаться, поэтому ослабить ее можно через материальное и финансовое давление. Экономический прессинг может выражаться, например, в бойкотировании товаров и услуг, в проведении забастовок на госпредприятиях, спонсирующих режим, в воздействии на правящие структуры экономическими санкциями.
    `,

    'poll.economy.questions.boycott_sponsors.text': 'Готовы ли вы сами бойкотировать компании и услуги в Беларуси, которые спонсируют режим? <a href="https://golos2020.org/results/listovka.jpg" target="_blank">Вот здесь</a> есть полная инструкция, как это делать',
    'poll.economy.questions.boycott_sponsors.answers.already_doing.text': 'Уже делаю это',
    'poll.economy.questions.boycott_sponsors.answers.can_do_more.text': 'Делаю это частично, но могу делать более активно',
    'poll.economy.questions.boycott_sponsors.answers.can_not_do_more.text': 'Делаю это частично, не буду делать более активно',
    'poll.economy.questions.boycott_sponsors.answers.will_not.text': 'Не буду отказываться от привычных товаров и услуг',

    'poll.economy.questions.withdraw_savings.text': 'Готовы ли вы консолидированно, одновременно с другими белорусами, забрать свои сбережения из белорусских банков?\n',
    'poll.economy.questions.withdraw_savings.answers.done.text': 'Да, уже это сделал',
    'poll.economy.questions.withdraw_savings.answers.yes_if.text': 'Да, заберу, если удостоверюсь, что это поможет смене власти',
    'poll.economy.questions.withdraw_savings.answers.no.text': 'Нет, не стану забирать, хотя храню деньги в белорусских банках',
    'poll.economy.questions.withdraw_savings.answers.no_savings.text': 'Не храню деньги в белорусских банках',

    'poll.economy.questions.strike.text': 'Если вы работаете на государственном предприятии, выберите наиболее активный вариант действий, на которые вы готовы:',
    'poll.economy.questions.strike.answers.yes.text': 'Открыто присоединиться к стачке',
    'poll.economy.questions.strike.answers.express_opinion.text': 'Выражать своё мнение, не присоединяясь к стачке (например, отказываться подписывать письма, инициированные действующей властью; выйти из государственного профсоюза; налаживать связи и самоорганизовываться с единомышленниками на предприятии)',
    'poll.economy.questions.strike.answers.act_anonymously.text': 'Действовать на рабочем месте анонимно (к примеру, рассказывать о нарушении прав работников через чат-боты, анонимно распространять листовки с информацией о положении дел на предприятии)',
    'poll.economy.questions.strike.answers.no.text': 'Осуждаю ситуацию в стране/на предприятии, но не буду предпринимать какие-либо действия',
    'poll.economy.questions.strike.answers.support_government.text': 'Поддерживаю руководство страны и предприятия',
    'poll.economy.questions.strike.answers.dont_work_for_state.text': 'Не работаю на государственном предприятии',

    'poll.economy.questions.change_unions.text': 'Готовы ли вы выйти из государственных профсоюзов / государственных общественных организаций и вступить в независимые профсоюзы?',
    'poll.economy.questions.change_unions.answers.yes.text': 'Да',
    'poll.economy.questions.change_unions.answers.just_leave.text': 'Могу выйти из государственных профсоюзов и общественных организаций, но никуда вступать не буду',
    'poll.economy.questions.change_unions.answers.state_unions.text': 'Я состою в государственном профсоюзе и не собираюсь выходить',
    'poll.economy.questions.change_unions.answers.no_unions.text': 'Я не состою в профсоюзах',

    'poll.economy.questions.boycott_oil.text': 'Готовы ли вы в формате безопасной забастовки против нефтеперерабатывающих компаний не заправлять машину в течение месяца?',
    'poll.economy.questions.boycott_oil.answers.yes.text': 'Да',
    'poll.economy.questions.boycott_oil.answers.no_with_car.text': 'Нет, не буду этого делать, хотя у меня есть машина',
    'poll.economy.questions.boycott_oil.answers.no_car.text': 'У меня нет машины',

    'poll.economy.questions.donate.text': 'Готовы ли вы поддерживать пострадавших от режима финансово через пожертвования в фонды и на платформах взаимопомощи',
    'poll.economy.questions.donate.answers.yes.text': 'Да, буду поддерживать',
    'poll.economy.questions.donate.answers.already.text': 'Уже поддерживаю',
    'poll.economy.questions.donate.answers.no.text': 'Нет, не буду поддерживать',

    'poll.economy.back.label': 'Вернуться назад',
    'poll.economy.next.label': 'Следующий блок',

    'poll.protests.header': 'УЛИЧНЫЕ ПРОТЕСТЫ',
    'poll.protests.text': `
      Мирные протесты в формате маршей, дворовых активностей и цепей солидарности показали режиму и всему миру, что в этот раз белорусы не готовы мириться с фальсификациями, убийствами и пытками.
      <br/>
      <br/>
      <b>Как протестная активность ослабляет режим</b>. Поддержка протестной активности важна, поскольку держит в напряжении силовые структуры и показывает мировому сообществу, что белорусы не сдаются. Так усиливается давление на власть внутри страны и за ее пределами. У зажатого в тиски режима нет другого выхода, кроме как обрушиться.
    `,

    'poll.protests.questions.protest_activities.text': 'Готовы ли вы продолжать уличную/дворовую протестную активность?',
    'poll.protests.questions.protest_activities.answers.all.text': 'Буду протестовать в любом формате, включая марши, дворовые выходы, развешивание флагов и лент и т.д.',
    'poll.protests.questions.protest_activities.answers.local_only.text': 'Буду протестовать только в формате дворовых выходов',
    'poll.protests.questions.protest_activities.answers.symbols_only.text': 'Буду протестовать только в формате развешивания флагов и лент',
    'poll.protests.questions.protest_activities.answers.no.text': 'Нет, не буду протестовать',

    'poll.protests.back.label': 'Вернуться назад',
    'poll.protests.next.label': 'Отправить ответы',
    webpoll_results_helper_text: 'Если вы ешё не голосовали — перейдите в бота и введите команду /анкета.',
    webpoll_results_title: 'Опрос о коллективном участии в протестных активностях',
    webpoll_blocks: 'Блок {{number}} из {{count}}',

    dialog_link: 'Голосование за переговоры',
    dialog_header: 'МЫ ТРЕБУЕМ НАЧАТЬ ПЕРЕГОВОРЫ',
    dialog_counter_title_total: 'Проголосовало',
    dialog_counter_title: 'Требует переговоров',
    dialog_description_header: '«Голос» запускает важное голосование в поддержку единой кампании демократических сил Беларуси о переговорах с режимом.',
    dialog_description: ` 
      Белорусское общество и демократические инициативы требуют решения затянувшегося политического кризиса в стране. 
      <br/>
      <br/> 
      Национальный лидер Республики Беларусь Светлана Тихановская заявила о готовности инициировать переговоры с режимом по итогам общенационального голосования.
      <br/>
      <br/> 
      Наша общая задача — показать, сколько белорусов хочет разрешения кризиса, чтобы можно было требовать переговоров при международном содействии.
    `,
    dialog_section_about_title: 'О ЧЕМ ЭТОТ ОПРОС',
    dialog_section_about_subtitle: 'МЫ ТРЕБУЕМ НАЧАТЬ ПЕРЕГОВОРЫ',
    dialog_section_about_text: `
      Цель голосования — показать, сколько белорусов хотят выхода из кризиса. 
      <br/>
      <br/> 
      Чем больше голосов — тем быстрее начнутся переговоры. Простое и безопасное для вас действие поможет решить самый страшный кризис в истории современной Беларуси.
      <br/>
      <br/>   
      Только широкий общественный запрос может вынудить прежнюю власть Беларуси сесть за стол переговоров.
      <br/>
      <br/> 
      Самое страшное для старой системы — несгибаемое сопротивление общества, которое осознает свою правоту, ощущает поддержку всего мира и требует соблюдения своих прав. Со второй волной протеста режиму уже не справиться — он будет вынужден уступить. 
      <br/>
      <br/> 
      Только опираясь на требование народа, Светлана Тихановская и все демократические силы смогут смело и напористо обращаться и к режиму, и договариваться с другими международными лидерами.
      <br/>
      <br/> 
      Данным «Голоса» доверяют во всем мире. На платформу ссылаются Евросоюз, ОБСЕ и многие другие авторитетные правозащитные, экономические и политические объединения. 
    `,
    dialog_call_to_action: 'Проголосовать:',
    dialog_setup_header: 'УСТАНОВКА ЧАТБОТА «ГОЛОСА»',
    dialog_setup_instruction_title: 'Инструкция по регистрации',
    dialog_setup_instruction_text: 'Если вы ещё не зарегистрированы в «Голосе» или ранее удаляли чат-бот, то нажмите на значок Telegram или Viber и следуйте инструкции.',
    dialog_setup_howitworks_title: 'Как это работает',
    dialog_setup_howitworks_text: 'Ваш телефонный номер будет зашифрован в системе и информация о вас надежно защищена. Никто не узнает о вашем участии и выборе –– даже создатели и разработчики платформы. «Голос» действует в правовом поле и не нарушает закон.',
    dialog_setup_howitworks_no_phone_title: 'Как проголосовать, если нет Telegram или Viber',
    dialog_setup_howitworks_no_phone_text_1: 'Вы можете помочь проголосовать близким или проголосовать сами, если у вас нет смартфона, приложения Telegram или Viber или сложно разобраться в технологии.',
    dialog_setup_howitworks_no_phone_link: 'Проголосовать без Telegram и Viber',
    dialog_setup_howitworks_no_phone_text_2: `
      Для этого перейдите по ссылке, выберите вариант ответа голосующего и введите его номер телефона.
      <br/>
      <br/> 
      Мы совершим секундный звонок. Вам не нужно поднимать трубку и перезванивать.
      <br/>
      <br/> 
      Последние 4 цифры пропущенного входящего номера — это и есть код верификации. Введите его.
      <br/>
      <br/> 
      Голос засчитан!
    `,
    dialog_setup_howitworks_no_phone_text_3: 'Важно: это менее безопасный вариант, чем голосование через Telegram и Viber. Поэтому мы рекомендуем использовать VPN.',
    results_stations_error: 'По этому участку нет данных.',
  },
};
export default ru;
