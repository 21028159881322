import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 6, 3, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 3, 2, 3),
    },
  },
}));

const FullWidth = (props) => {
  const classes = useStyles(props);
  const { className, children } = props;

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={clsx(classes.root, className)}
    >
      <Container
        disableGutters
        maxWidth="lg"
      >
        <Grid
          container
          justify="center"
        >
          <Grid
            item
            xs={12}
          >
            {children}
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

FullWidth.defaultProps = {
};

FullWidth.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default FullWidth;
