import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { Link as RouterDomLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: '"montserrat", "Helvetica", "Arial", sans-serif',
    fontSize: (props) => (props.small ? '0.9rem' : props.big ? '1.4rem' : '1rem'),
    cursor: 'pointer',
    textTransform: 'none',
  },
}));

const VoiceLink = (props) => {
  const {
    color,
    href,
    children,
    small,
    big,
    onClick,
    bold,
    target,
    underline,
  } = props;

  const classes = useStyles({ small, big });
  const targetValue = href && !target ? '_blank' : target;

  let attrs = {
    className: classes.root,
    color,
    underline,
  };

  if (href) {
    attrs.href = href;
  }

  if (targetValue) {
    attrs.target = targetValue;
  }

  if (targetValue === '_blank') {
    attrs = {
      ...attrs,
      rel: 'noopener noreferrer',
    };
  }

  if (typeof onClick === 'function') {
    attrs = {
      ...attrs,
      onClick,
    };
  }

  return (
    <Link {...attrs}>
      {bold === false ? (children) : (<strong>{children}</strong>)}
    </Link>
  );
};

VoiceLink.defaultProps = {
  color: 'inherit',
  small: false,
  onClick: null,
  href: '',
  bold: true,
  target: null,
  big: false,
  underline: 'none',
};

VoiceLink.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  target: PropTypes.string,
  onClick: PropTypes.func,
  bold: PropTypes.bool,
  big: PropTypes.bool,
  underline: PropTypes.oneOf(['always', 'none', 'hover']),
};

export default VoiceLink;

export function RouterLink({
  children, to, color, small,
}) {
  const classes = useStyles({ small });
  const attrs = {
    className: classes.root,
    color,
    underline: 'always',
  };

  return (
    <Link component={RouterDomLink} to={to} {...attrs}>
      {children}
    </Link>
  );
}

RouterLink.defaultProps = {
  color: 'inherit',
  small: false,
};

RouterLink.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  small: PropTypes.bool,
};
