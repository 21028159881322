import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faqUrl, termsUrl } from '../../../globals';
import FullWidth from './FullWidth';
import HonestLogo from '../../assets/images/honest-people.svg';
import VoiceLink from '../components/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 'auto',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '& .MuiTypography-body1': {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiTypography-body1': {
        fontSize: '0.8rem',
      },
    },
  },
  topPart: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  bottomPart: {
    backgroundColor: 'black',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  link: {
    textTransform: 'none',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.root}>
      <FullWidth
        className={classes.topPart}
      >
        <Grid
          justify="space-between"
          alignItems="flex-start"
          container
          spacing={4}
        >
          <Grid item>
            <a href="https://honest-people.by" target="_blank" rel="noreferrer">
              <img width="163" height="65" src={HonestLogo} alt="Честные люди" />
            </a>
          </Grid>

          <Grid item>
            <Typography align="left">
              {t('social_media_follow')}
              &nbsp;
              {t('tell_friends')}
            </Typography>

            <Box mt={1}>
                <a href="https://vk.com/vote.belarus" className="vk social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'vk']} color="white" />
                </a>
                <a href="https://www.facebook.com/golos.belarus" className="facebook social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} color="white" />
                </a>
                <a href="https://www.instagram.com/vote.belarus/" className="instagram social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'instagram']} color="white" />
                </a>
                <a href="https://twitter.com/votebelarus" className="twitter social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'twitter']} color="white" />
                </a>
                <a href="https://ok.ru/group/58439959183470" className="odnoklassniki social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'odnoklassniki']} color="white" />
                </a>
                <a href="https://t.me/votebelarus2020" className="telegram social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'telegram-plane']} color="white" />
                </a>
                <a href="https://invite.viber.com/?g2=AQBZ9eVwoKmWt0vfMTIOw26q7lxbECkQmqGc7Cu1sG7XqSPkWMU8H2Rd%2F%2BF%2BiKcL" className="viber social" rel="noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fab', 'viber']} color="white" />
                </a>
            </Box>
          </Grid>

          <Grid item>
            <Link
              className={classes.link}
              href="https://www.instagram.com/explore/tags/мычестныелюди"
              rel="noopener noreferrer"
              target="_blank"
              color="inherit"
            >
              <Typography
                component="span"
                variant="body1"
              >
                {t('honest_people_hashtag')}
              </Typography>
            </Link>
            <br />

            <Link
              className={classes.link}
              href="https://www.instagram.com/explore/tags/унасестьголос"
              rel="noopener noreferrer"
              target="_blank"
              color="inherit"
            >
              <Typography
                component="span"
                variant="body1"
              >
                {t('we_have_voice_hashtag')}
              </Typography>
            </Link>
            <br />

            <Link
              className={classes.link}
              href="https://www.instagram.com/explore/tags/ягуляю"
              rel="noopener noreferrer"
              target="_blank"
              color="inherit"
            >
              <Typography
                component="span"
                variant="body1"
              >
                {t('go_hashtag')}
              </Typography>
            </Link>
            <br />

            <Link
              className={classes.link}
              href="https://www.instagram.com/explore/tags/supportbelarus"
              rel="noopener noreferrer"
              target="_blank"
              color="inherit"
            >
              <Typography
                component="span"
                variant="body1"
              >
                {t('go_eng_hashtag')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </FullWidth>

      <FullWidth
        className={classes.bottomPart}
      >
        <Grid
          justify="center"
          alignItems="center"
          container
          spacing={2}
        >
          <Grid item>
            <VoiceLink
              bold={false}
              small
              href={termsUrl}
            >
              {t('пользовательское')}
            </VoiceLink>
          </Grid>
          <Grid item>
            <VoiceLink
              bold={false}
              small
              href={faqUrl}
            >
              {t('faq')}
            </VoiceLink>
          </Grid>
        </Grid>
      </FullWidth>
    </footer>
  );
}
