import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const justifyContentStyles = (justifyContent) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent,
});

const useStyles = makeStyles((theme) => ({
  left: (props) => {
    const leftJustifyContent = props.leftJustifyContent
      && justifyContentStyles(props.leftJustifyContent);
    return {
      textAlign: props.leftAlign ? props.leftAlign : 'left',
      ...leftJustifyContent,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        order: props.reverseSM ? 1 : '',
      },
    };
  },
  right: (props) => {
    const rightJustifyContent = props.rightJustifyContent
      && justifyContentStyles(props.rightJustifyContent);
    return {
      textAlign: props.rightAlign ? props.rightAlign : 'left',
      ...rightJustifyContent,
      [theme.breakpoints.down('md')]: {
        display: props.leftSpan === 12 ? 'none' : 'block',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    };
  },
}));

const TwoCols = ({
  alignItems = 'flex-start',
  left,
  leftAlign = 'left',
  leftSpan = 6,
  right,
  rightAlign = 'left',
  rightSpan = 6,
  justifyContent = 'space-between',
  reverseSM = false,
  leftJustifyContent = '',
  rightJustifyContent = '',
}) => {
  const classes = useStyles({
    leftAlign,
    rightAlign,
    leftSpan,
    reverseSM,
    leftJustifyContent,
    rightJustifyContent,
  });

  return (
    <Grid
      container
      spacing={4}
      justify={justifyContent}
      alignItems={alignItems}
    >
      <Grid
        item
        xs={12}
        md={leftSpan}
        className={classes.left}
      >
        {left}
      </Grid>

      {
        leftSpan < 12 && (
          <Grid
            item
            xs={12}
            md={rightSpan}
            className={classes.right}
          >
            {right}
          </Grid>
        )
      }
    </Grid>
  );
};

TwoCols.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  leftAlign: 'left',
  leftSpan: 6,
  rightAlign: 'left',
  rightSpan: 6,
  reverseSM: false,
  leftJustifyContent: '',
  rightJustifyContent: '',
};

TwoCols.propTypes = {
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  left: PropTypes.element.isRequired,
  leftAlign: PropTypes.string,
  leftSpan: PropTypes.number,
  right: PropTypes.element.isRequired,
  rightAlign: PropTypes.string,
  rightSpan: PropTypes.number,
  reverseSM: PropTypes.bool,
  leftJustifyContent: PropTypes.string,
  rightJustifyContent: PropTypes.string,
};

export default TwoCols;
