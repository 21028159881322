import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ru from './ru';
import by from './by';
import en from './en';

let lng = window.localStorage.i18nextLng || 'ru';
if (window.location && window.location.pathname === '/supportbelarus') {
  lng = 'en';
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      by, ru, en,
    },
    lng,
    fallbackLng: window.location && window.location.pathname === '/supportbelarus' ? 'en' : 'ru',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
