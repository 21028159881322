import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Paragraph from './Paragraph';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.silver}`,
    padding: theme.spacing(4, 4, 2, 4),
    marginBottom: theme.margin.bottom,
    textAlign: 'center',
    height: '100%',
    '& p': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  title: {
    marginBottom: theme.margin.bottom,
    minHeight: '3rem',
  },
  icon: {
    display: 'flex',
    height: '160px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '140px',
    },
    '& img': {
      height: '120px',
      marginBottom: theme.margin.bottom,
      [theme.breakpoints.down('xs')]: {
        height: '100px',
      },
    },
  },
}));

const Card = ({
  icon = null,
  iconAlt = '',
  title,
  content,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <img
          src={icon}
          alt={iconAlt}
        />
      </Box>

      <Typography
        component="h4"
        variant="h4"
        className={classes.title}
      >
        {title}
      </Typography>

      <Paragraph
        color="textSecondary"
        textAlign="center"
      >
        {content}
      </Paragraph>
    </Box>
  );
};

Card.defaultProps = {
  icon: null,
  iconAlt: '',
};

Card.propTypes = {
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default Card;
