import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: (props) => (props.shadow ? `1px 1px 4px 2px ${theme.palette.silver}` : ''),
    maxHeight: (props) => (props.fullWidth ? 'max-content' : '500px'),
    maxWidth: '100%',
    marginBottom: theme.margin.bottom,
    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
  },
}));

const Image = ({
  shadow,
  src,
  src2x,
  alt,
  fullWidth,
}) => {
  const classes = useStyles({ shadow, fullWidth });

  const attrs = {
    className: classes.root,
    src,
    alt,
  };
  if (src2x) {
    attrs.srcSet = `${src}, ${src2x} 2x`;
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img {...attrs} />
  );
};

Image.defaultProps = {
  shadow: false,
  src2x: '',
  fullWidth: false,
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  src2x: PropTypes.string,
  shadow: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Image;
