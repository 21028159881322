import React from 'react';

import {
  Container,
} from '@material-ui/core';

import Separator from '../structure/Separator';
import Section from '../components/Section';

export default function NotFound() {
  return (
    <Container component="main" maxWidth={false}>
      <Separator />
      <Section
        background="gray"
        title="Not found"
      />
    </Container>
  );
}
