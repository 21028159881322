import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Box } from '@material-ui/core';

import { getTotalVoted } from '../../../shared/services/ResultsService';
import { electionUrl, goPath, constitutionVotePath } from '../../../../globals';
import { SocialButtons } from '../../../shared/components/SocialButtons';
import Button from '../../../shared/components/Button';
import Card from '../../../shared/components/Card';
import Counter from '../../../shared/components/Counter';
import Flags from '../../../assets/images/wrw-flags.svg';
import Book from '../../../assets/images/book.svg';
import Icon from '../../../shared/components/Icon';
import IconCheckbox from '../../../assets/images/icon-checkbox.svg';
import IconCommand from '../../../assets/images/icon-command.svg';
import IconFlag from '../../../assets/images/icon-flag.svg';
import IconLibra from '../../../assets/images/icon-libra.svg';
import Image from '../../../shared/components/Image';
import Loading from '../../../shared/components/Loading';
import Paragraph from '../../../shared/components/Paragraph';
import ResultsMapImage from '../../../assets/images/home/results-map.jpg';
import ResultsMapImage2x from '../../../assets/images/home/results-map@2x.jpg';
import Section from '../../../shared/components/Section';
import Separator from '../../../shared/structure/Separator';
import ThreeCols from '../../../shared/structure/ThreeCols';
import TwoCols from '../../../shared/structure/TwoCols';
import LandingAppIntroBlock from './landing/AppIntroBlock';

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

export default function Landing() {
  const { t } = useTranslation();
  const [total, setTotal] = useState(null);
  const [totalLoaded, setTotalLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getTotalVoted().then(
      (_totalVoted) => {
        setTotal(_totalVoted);
        setTotalLoaded(true);
      },
      () => {
        setTotalLoaded(true);
      },
    );
  }, []);

  return (
    <>
      <Section
        background="blue"
        title={t('landing_call_to_action_title')}
      />
      <Section
        content={(
          <>
            <TwoCols
              left={(
                <>
                  <Paragraph>
                    {t('landing_counters_p_1')}
                  </Paragraph>
                  <Paragraph>
                    {t('landing_counters_p_2')}
                  </Paragraph>
                  <Paragraph>
                    {t('landing_counters_p_3')}
                  </Paragraph>
                  <Paragraph>
                    {t('landing_counters_p_4')}
                  </Paragraph>
                </>
              )}
              right={(
                <>
                  <Box
                    ml={3}
                    className={classes.marginLeft}
                  >
                    {totalLoaded ? (
                      <Counter
                        title={t('landing_counters_title')}
                        total={total.TotalVoted || 0}
                        color="blue"
                      />
                    ) : <Loading />}

                    <Separator />

                    <Paragraph>
                      {t('landing_counters_p_5')}
                    </Paragraph>

                    <SocialButtons />
                  </Box>
                </>
              )}
            />
          </>
        )}
      />

      <Section
        color="white"
        align="center"
        content={(
          <Button
            text={t('dialog_link')}
            background="blue"
            href="/dialog"
          />
        )}
      />

      <Separator background="blue" />
      <Section
        background="gray"
        title={t('landing_results_title')}
        content={(
          <>
            <TwoCols
              rightAlign="right"
              left={(
                <>
                  <Paragraph>
                    {t('landing_results_p_1')}
                  </Paragraph>
                  <Button
                    small
                    background="blue"
                    href={electionUrl}
                    text={t('landing_results_button')}
                  />
                </>
              )}
              right={(
                <Image
                  src={ResultsMapImage}
                  src2x={ResultsMapImage2x}
                  alt={t('landing_results_image_alt')}
                  shadow
                />
              )}
            />
          </>
        )}
      />
      <Section
        content={(
          <TwoCols
            leftAlign="center"
            rightAlign="center"
            alignItems="stretch"
            left={(
              <LandingAppIntroBlock
                image={<Image src={Book} alt={t('landing_projects_image_alt')} />}
                text={t('landing_projects_p_1')}
                href={constitutionVotePath}
                title={t('landing_projects_button_1')}
              />
            )}
            right={(
              <LandingAppIntroBlock
                image={<Image src={Flags} alt={t('landing_projects_image_alt')} />}
                text={(
                  <Trans
                    i18nKey="landing_projects_p_2"
                    components={{ bold: <strong /> }}
                  />
                )}
                href={goPath}
                title={t('landing_projects_button_2')}
              />
            )}
          />
        )}
      />
      <Section
        background="gray"
        content={(
          <>
            <TwoCols
              leftSpan={3}
              rightSpan={9}
              alignItems="center"
              left={(
                <Icon
                  src={IconCommand}
                  alt={t('landing_icon_alt')}
                />
              )}
              right={(
                <Paragraph
                  title={t('landing_command_title')}
                >
                  {t('landing_command_p')}
                </Paragraph>
              )}
            />
          </>
        )}
      />

      <Section
        content={(
          <ThreeCols
            alignItems="stretch"
            left={(
              <Card
                icon={IconLibra}
                iconAlt={t('landing_card_icon_alt_1')}
                title={t('landing_card_title_1')}
                content={t('landing_card_content_1')}
              />
            )}
            center={(
              <Card
                icon={IconCheckbox}
                iconAlt={t('landing_card_icon_alt_2')}
                title={t('landing_card_title_2')}
                content={t('landing_card_content_2')}
              />
            )}
            right={(
              <Card
                icon={IconFlag}
                iconAlt={t('landing_card_icon_alt_3')}
                title={t('landing_card_title_3')}
                content={t('landing_card_content_3')}
              />
            )}
          />
        )}
      />
    </>
  );
}
