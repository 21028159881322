import React from 'react';

import Section from '../../../shared/components/Section';
import Paragraph from '../../../shared/components/Paragraph';
import List from '../../../shared/components/List';

export default function Terms() {
  return (
    <>
      <Section
        background="blue"
        title="Пользовательское соглашение"
      />
      <Section
        content={(
          <>
            <Paragraph
              title="Соглашение"
            >
              Вы подтверждаете, что ознакомились с условиями настоящего Соглашения
              в полном объеме до начала использования сервиса и соглашаетесь с ними.
            </Paragraph>
            <Paragraph>
              Конституция Республики Беларусь:
            </Paragraph>
            <List>
              <li>
                Статья 38. Граждане Республики Беларусь имеют право свободно избирать
                и быть избранными в государственные органы на основе всеобщего, равного,
                прямого или косвенного избирательного права при тайном голосовании.
              </li>
              <li>
                Статья 65. Выборы являются свободными: избиратель лично решает,
                участвовать ли ему в выборах и за кого голосовать.
              </li>
            </List>
            <Paragraph>
              Настоящий сервис направлен на реализацию и защиту конституционных прав граждан
              по проведению честных выборов.
            </Paragraph>
            <Paragraph>
              Владелец сервиса не преследует никаких коммерческих целей и вся получаемая им
              информация будет направлена исключительно для достижения цели информирования
              о процессе и результатах выборов Президента Республики Беларусь.
            </Paragraph>
            <Paragraph>
              Сервис позволяет загрузить 2 фотографии бюллетеня для голосования
              от 1 избирателя (далее - пользователь): 1 фото лицевой стороны и 1 фото оборотной.
              Владелец сервиса вправе использовать полученное изображение, обрабатывать его,
              скрывать части изображения и публиковать в открытом доступе.
              Не допускается модификация изображения, за исключением сокрытия личных данных,
              содержащихся на изображении, лиц людей, а также нанесения вотермарки.
              Допускается также изменение таких параметров, как размер,
              яркость, контраст и другие, не влекущие изменения его содержания.
            </Paragraph>
            <Paragraph>
              В целях систематизации информации пользователь предоставляет владельцу сервиса
              следующие данные: номер мобильного телефона, номер участка на котором проголосовал
              пользователь, а также, опционально, социально-демографические данные: возраст,
              пол и другие.
            </Paragraph>
            <Paragraph>
              Размещая фотографию, вы соглашаетесь с тем, что ваш голос будет учтен системой
              при оценке явки избирателей на участок, количества проголосовавших
              за конкретного кандидата. Информация в виде обобщенных данных будет
              обнародована и доступна для публичного использования после завершения голосования.
            </Paragraph>
            <Paragraph>
              Мы гарантируем конфиденциальность. Данные, позволяющие идентифицировать пользователя,
              хранятся в зашифрованном виде.
            </Paragraph>
            <Paragraph>
              При обработке данных мы принимаем необходимые правовые, организационные
              и технические меры их защиты от неправомерного или случайного доступа к ним,
              уничтожения, изменения, блокирования, копирования, предоставления, распространения,
              а также от иных неправомерных действий.
            </Paragraph>
            <Paragraph>
              Использование сервиса добровольное и остаётся на усмотрение пользователя.
              Владелец сервиса не несет ответственности за последствия,
              которые могут возникнуть у пользователя в связи с использованием сервиса
              и действием третих лиц.
            </Paragraph>
          </>
          )}
      />
    </>
  );
}
