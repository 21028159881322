import actionTypes from '../actionTypes';

const initialState = {
  currentPageIndex: 0,
  config: {
    pages: [],
  },
  token: {
    value: null,
    isValid: true,
  },
  answers: {},
  isCompleted: null,
};

export default function webpollReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_WEBPOLL: {
      const webpoll = action.payload;
      return {
        ...state,
        config: webpoll,
      };
    }
    case actionTypes.LOAD_WEBPOLL_ANSWERS: {
      const answers = action.payload;
      return {
        ...state,
        answers,
      };
    }
    case actionTypes.SET_WEBPOLL_ANSWER: {
      const { questionId, answerId } = action.payload;
      return {
        ...state,
        answers: {
          ...state.answers,
          [questionId]: answerId,
        },
      };
    }
    case actionTypes.SET_WEBPOLL_PAGE: {
      const pageIndex = action.payload;
      return {
        ...state,
        currentPageIndex: pageIndex,
      };
    }
    case actionTypes.SET_WEBPOLL_TOKEN: {
      const tokenValue = action.payload;

      return {
        ...state,
        token: {
          ...state.token,
          value: tokenValue,
          isValid: true,
        },
      };
    }
    case actionTypes.LOAD_WEBPOLL_TOKEN_STATUS + actionTypes.FAIL: {
      return {
        ...state,
        token: {
          ...state.token,
          isValid: false,
        },
      };
    }
    case actionTypes.SET_WEBPOLL_COMPLETED: {
      const isCompleted = action.payload;
      return {
        ...state,
        isCompleted,
      };
    }
    default:
      return state;
  }
}
