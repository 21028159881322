import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Paragraph from '../../../../shared/components/Paragraph';
import Button from '../../../../shared/components/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    '& p': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  },
  button: {
    alignSelf: 'center',
    marginTop: 'auto',
  },
  image: {
    minHeight: '130px',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
    textAlign: 'center',
  },
}));

const AppIntroBlock = ({
  image,
  text,
  href,
  title,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
    >
      <Box className={classes.image}>
        {image}
      </Box>
      <Paragraph>
        {text}
      </Paragraph>
      <Button
        className={classes.button}
        small
        background="blue"
        href={href}
        text={title}
      />
    </Box>
  );
};

AppIntroBlock.defaultProps = {
};

AppIntroBlock.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.element.isRequired,
  text: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AppIntroBlock;
