const en = {
  translations: {
    polling_stations: 'Polling stations',
    результаты: 'Preliminary election results',
    voice: 'voice',
    with_photo: 'photos',
    according_to_CEC: 'according to PEC',
    voters: 'voters',
    unique_confirmed: 'unique confirmed votes on the "Voice" platform',
    'фото в системе': 'verified ballot photos on the "Voice" platform',
    social_media_follow: 'Follow us on social media.',
    tell_friends: 'Tell your friends about us!',
    пользовательское: 'Terms of service',
    faq: 'Frequently Asked Questions',
    stations: 'stations',
    'официальная явка': 'voter turnout according to PEC',
    according_to_observers: 'voter turnout according to observer',
    honest_people_hashtag: '#weAreHonestPeople',
    we_have_voice_hashtag: '#weHaveAVoice',
    all: 'All',
    dmitriyev: 'Dmitriyeu',
    kanopatskaja: 'Kanapatskaya',
    lukashenko: 'Lukashenka',
    tihanovkaja: 'Tsikhanouskaya',
    cherechen: 'Cherachen',
    against: 'Against all',
    corrupted: 'Spoilt ballot',
    ignore: 'Did not vote',
    dmitriyevAccusative: 'for Dmitriyeu',
    kanopatskajaAccusative: 'for Kanapatskaya',
    lukashenkoAccusative: 'for Lukashenka',
    tihanovkajaAccusative: 'for Tsikhanouskaya',
    cherechenAccusative: 'for Cherachen',
    againstAccusative: 'against all',
    corruptedAccusative: 'This ballot was spoilt',
    ignoreAccusative: '',
    pollingStationDetails: 'See photos',
    pollingStationDownload: 'Download the flyer',
    voices_plural: 'voices',
    voices_plural_stats: 'voices',
    voted_for: 'This vote was casted',
    back_side: 'The back',
    honest_stations: 'The victory of the candidate coincides in the official results of the commission and the "Voice" data',
    dishonest_stations: 'There are more votes for a candidate in the "Voice" platform than in the official report (falsification)',
    not_enough_data: 'Insufficient data from the polling stations to conclude about the election commission\'s honesty',
    total_num_of_voices: 'users on the "Voice" platform',
    участок: 'Polling station',
    all_stations: 'All polling stations',
    область: 'region',
    title: 'Online platform "Voice". This time your voice matters!',
    processing_monitored: 'Voter turnout according to observers is being processed',
    processing_voters: 'Voter turnout is being processed',
    processing_official_numbers: 'The final results were not posted or photographed',
    processing_official_numbers_table: 'No data',
    protect_now: 'Ensure your voice is heard!',
    more_ballots_than_official: 'There are more "Voices" than PEC announced votes',
    more_photos_than_official: 'There are more photos of ballots than PEC announced votes',
    destroyed_ballot: 'This ballot was spoilt',
    summary_results: 'Voting results',
    detailed_report_button: 'Detailed report',
    results_button: 'PEC results',
    summary_1: 'The elections are invalid, because falsifications were detected at every third polling station.',
    summary_2: 'The final figures announced by the CEC differ significantly from the precincts\' official results.',
    summary_3: 'Alyaksandr Lukashenka cannot be considered the legitimate president of Belarus.',
    summary_4: 'A large number of votes cast for Sviatlana Tsikhanouskaya were stolen by precinct commissions.',
    chatbot_send_photo: 'Send a photo of your ballot to the chatbot of the "Voice" platform',
    detailed_report: '<bold>The detailed report<bold>',
    detailed_report_from: 'created by the "Voice" platform and the "Honest People" initiative can be found',
    here: 'here',
    no_photos: 'Photos of the ballots are being processed',
    no_photos_available: 'There are no photos for this candidate',
    sensitive_data: 'The photo is hidden because it may contain the voter\'s passport or other sensitive data. The "Voice" platform possesses the original photo. There are a total of 45,865 such photos from {{photos}} (less than 10%)',
    photo_quarantined: 'The photo is temporarily hidden, as it is undergoing additional processing',
    report_personal_data: 'We have hidden personal data (passport or face) from most of the photographs containing it. If you still notice a photo with personal information on it or the one incorrectly processed, let us know by clicking on the "Hide photo" button below.',
    PEC_results: 'PEC results',
    send_results_photo: 'Send PEC results',
    station_code: 'Polling station number',
    photos_disclaimer: 'Photos of the PEC results are from independent observers of the "Honest People" and "Zubr" initiatives, as well as from open sources. We estimate the accuracy of the data to be 98%. If you find an inaccuracy or error, please let us know via',
    this_form: 'this form',
    site_title: 'Platform "Voice" results: find your station!',
    site_description: 'We will help to protect your choice and show the results, calculated not by commissions, but by voters. Go to Viber, Telegram, tell us about your choice!',
    error_notify: 'Report a problem',
    notify: 'Hide photo',
    notify_personal_data: 'Contains personal data',
    notify_incorrect_data: 'Incorrect candidate',
    notify_other: 'Other problem',
    problem: 'Problem',
    state_problem: 'Describe problem',
    report_manually: 'If for some reason the form above does not work, submit the error manually using',
    missing_photo1: 'Can\'t find your photo? ',
    missing_photo2: 'The "Voice" platform contains ',
    missing_photo3: '{{registered}} voices',
    missing_photo4: ' not linked to any polling station, of which {{photoVoices}} are with a photo.',
    walking_call_header: 'Go out for a walk in a good company',
    // walking_call_header: 'Project #ягуляю has been suspended',
    walking_suspended: 'The "Voice" platform has temporarily put the <strong>#ягуляю</strong> project on hiatus to survey Belarusians\' opinions on changes in the Constitution.',
    walking_poll: 'Participate in ',
    walking_link_to_poll: 'the Constitution survey.',
    button_support_belarus: 'SUPPORT BELARUSIANS',
    how_does_it_work: 'How does it work?',
    us_already: 'Registered with "Voice"',
    walking_today: '{{GoOut}} for a walk in Belarus on {{Sunday}}',
    walking_home: '{{Walk}} in Belarus on {{Sunday}}',
    image_author: 'illustration by:',
    // support_belarus_header: 'Other nations supporting Belarus',
    support_belarus_header: 'SUPPORT BELARUS TODAY',
    link_to_support_belarus_button: 'Join {{SupportersAbroad}} people',
    link_to_support_belarus_temp: 'JOIN PEOPLE SUPPORTING BELARUS',
    walking_title_step_1: 'Step 1: Register with "Voice"',
    walking_title_step_2: 'Step 2: Tell the chat bot you\'re out for a walk',
    walking_title_step_3: 'Step 3: Share your walk with friends',
    walking_activate_bot: 'Activate the bot before your walk',
    walking_activate_bot_desc_1: 'Before you head out for a walk, activate the chat bot by sending the message <strong>/ягуляю</strong>. This way "Voice" will know that on this specific day you joined the protests. If you would like to go out but you cannot – send the message anyway.',
    walking_activate_bot_desc_2: 'Participation in legal large-scale marches, local protests, solidarity chains, any initiatives, including a walk in the yard and a conversation with your neighbors — your protest can take any form. The important thing is that you\'re active, and that our chat bot knows about it. The platform does not need your personal data — we would only like to count the participants in the protests.',
    walking_activate_bot_desc_3: 'We will set up a count every Sunday, as well as on special days, which will be announced separately',
    walking_hashtag: '/ягуляю',
    walking_use_hashtag: 'Use the hashtag #ягуляю',
    walking_use_hashtag_desc_1: 'The more there are of us, the louder is our voice. The authorities will have a much harder time saying that we cannot be heard. Take pictures of yourself during the walk, post them in social networks, and use the hashtag <strong>#ягуляю</strong>.',
    walking_use_hashtag_desc_2: 'Tell everyone you know about the project. Our counter is the proof of our truth and our victory.',
    walking_how_title: 'How it works',
    walking_how_desc_1: '<bold>#ягуляю</bold> is a new special project by "Voice". This time we would like to count the Belarusians who go out to protests to stand for their freedom and new fair elections. We would like to show the world the real picture of our fight for justice.',
    walking_how_desc_2: '<bold>#ягуляю</bold> will count everyone: those tho are out for a legal walk in Minsk and other large cities, those who are out for a walk in remote corners of Belarus to support protesters, those who objectively cannot walk but would like to stand against lawlessness.',
    walking_how_desc_3: 'It\'s anonymous and safe. Anyone can join. Even those who would like to support the protest but have not yet resolved to do it openly.',
    walking_how_desc_4: 'More than <bold>1 300 000</bold> people have already registered with "Voice". Now we are re-opening the registration on the platform.',
    walking_how_desc_5: 'Installed the bot? Share our idea with your friends!',
    walking_how_desc_6: '<bold>If you have already registered with the chat bot, proceed to the next step.</bold>',
    walking_how_desc_7: '<bold>If you are registering for the first time, press the button for your favorite messenger app and follow the chat bot instructions. On the first step, confirm that you have provided your phone number (it will be securely encrypted).</bold>',
    no_messenger: 'If you do not have a messenger app',
    faq_answers: 'Answers to frequently asked questions',
    go_hashtag: '#ягуляю',
    support_belarus_call_header: 'Support people protesting in\u00A0Belarus on {{Sunday}}',
    support_belarus_background_image: 'Photo of Stela with thousands of protesters in\u00A0Minsk',
    support_belarus_counters_title_total: 'World support for\u00A0Belarus',
    support_belarus_counters_title_total_walking: '{{Protest}} in Belarus on\u00A0{{Sunday}}',
    support_button_header: 'Support Belarus Today',
    support_button_desc: 'Just press the "SUPPORT THE PROTESTERS" button on\u00A0Sunday, and we will show to people in Belarus that they are not alone and that the whole world supports those who walk today against the regime and for democracy.',
    support_button: 'Support the protesters',
    support_button_temp: 'Come back on Sunday to support Belarus',
    support_header: 'Why Does It Matter?',
    support_desc_0: 'Every Sunday thousands of Belarusians take to the streets to peacefully protest against the falsified elections by the "last dictator of Europe" Alyaksandr Lukashenka.',
    support_desc_1: 'We created a unique platform, "Voice", to count the number of people attending these protests. This time we add a possibility for the whole world to show their support of the will of Belarusians to live in a law-bound, democratic state.',
    support_desc_2: 'We invite you to show solidarity with the people of Belarus, who have long been subjected to terror and repression for their views by the country‘s authoritarian government.',
    support_icons_header: 'Why Are People Protesting?',
    support_icons_title_0: 'The country has been governed by the "last dictator of Europe" A. Lukashenka for the past 26 years.',
    support_icons_title_1: 'All Belarusian referendums and elections after 1994 were declared illegitimate by the OSCE.',
    support_icons_title_2: 'During the election campaign and in the days following the election, over 7 000 people were brutally arrested at peaceful protests objecting the falsified results.',
    support_icons_title_3: 'Illegal mass arrests, fabricated crimes, extremely high and unreasonable fines, abuse of power of law enforcement agencies (kidnapping, blackmail, aggression, and violence up to murder) – this is a present-day reality in Belarus.',
    support_icons_desc: 'This fundamentally contradicts the idea of free, transparent, democratic elections and leaves no doubt about them being illegal and falsified. Citizens of Belarus are deprived of basic human rights: freedom of speech, the right to vote, or the right to freedom and personal inviolability. There is no presumption of innocence, and independent, fair courts are non-existent in Belarus. Furthermore, police can hinder the right of citizens of Belarus to timely medical care.',
    support_flags: 'Image of the national white-red-white flags',
    support_share_desc: 'Share this message to allow other people support Belarus',
    support_icon_0: 'Gun icon',
    support_icon_1: 'Crossed eye icon',
    support_icon_2: 'Prison man icon',
    support_icon_3: 'Libra icon',
    support_belarus: 'Support Belarus',
    photo_by: 'photo by ',
    go_eng_hashtag: '#supportbelarus',
    support_button_disabled: 'Thanks for your support!',
    enable_geolocation: 'Please enable geolocation in your browser or on your device to let us know your region.',
    walking_info: 'On\u00A0Sunday, September\u00A013, there was no internet access in Belarus for many hours, which prevented many of our users from sending a command to\u00A0the chatbot. Therefore, we will continue accepting your commands until 12.00\u00A0on\u00A0Monday. Hurry up, send /ягуляю to the "Voice" platform chatbot!',
    walking_result_title: 'We go for walks',
    walking_result_supporters_title: 'Our Supporters',
    walking_result_p1: 'Now Belarusians are marching every day at different times throughout the country: to continue to express their opinion, but to avoid large-scale detentions. For several weeks now, the format of local marches has been used — between courtyards and across a district.',
    walking_result_p2: 'And to find out how many people show their disagreement with the regime through street demonstrations — with such a decentralized format — is especially important.',
    walking_result_p3: 'If you are taking part in street protests this weekend, be sure to tell us. A form of protest can be a procession between courtyards, participation in a district march, or in a large demonstration.',
    walking_result_p4: 'To report your walk, you need to send a command <strong>/ягуляю</strong> to the Voice chatbot.',
    walking_result_p5: 'Spread the word to your friends who are also protesting!',
    walking_by: 'in Belarus',
    walking_eu: 'in Europe',
    walking_world: 'in the world',
    support_coordination_council: 'Support the Coordination Council',
    coordination_council: 'the Coordination Council',
    voted: 'Voted',
    poll: 'poll',
    polls_explain_1: 'The "Voice" platform launches a poll in support of the Coordination Council, an authorised representative of the Belarusian people.',
    polls_explain_2: 'The Coordinating Council was created at the initiative of Sviatlana Tsikhanouskaya to hold dialogue with the authorities on behalf of civil society and fulfil the following demands:',
    polls_explain_3: 'release political prisoners and stop the repression,',
    polls_explain_4: 'prosecute those responsible for murder and violence,',
    polls_explain_5: 'hold new fair elections.',
    polls_explain_6: 'Together we will overcome the political crisis and achieve agreement in the society. We will defend the sovereignty and independence of our country.',
    polls_take_part: 'Take part in the poll',
    polls_take_part_p1: 'The poll is conducted through one of the messengers, Viber or Telegram. Upon your registration on the "Voice" platform, the chatbot will ask you for your phone number. It will allow us to ensure users\' uniqueness and eliminate cheating. Your phone number will be encrypted, which means it will be safe. Participation in the poll is anonymous.',
    polls_take_part_p2: 'When you register with us, we will be able to notify you about new polls as well as their results.',
    polls_take_part_p3_1: 'Take part in the poll on recognition of ',
    polls_take_part_p3_2: ' as the representative of the Belarusian society, authorised to express a voice, negotiate with the state and international structures, and will aim to fulfil the demands of Belarusians.',
    polls_take_part_p4: 'Open the chat with the chatbot by clicking on either Telegram or Viber button. Submit the word <bold>/опрос</bold> to participate in the poll.',
    polls_about_voice: 'About the "Voice" platform',
    polls_about_p1: '<bold>More than 1,290,000</bold> Belarusians are registered on the "Voice" platform.',
    polls_about_p2_1: 'The platform has already proven its reliability and validity. We conducted an alternative vote count in the presidential elections and revealed numerous falsifications. We published ',
    polls_about_p2_2: 'an interactive map of polling stations',
    polls_about_p2_3: ' with violations and ',
    polls_about_p2_4: 'a final report,',
    polls_about_p2_5: ' that refuted the official results of the CEC.',
    polls_about_p3: 'We have preserved all personal data, and none of our users has faced punishment as a result of their choice.',
    polls_about_p4: 'People of Belarus trust us in our expertise in creating reliable digital solutions. We continue to work in the interests of civil society.',
    polls_icon_pair: 'People holding hands',
    registered_with_us: 'Registered with "Voice"',
    landing_call_to_action_title: 'A Platform to Hear Every Belarusian',
    landing_counters_p_1: 'The "Voice" platform has been created by Belarusian developers to evaluate the fairness of the elections and to provide an alternative registry of votes.',
    landing_counters_p_2: 'After proving that the elections had been fraudulent, we extended the platform to support counting people who participate in peaceful protests.',
    landing_counters_p_3: 'In addition, the platform cooperates with the Coordination Council and collects people\'s opinions on the Council decisions.',
    landing_counters_p_4: 'Find all of our projects below, and join us!',
    landing_counters_p_5: 'Register on the platform by using one of the messenger apps below:',
    landing_counters_title: 'Registered with\u00A0"Voice"',
    landing_results_title: 'Proof of Electoral Fraud',
    landing_results_button: 'Election results',
    landing_results_p_1: 'The "Voice" platform has collected more than half a million ballots, which serve as a proof that the elections were fraudulent, and has created an interactive report, which anyone can use to see the results of our work.',
    landing_results_image_alt: 'Results Map',
    landing_projects_p_1: 'Voting against amendments to the Constitution and collecting proposals for amendments to the Constitution of Belarus.',
    landing_projects_button_1: 'Poll',
    landing_projects_p_2: 'The <bold>#ягуляю</bold> project has been created to count and support people who protest against fraudulent elections by participating in peaceful protests.',
    landing_projects_button_2: '#ягуляю project',
    landing_projects_image_alt: 'The Coordination Council opinion poll',
    landing_command_title: 'Our team',
    landing_icon_alt: 'Icon –– a group of inspired people',
    landing_command_p: 'We are Belarusians\u00A0–– independent software engineers and people who care. Moreover, every Belarusian who wants to participate in the creation of a prosperous and successful country belongs to our team. We want the rights and freedoms of citizens of Belarus to be respected, and the authorities to work on improving people\'s lives.',
    coordination_council_1: 'Coordination',
    coordination_council_2: 'Council',
    coordination_council_logo_alt: 'Coordination Council Logo',
    landing_card_icon_alt_1: 'Libra icon',
    landing_card_title_1: 'It is legal and anonymous',
    landing_card_content_1: 'User data is encrypted and securely stored. No one will know about your participation and your choice, not even the\u00A0developers. The\u00A0"Voice" platform operates legally and does not violate the\u00A0law.',
    landing_card_icon_alt_2: 'Checkbox icon',
    landing_card_title_2: 'Why trust this system?',
    landing_card_content_2: 'We have demonstrated our transparency and openness during the\u00A0presidential elections. We have fulfilled our promises, found falsification, and protected user data. More than 1\u00A0million people trust us.',
    landing_card_icon_alt_3: 'Flag icon',
    landing_card_title_3: 'What does it affect?',
    landing_card_content_3: 'Polls through the\u00A0"Voice" platform may affect the decisions made by\u00A0The\u00A0Coordination Council towards the\u00A0development of our country and the recovery from a deep political crisis.',
    landing_dreams_title: 'Belarus dreams',
    landing_dreams_subtitle: 'Collecting ideas for new Belarus',
    landing_dreams_send: 'Collected dreams',
    polls_results_title: 'Polls results',
    polls_image_alt: 'Изображение карты с результатами опроса в поддержку Координационного Совета',
    polls_results_p1: '"Голос" подвел итоги опроса в поддержку Координационного Совета.',
    polls_results_p2: 'Доверие белорусов было очевидно с начала опроса, но особенно важно озвучить результаты: более <strong>522,000</strong>\u00A0пользователей оставили свои голоса.',
    polls_results_p3: '<strong>96.75%</strong> из них признали Координационный Совет полномочным представителем своих интересов в диалоге с государством. <strong>1.25%</strong>\u00A0высказались против, <strong>2%</strong> затруднились ответить.',
    polls_results_p4: 'Так распределилсь голоса <strong>504,289</strong>\u00A0белорусов, которые, по состоянию на 25\u00A0сентября, поддержали Координационный Совет:',
    polls_results_line_1: 'Бресткая обл.',
    polls_results_line_2: 'Витебская обл.',
    polls_results_line_3: 'Гомельская обл.',
    polls_results_line_4: 'Гродненская обл.',
    polls_results_line_5: 'Минская обл.',
    polls_results_line_6: 'Могилевская обл.',
    polls_results_line_7: 'Минск',
    image_nina_alt: 'Я гуляю - Nina Baginskaya, drawing',
    selected: 'Выбрано',
    pollbuilder_call_header: 'Сбор предложений к изменениям в\u00A0конституцию Беларуси',
    pollbuilder_title_1: 'Действия до изменения конституции',
    pollbuilder_title_2: 'Предлагаемые изменения в\u00A0конституцию',
    accordion_info_show_title: 'Узнать детали',
    accordion_info_hide_title: 'Скрыть детали',
    pollbuilder_title_3: 'Ваши предложения (не обязательно)',
    pollbuilder_desc_3: 'Возможно есть что-то очень важное, что мы забыли –– оставьте, пожалуйста, свой комментарий и мы обязательно передадим его специалистам по конституционному праву в нашей команде.',
    pollbuilder_title_4: 'Расскажите немного о себе (не обязательно)',
    pollbuilder_desc_4: 'Этот шаг не обязателен, но позволит нам лучше понимать нашу аудиторию.',
    pollbuilder_select_sex_title: 'Пол',
    pollbuilder_select_sex_1: 'Женский',
    pollbuilder_select_sex_2: 'Мужской',
    pollbuilder_select_age_title: 'Возраст',
    pollbuilder_select_age_0: 'До 18',
    pollbuilder_select_age_1: '18-24',
    pollbuilder_select_age_2: '25-34',
    pollbuilder_select_age_3: '35-44',
    pollbuilder_select_age_4: '45-54',
    pollbuilder_select_age_5: '55-64',
    pollbuilder_select_age_6: '65-74',
    pollbuilder_select_age_7: '75 и старше',
    pollbuilder_select_education_title: 'Образование',
    pollbuilder_select_education_1: 'Неполное или общее среднее (школа)',
    pollbuilder_select_education_2: 'Профессионально-техническое или среднее специальное (ПТУ, колледж, техникум)',
    pollbuilder_select_education_3: 'Незаконченное высшее (более 3 курсов ВУЗа)',
    pollbuilder_select_education_4: 'Высшее',
    pollbuilder_select_education_5: 'Научная степень',
    pollbuilder_select_work_title: 'Где вы работаете?',
    pollbuilder_select_work_1: 'Работаю на государственном предприятии, в государственной организации',
    pollbuilder_select_work_2: 'Работаю на частном предприятии, в компании, организации',
    pollbuilder_select_work_3: 'Имею свой бизнес',
    pollbuilder_select_work_4: 'Работаю в некоммерческой (общественной) организации',
    pollbuilder_select_work_5: 'ИП, самозанятый(ая), фрилансер',
    pollbuilder_select_work_6: 'Учусь в среднем специальном или высшем учебном заведении',
    pollbuilder_select_work_7: 'Не работаю, веду домашнее хозяйство или нахожусь в декретном отпуске',
    pollbuilder_select_work_8: 'На пенсии по возрасту или инвалидности',
    pollbuilder_select_work_9: 'Временно не работаю, ищу работу',
    pollbuilder_select_location_title: 'Где Вы в настоящее время проживаете?',
    pollbuilder_select_location_1: 'Минск',
    pollbuilder_select_location_2: 'Областной центр',
    pollbuilder_select_location_3: 'Крупный город с населением более 100 тыс. чел.',
    pollbuilder_select_location_4: 'Средний город (30-100 тыс. чел.)',
    pollbuilder_select_location_5: 'Малый город (до 30 тыс. чел)',
    pollbuilder_select_location_6: 'Иной населенный пункт (село, городской поселок, агрогородок, поселок, деревня)',
    pollbuilder_select_location_7: 'Не хочу отвечать',
    pollbuilder_select_marital_title: 'Ваше семейное положение',
    pollbuilder_select_marital_1: 'Холост(а) / Разведен(а) / Вдовец(вдова)',
    pollbuilder_select_marital_2: 'В браке / В отношениях',
    pollbuilder_submit_desc: 'Вы можете проголосовать только один раз и не сможете исправить свой ответ, поэтому прнимайте решение взвешенно, ознакомившись с материалами выше. Мы постарались максимально подробно описать все преимущества и недостатки всех вариантов.',
    pollbuilder_submit: 'Проголосовать',
    pollbuilder_select_region_title: 'В какой области Вы проживаете?',
    pollbuilder_select_region_1: 'Брестская область',
    pollbuilder_select_region_2: 'Витебская область',
    pollbuilder_select_region_3: 'Гомельская область',
    pollbuilder_select_region_4: 'Гродненская область',
    pollbuilder_select_region_5: 'Могилёвская область',
    pollbuilder_select_region_6: 'Минская область',
    pollbuilder_select_region_7: 'Минск',
    pollbuilder_select_region_8: 'Не в Беларуси',
    pollbuilder_select_do_not_answer: 'Другое / не хочу отвечать',
    const_main_title: 'Каждый гражданин может повлиять на изменения в своей стране',
    const_main_p_1: 'Платформа "Голос" используется для опроса граждан Беларуси по поводу того, в какой стране мы хотим жить дальше - с каким президентом, языком, конституцией и независимостью.',
    const_main_p_2: 'Нам важен каждый голос! Зарегистрируйтесь в чатботе, подтвердив свой номер телефона и участок для голосования и помогите нам написать основу конституции страны - сегодня для этого нужен только наш народ.',
    const_us_already: 'Нас уже',
    const_why_trust: 'Почему нам доверяют?',
    honest_people_logo_alt: 'Логотип инициативы Честные Люди',
    voice_logo_alt: 'Логотип онлайн-платформы Голос',
    const_howitworks_p_1: 'Платформа "Голос" представляет из себя чатбот в мессенджере Viber или Telegram, после регистрации в котором вам предложат пройти опрос и ответить на вопросы о будущей конституции.',
    const_howitworks_p_2: 'При регистрации в мессенджере чатбот попросит у вас номер телефона. Этот номер нужен для уникальности пользователя и определения, что он из Беларуси. Попадая в систему, номер шифруется и недоступен для просмотра никому, включая разработчиков платформы.',
    const_howitworks_p_3: 'Нажмите на кпоку любимого мессенджера, чтобы начать работу с чатботом. При использовании обоих мессенджеров будет учитываться только один голос.',
    const_whychange_title: 'Почему текущую Конституцию нужно поменять?',
    const_whychange_p: 'Поскольку текущая конституция и ее предыдущие изменения в том числе привели к тому , что президент получил практически неограниченную власть, законы в стране перестали действовать и выборы были целиком и полностью сфальсифицированы.',
    const_whyVoice_trust: 'Почему "Голосу" доверяют?',
    const_whyVoice_p_1: 'Платформа "Голос" уже доказала свою надежность и прозрачность, выявив фальсификации во время президентских выборов и опубликовав ',
    and: ' и ',
    const_final_report: 'финальный отчёт.',
    we: 'Мы',
    const_we_p: 'Kоманда независимых разработчиков и неравнодушных белорусов,  совместно с Координационным Советом.  Хотим, чтобы каждый гражданин нашей страны мог участвовать в ее развитии и процветации через высказывание своего мнения по тем или иным решениям.',
    go_counter_title: 'Walk on December 19th-20th',
    go_last_title: 'Previous walks',
    go_last_p1: '<strong>Belarusians from more than 400 cities, towns and villages</strong> took part in peaceful protests previously on Sundays. Moreover, <strong>sympathizers from 1400 cities and villages from all over the world joined them!</strong>',
    go_last_p2: 'The number of people that took part in the city walk is colored on the map. (The more people participated the redder is the dot).',
    letter_title: 'Новогодняя открытка для А.Г. Лукашенко',
    letter_subtitle: 'Мечты белорусов, собранные в проекте "Беларусь Мечтает"',
    letter_counter: 'Мечты для Лукашенко',
    results_stations_error: 'No data available for this polling station.',
  },
};
export default en;
