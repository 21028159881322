import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faViber,
  faVk,
  faTwitter,
  faOdnoklassniki,
  faFacebookF,
  faInstagram,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';

import Header from '../../shared/structure/Header';
import Footer from '../../shared/structure/Footer';

import Landing from './views/Landing';
import FAQ from './views/FAQ';
import Terms from './views/Terms';
import NotFound from '../../shared/views/NotFound';
import ScrollToTop from '../../shared/structure/ScrollToTop';

import '../../App.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}));

export default function App() {
  const classes = useStyles();

  library.add(faViber, faVk, faTwitter, faOdnoklassniki, faFacebookF, faInstagram, faTelegramPlane);
  return (
    <div className={classes.root}>
      <Router basename="/home">
        <ScrollToTop />
        <Header />
        <Container disableGutters component="main" maxWidth={false}>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Container>
        <Footer />
      </Router>
    </div>
  );
}
