import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FullWidth from '../structure/FullWidth';
import SectionTitle from './SectionTitle';
import TwoCols from '../structure/TwoCols';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.background === 'blue'
      ? 'white'
      : 'inherit',
    backgroundColor: props.background === 'blue'
      ? theme.palette.primary.main
      : theme.palette[props.background],
    textAlign: props.align,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  }),
}));

const Section = ({
  align,
  background = 'white',
  button,
  content,
  title,
  subtitle,
}) => {
  const classes = useStyles({ align, background });
  let titleEl;
  let headerEl;

  if (title) {
    titleEl = (
      <SectionTitle
        title={title}
        subtitle={subtitle}
      />
    );

    if (button) {
      headerEl = (
        <TwoCols
          rightSpan={4}
          rightAlign="right"
          right={button}
          leftSpan={8}
          left={titleEl}
          alignItems="center"
        />
      );
    } else {
      headerEl = titleEl;
    }
  } else if (button) {
    headerEl = button;
  }

  return (
    <FullWidth
      className={classes.root}
    >
      <>
        {headerEl}
        {content}
      </>
    </FullWidth>
  );
};

Section.defaultProps = {
  align: 'left',
  background: 'white',
  button: null,
  content: null,
  title: null,
  subtitle: null,
};

Section.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  background: PropTypes.oneOf(['white', 'gray', 'blue']),
  button: PropTypes.element,
  content: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Section;
