const ENVIRONMENT = 'development';
const GTAG_ID = 'UA-173158975-3';
const SENTRY_URL = 'https://7ee82afcf4704b5781f0016940419a45@o461748.ingest.sentry.io/5463963';
const RESULTS_URL = 'https://golos2020.org/election';
const WALKING_URL = 'https://golos2020.org/go';
const MAP_API_KEYS = '43d1a75b-a259-42d5-9b63-c4b50cdf14c4,d618619a-c67f-43c4-b832-05312f126771';
const VIBER_BOT_URL = 'viber://pa?chatURI=golos-by';
const TELEGRAM_BOT_URL = 'https://t.me/golosby_bot';
const FAQ_URL = 'https://landing.belarus2020-test.org/home/terms';
const API_BASE_URL = 'https://d3kxokrdpc64vo.cloudfront.net';
const STATS_BUCKET_URL = 'https://d3kxokrdpc64vo.cloudfront.net/stats.json';
const GO_PATH = '/go';
const TERMS_URL = 'https://golos.partizan-results.com/terms.html';
const GO_ENG_PATH = '/supportbelarus';
const CONSTITUTION_PATH = '/constitution';
const THANKYOU_PATH = '/constitution/thankyou';
const POLLS_PATH = '/polls';
const GO_ENG_URL = 'https://partizan-results.com/supportbelarus';
const ABROAD_CHECKIN_API = 'https://on8fmdmh1j.execute-api.us-east-1.amazonaws.com/Prod/checkin';
const POLLING_STARTIONS_API = 'https://on8fmdmh1j.execute-api.us-east-1.amazonaws.com/Prod/Quarantine';
const POLLING_STATIONS_JSON = 'https://dk95z7b6sxn9u.cloudfront.net/pollingStations.json';
const POLLS_URL = 'https://golos.partizan-results.com/polls.html';
const POLL_BUILDER_API = '/temp';
// const CONSTITUTION_API_URL = 'https://api.belarus2020-test.org/Prod';
const CONSTITUTION_API_URL = 'https://api.golos2020.org';
const CONSTITUTION_MAIL_PATH = '/constitution/mail';
const POLL_RESULTS_JSON = 'https://d3kxokrdpc64vo.cloudfront.net/constitution-poll-results.json';
const POSTCARD = 'https://6txq50zymh.execute-api.us-east-2.amazonaws.com/Prod/dream/postcard?limit=10000&offset=0';
const WEBPOLL_API = 'https://api.golos2020.org/web-poll';
const WEBPOLL_PATH = '/webpoll';
const WEBPOLL_RESULTS_PATH = '/webpoll/results';
const DIALOG_PATH = '/dialog';

module.exports = {
  GTAG_ID,
  SENTRY_URL,
  ENVIRONMENT,
  RESULTS_URL,
  MAP_API_KEYS,
  VIBER_BOT_URL,
  TELEGRAM_BOT_URL,
  WALKING_URL,
  FAQ_URL,
  TERMS_URL,
  API_BASE_URL,
  STATS_BUCKET_URL,
  GO_PATH,
  GO_ENG_PATH,
  CONSTITUTION_PATH,
  THANKYOU_PATH,
  POLLS_PATH,
  GO_ENG_URL,
  ABROAD_CHECKIN_API,
  POLLING_STARTIONS_API,
  POLLING_STATIONS_JSON,
  POLLS_URL,
  POLL_BUILDER_API,
  CONSTITUTION_API_URL,
  CONSTITUTION_MAIL_PATH,
  POLL_RESULTS_JSON,
  POSTCARD,
  WEBPOLL_API,
  WEBPOLL_PATH,
  WEBPOLL_RESULTS_PATH,
  DIALOG_PATH,
};
