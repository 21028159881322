import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FullWidth from './FullWidth';
import theme from '../../theme/theme';

const useStyles = makeStyles(() => ({
  root: (props) => ({
    fontSize: props.size === 'medium' ? '0.875rem' : '0.5rem',
    padding: props.size === 'medium' ? theme.spacing(1, 0) : theme.spacing(0, 0),
    backgroundColor: props.background,
  }),
}));

const Separator = ({
  background,
  size,
}) => {
  const classes = useStyles({
    // eslint-disable-next-line no-nested-ternary
    background: background === 'white' ? 'white' : (background === 'gray' ? '#F3F3F3' : theme.palette.primary.main),
    size,
  });

  return (
    <FullWidth
      className={classes.root}
    >
      <>&nbsp;</>
    </FullWidth>
  );
};

Separator.defaultProps = {
  background: 'white',
  size: 'medium',
};

Separator.propTypes = {
  background: PropTypes.oneOf(['white', 'gray', 'blue']),
  size: PropTypes.oneOf(['small', 'medium']),
};

export default Separator;
