const actionTypes = {
  LOAD_POLLING_STATIONS: 'LOAD_POLLING_STATIONS',
  LOAD_WHITELIST: 'LOAD_WHITELIST',
  LOAD_RESULTS: 'LOAD_RESULTS',
  LOAD_DETACHED: 'LOAD_DETACHED',
  LOAD_POLL_BUILDER: 'LOAD_POLL_BUILDER',
  LOAD_POLL_CHOICES: 'LOAD_POLL_CHOICES',
  CHANGE_POLL_CHOICES: 'CHANGE_POLL_CHOICES',
  LOAD_SOCIAL_DATA: 'LOAD_SOCIAL_DATA',
  CHANGE_SOCIAL_DATA: 'CHANGE_SOCIAL_DATA',
  LOAD_POLL_RESULTS: 'LOAD_POLL_RESULTS',
  LOAD_USER_DATA: 'LOAD_USER_DATA',
  CHANGE_TOKEN_STATUS: 'CHANGE_TOKEN_STATUS',
  LOAD_WEBPOLL: 'LOAD_WEBPOLL',
  LOAD_WEBPOLL_ANSWERS: 'LOAD_WEBPOLL_ANSWERS',
  SET_WEBPOLL_ANSWER: 'SET_WEBPOLL_ANSWER',
  SET_WEBPOLL_PAGE: 'SET_WEBPOLL_PAGE',
  SET_WEBPOLL_TOKEN: 'SET_WEBPOLL_TOKEN',
  SET_WEBPOLL_COMPLETED: 'SET_WEBPOLL_COMPLETED',
  LOAD_WEBPOLL_TOKEN_STATUS: 'LOAD_WEBPOLL_TOKEN_STATUS',
  START: '_START',
  SUCCESS: '_SUCCESS',
  FAIL: '_FAIL',
};

export default actionTypes;
